#integrations-dropdown {
  gap: 10px;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%
}

.flex-row {
  display: flex;
  align-items: center;
}

#integrations-dropdown .dropdown-container {
  width: 35%;
}

#integrations-dropdown .inner-input {
  display: flex;
  gap: 5px;
  align-items: center;
}

#integrations-dropdown .inner-input img {
  margin-right: 8px;
  width: 20px;
  height: auto;
}

#integrations-dropdown .button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  gap: 4vw;
  padding: 10px;
  border: 1px solid #14182f1a;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 #14182f1a;
  cursor: pointer;
}

#integrations-dropdown .button.button-error {
  border: 1px solid #d71717;
}

#integrations-dropdown .is-open {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #ffffff;
  z-index: 1000 !important;
}

#integrations-dropdown ul {
  position: absolute;
  width: 35%;
  border: 1px solid #14182f1a;
  border-top: none;
  max-height: 22vh;
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 1000 !important;
  display: flex;
  flex-direction: column;
  gap: 0;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#integrations-dropdown .ul-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #797f99;
  padding: 10px 20px;
}

#integrations-dropdown li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 20px;
}

#integrations-dropdown .li-left {
  display: flex;
  gap: 20px;
  align-items: center;
}

#integrations-dropdown .li-left .img-block {
  width: 48px;
  height: 48px;
  box-shadow: 0px 1px 3px 0px #14182f1f;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#integrations-dropdown .li-left .img-block img {
  width: 30px;
  height: auto;
}

#integrations-dropdown .intg-li input {
  height: 24px;
  width: 24px;
  color: #d5d7e4;
  border: 1px solid #d5d7e4;
}

#integrations-dropdown .li-left .connected {
  display: flex;
  align-items: center;
  gap: 5px;
}

#integrations-dropdown .li-left .dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
}

#integrations-dropdown .li-left .active-color {
  border: 1px solid #14d273;
  background-color: #1be27f;
}

#integrations-dropdown .li-left .not-active-color {
  border: 1px solid #ced0df;
  background-color: #d5d7e4;
}

#integrations-dropdown .li-left .active {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;

  color: #1be27f;
}

#integrations-dropdown .li-left .not-active {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;

  color: #d5d7e4;
}

#integrations-dropdown .error {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 10px;
}

#integrations-dropdown .error p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  text-align: left;
  color: #981830;
}

#integrations-dropdown .error button {
  font-weight: 500;
  text-decoration: underline;
  color: #3b53d1;
  padding-left: 10px;
}

#integrations-dropdown .configure-mapping button {
  font-weight: 500;
  text-decoration: underline;
  color: #3b53d1;
  padding-left: 10px;
}
