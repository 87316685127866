#popup {
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: -20px 0px 150px rgba(0, 0, 0, 0.3);
  border-top: 2px solid #f2f2f2;
  transition: all 0.5s ease;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}
#popup .popup-content {
  width: max-content;
  height: max-content;
  padding: 20px;
  background: #fff;
  opacity: 0;
  transform: translateY(10%);
}
#popup.show .popup-content {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.3s ease;
  border-radius: 20px;
 /* box-shadow: 4px 4px 4px rgba(0, 0, 50, 0.2);*/
  box-shadow: 5px 5px 0px rgba(79, 54, 231, 0.17)
}
#popup .head-bottom-popup {
  position: relative;
}
#popup .head-bottom-popup span {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}
#popup .head-bottom-popup span img {
  width: 20px;
}

#popup .closeButton {
  padding: 8px;
  border-radius: 10%;
  background-color: #F5F5F9;
}