#billing-cycle-client {
  margin-top: 10px;
}

#billing-cycle-client .billing-cycle-ctn {
  display: flex;
  align-items: center;
  gap: 20px;
}

#billing-cycle-client h1 {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #0d1021;
}

/* ACTIVE */
#billing-cycle-client .annually-ctn-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#billing-cycle-client .billing-option {
  padding: 1px;
  background: #d5d7e4;
  border-radius: 16px;
  cursor: pointer;
  transition: padding 0.2s;
}

#billing-cycle-client .billing-option.active {
  padding: 2px;
  background: linear-gradient(to right, #ff206e 0%, #f17027 100%);
}

#billing-cycle-client .billing-option.active-monthly {
  padding: 2px;
  background: #4d58f3;
}

#billing-cycle-client .annually-ctn {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  padding: 16px 16px 0 16px;
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 14px;
}

#billing-cycle-client .annually-ctn .left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
}

#billing-cycle-client .annually-ctn .left p {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #72757e;
}

#billing-cycle-client .annually-ctn .left .price {
  display: flex;
  align-items: end;
  gap: 5px;
}

#billing-cycle-client .annually-ctn .left .price h2 {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}

#billing-cycle-client .annually-ctn .left .price span {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #111833;
  margin-bottom: 10px;
}

#billing-cycle-client .annually-ctn .save-percent {
  padding: 4px 8px;
  border-radius: 17.5px;
  background: linear-gradient(107.11deg, #ff206e 16.47%, #f17027 65.61%);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}

/* INACTIVE */

#billing-cycle-client .monthly-ctn-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  min-width: 220px;
}

#billing-cycle-client .disabled:hover {
  cursor: not-allowed;
}

#billing-cycle-client .monthly-ctn {
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  padding: 16px 16px 0 16px;
  background: #ffffff;
  width: 100%;
  height: 100%;
  min-width: 220px;
  border-radius: 14px;
}

#billing-cycle-client .monthly-ctn .left {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: start;
}

#billing-cycle-client .monthly-ctn .left p {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #72757e;
}

#billing-cycle-client .monthly-ctn .left .price {
  display: flex;
  align-items: end;
  gap: 5px;
}

#billing-cycle-client .monthly-ctn .left .price h2 {
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}

#billing-cycle-client .monthly-ctn .left .price span {
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  color: #111833;
  margin-bottom: 10px;
}
