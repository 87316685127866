.icon-style {
  margin-top: 10px;
  margin-left: 20px;
  cursor: pointer;
  color: #797f99;
}

.icon-container {
  text-align: center;
  margin-top: 20px;
}

.toast-container {
  top: 100px;
}

.modal-header {
  border: 0;
}

.modal-footer {
  border: 0;
}
