.top-header .inner-header-block .right-block .profile-ui {
  position: relative;
}
.profile-dropdown {
  position: absolute;
  /*TODO remove transform !important from index.css*/
}
.profile-dropdown ul li a {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 10px 10px 5px 15px;
  color: #595f79;
}
.PhoneInputCountry {
  opacity: 1 !important;
  width: 70px !important;
  position: relative !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px !important;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
}
.checkbox-listing {
  padding: 0 5px !important;
}
/*
.PhoneInputCountrySelect .PhoneInputCountryIcon{
    position: absolute !important;
    left:0;
    z-index: 100;
    border:1px solid #555 !important;
}*/
.onboard-content-ui {
  padding-top: 120px;
}
.PhoneInputCountry select {
  padding: 10px;
  appearance: none;
}
.PhoneInputCountry select option {
  color: #0d1829;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;
}
.content-wrapper-ui {
  padding-top: 80px;
  margin-right: 30px;
}
.addmember-modal .modal-content {
  width: 570px !important;
}
.addmember-modal .modal-content .right-block {
  display: flex;
  justify-content: flex-start !important;
  width: 100%;
}
.addmember-modal .modal-content .left-block {
  width: 150px !important;
  font-size: 14px;
}
.addmember-modal .modal-content .checkbox-wrap {
  width: auto !important;
  margin-right: 20px;
}
.addmember-modal .modal-content .user-role-block {
  margin-bottom: 0px !important;
}
.addmember-modal .modal-content .user-role-listing .checkbox-text {
  width: 50px !important;
  font-size: 13px;
}
.addmember-modal .modal-content .user-role-listing {
  margin-bottom: 15px !important;
}
.addmember-modal .modal-content .modal-heading.mt-16 {
  margin: 5px 0 !important;
  margin-bottom: 15px !important;
}
.addmember-modal .modal-content .add-member-fields {
  padding: 0 24px;
}
.addmember-modal .modal-content .form-group {
  margin-bottom: 15px;
}
a {
  cursor: pointer;
}
.username:first-letter {
  text-transform: capitalize;
}
.dropdown-menu .dropdown-item {
  cursor: pointer;
}
.textarea-box {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  height: 120px !important;
  padding: 10px;
  width: 100%;
  resize: none;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  font-size: 14px;
  letter-spacing: 0.12px;
  line-height: 22px;
  color: #666 !important;
}
.textarea-box::placeholder {
  color: #999;
}
.overlay-block {
  height: 120% !important;
}
.customize-plan-modal {
}

.mycustom-select .my-select {
  border: none;
  border-radius: 10px;
  outline: none;
  width: 100%;

  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 45px;
  padding: 0 15px;
  line-height: 45px;
  cursor: pointer;
}
.mycustom-select .my-select::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.mycustom-select .my-select::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(59, 83, 209, 0.3);
  border-radius: 10px;
  border-radius: 5px;
}

.mycustom-select .my-select::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(59, 83, 209, 0.5);
}
/*.table-content {
    transform:rotateX(180deg);
    -moz-transform:rotateX(180deg); !* Mozilla *!
    -webkit-transform:rotateX(180deg); !* Safari and Chrome *!
    -ms-transform:rotateX(180deg); !* IE 9+ *!
    -o-transform:rotateX(180deg); !* Opera *!
}
.table-content > *  {
    transform:rotateX(-180deg);
    -moz-transform:rotateX(-180deg); !* Mozilla *!
    -webkit-transform:rotateX(-180deg); !* Safari and Chrome *!
    -ms-transform:rotateX(-180deg); !* IE 9+ *!
    -o-transform:rotateX(-180deg); !* Opera *!
}*/
.table-content::-webkit-scrollbar {
  width: 6px;
  height: 11px;
}
.my-coupon input {
  width: 100%;
  height: 45px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #f5f5f9;
  padding: 0 10px;
  transition: 0.2s ease-in-out;
}

.workflow-setting .setting-inner-wrap .attach-workflow-wrapper {
  display: block !important;
}
.custom-integration-list select {
  width: 100%;
  display: inline-block;
  height: 40px;
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  position: relative;
  border: none;
  color: #414766;
  text-align: left;
  padding-left: 35px;
  padding-right: 20px;
  letter-spacing: 0.12px;
  font-size: 16px;
  margin-bottom: 20px;
  outline: none;
}

.info-card {
  /*
  min-height: 220px;
  */
  box-shadow:
    0px 0px 2px rgba(20, 24, 47, 0.12),
    0px 2px 4px rgba(20, 24, 47, 0.1);
  border-radius: 12px;
}

.cursorNotAllowed:hover {
  cursor: not-allowed;
}
.edit-worklfow-name {
  margin-bottom: 20px;
}
.edit-worklfow-name .edit-btn {
  cursor: pointer;
}
.edit-worklfow-name .edit-btn img {
  width: 11px;
  padding-bottom: 2px;
  border-bottom: 2px solid #ccc;
}

.edit-worklfow-name button {
  background: #3b53d1;
  color: #fff;
  padding: 3px 7px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.edit-worklfow-name input {
  padding: 2px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #ccc;
}
.custom-wk-profiles {
  overflow-x: auto;
}
.custom-wk-profiles table {
  width: 2000px !important;
}
.custom-tag-ui {
  background: #f832a1;
  padding: 5px 12px;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 14px;
  text-transform: uppercase;
  border-radius: 6px;
  position: absolute;
  top: -10px;
  left: 20px;
}
.capital {
  display: inline-block;
}
.capital:first-letter {
  text-transform: capitalize;
}
.date-picker-container abbr {
  color: #555;
}

.leads-select-tag {
  background: rgba(100, 100, 230, 0.2);
  color: #5369df;
  border-radius: 20px;
  padding: 3px 10px;
  font-weight: 500;
  height: 30px;
  margin-right: 50px;
}

.custom-error-bar {
  background: lightcoral;
  color: #fff;
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  padding: 5px 20px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.custom-error-bar img {
  cursor: pointer;
}
.custom-error-bar a {
  margin-left: 10px;
  color: #fff;
  border-bottom: 1px solid #fff;
  font-size: 15px;
  padding-bottom: 2px;
}
.custom-error-bar img {
  margin-left: 2px;
  margin-top: -2px;
}
@media only screen and (max-width: 1500px) {
  .onboard-content-ui .offset-xl-1 {
    margin-left: 0;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .ripple-btn,
  .btn1,
  .btn2,
  .btn3,
  .btn5,
  .btn6,
  .cta-search-btn,
  .cp-btn,
  .ec-btn,
  .contact-support-btn {
    font-size: 14px;
  }
  .number-text {
    font-size: 36px !important;
  }

  .content-ui span {
    font-size: 15px !important;
    font-weight: 500;
  }
}
.leads-select-tag {
  background: rgba(100, 100, 230, 0.2);
  color: #5369df;
  border-radius: 20px;
  padding: 3px 10px;
  font-weight: 500;
  height: 30px;
  margin-right: 50px;
}
.select-payment-method {
  margin-top: 2px;
}
.select-payment-method span.method-button {
  width: 100px;
  color: #ccc;
  text-align: center;
  font-weight: 500;
  margin-right: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.select-payment-method span:first-child {
  border-right: 1px solid #555;
}

.notification-list {
  padding: 10px;
}
.notification-list li {
  margin-top: 15px;
}
.notification-list h1 {
  font-size: 18px;
}
.notification-list h3 {
  font-size: 13px;
  margin-bottom: 0;
  padding: 5px;
  font-weight: 400;
  cursor: default;
}
.notification-list .notification-msg-container {
  display: flex;
  align-items: center;
}
.notification-list .image-container {
  margin-top: -5px;
  margin-right: 5px;
  border-radius: 50%;
  background: rgba(147, 137, 203, 0.1);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-list img {
  padding: 10px;
  width: 35px;
}
.notification-list small {
  color: #999;
  margin-left: 43px;
}
.notification-list a {
  text-decoration: underline;
  color: blue !important;
  padding: 0 !important;
}
.notification-list a:hover {
  background: none !important;
}
.table-content table tbody tr td .username.view-profile {
  display: flex;
  align-items: center;
}
.table-content table tbody tr td .username.view-profile a {
  color: #222;
  display: block;
  font-weight: 500;
}
.table-content table tbody tr:hover td .username.view-profile a {
  color: #3b53d1;
  text-decoration: underline;
}
.table-content table tbody tr td .username.view-profile .company-logo {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  margin-right: 10px;
}
.table-content table tbody tr td .username.view-profile .company-logo img {
  width: 100%;
  height: 100%;
}
.waitingList-notifications {
  display: flex;
}
.waitingList-notifications div {
  display: flex !important;
}
.waitingList-notifications a {
  color: #222 !important;
  text-decoration: none !important;
  font-size: 16px;
}
.waitingList-notifications-links:hover {
  color: #3b53d1 !important;
  text-decoration: underline !important;
}
.modal.profile-modal .profile-img-block {
  border-radius: 0;
}
.initials {
  background: #ffd2dd;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  color: #ca5372;
}

.modal.profile-modal .profile-info {
  width: 70%;
}
.modal.profile-modal .left-wrap {
  width: 30%;
}
.modal.profile-modal .more-emails {
  text-decoration: underline;
  color: #3b53d1;
  font-size: 13px;
}
.modal.profile-modal .profile-added-by {
  margin-bottom: 0px;
  /* text-align: right; */
  /* width: 100%; */
  color: #999;
  font-size: 12px;
  margin-top: 13px;
}
.profile-data-edit-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal.profile-modal .edit_profile_field {
  width: 20px;
  height: 20px;
  border-radius: 20%;
  padding: 4.5px;
  margin-left: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.modal.profile-modal .profile-data-edit-wrapper {
  width: 65%;
  display: flex;
  justify-content: flex-start;
}
.modal.profile-modal .profile-data-edit-wrapper input {
  /*border: none;
  border-bottom: 1px solid  #999;*/
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 1px 5px;
  width: max-content;
}
.modal.profile-modal .profile-data-edit-wrapper > button {
  padding: 2px 6px;
  height: 25px;
  border-radius: 5px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal.profile-modal .profile-added-by {
  margin-bottom: 0px;
  /* text-align: right; */
  /* width: 100%; */
  color: #999;
  font-size: 12px;
  margin-top: 13px;
}

.wk-profile-search input {
  border-radius: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #999 !important;
  height: 40px !important;
}
.wk-profile-search {
  margin-left: -20px;
}

.search-ui input {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 40px;
  width: 200px;
  padding: 0 20px 0 42px;
  font-size: 16px;
  letter-spacing: -0.18px;
  line-height: 20px;
  color: #797f99;
  border: none;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.modal.profile-modal .profile-data-edit-wrapper .profile-field {
  word-break: break-all;
}

.modal-send-email .modal-dialog {
  max-width: 1500pc;
  position: absolute;
  bottom: 0;
  margin: 0;
  z-index: 10;
  right: 100px;
  width: 600px;
}

.modal-send-email .modal-dialog .modal-body {
  margin: 15px;
}

.modal-send-email .modal-dialog .modal-footer {
  border-radius: 0;
}
.unsubscribe_popup {
  padding: 10px !important;
}
.unsubscribe_popup input[type='checkbox'] {
  transform: scale(1.2);
  margin-right: 5px;
  vertical-align: middle;
}
.unsubscribe_popup li label {
  font-size: 14px;
  margin: 0;
  color: darkred;
}
.btn-disabled {
  background: #ccc !important;
  color: #fff;
  text-shadow: none;
  cursor: default;
}
.unsubscribe-modal-wrapper .modal-content {
  width: 600px !important;
  margin-left: -35px;
}
.unsubscribe-modal-wrapper .star {
  color: darkred;
  font-size: 17px;
}
.unsubscribe-modal-wrapper h2 {
}
.unsubscribe-modal-wrapper .why-container select {
  width: 50%;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 30px;
  outline: none;
}
.unsubscribe-modal-wrapper .why-textarea-container label {
  display: block;
}
.unsubscribe-modal-wrapper .why-textarea-container textarea {
  outline: none;
  padding: 10px;
  border-radius: 5px;
  width: 100% !important;
}

.i-infos {
  background: #fff;
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  color: #999;
  padding-left: 6px;
  font-weight: bold;
  font-size: 13px;
  border: 1px solid #999;
  margin-left: 10px;
  cursor: default;
}
.error-warning {
  color: coral !important;
}

.fp-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
  z-index: 999999;
  background: #ffffff;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fp-container .fp-loader {
  top: 30%;
  z-index: 999999;
  position: absolute;
  margin-bottom: 20px;
}

.email .modal-header {
  height: 40px;
  background: -webkit-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: -o-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: linear-gradient(140.68deg, #5369df 0%, #4458c9 100%);
}

.email .modal-footer {
  height: auto;
  background-color: white !important;
}

.email .modal-header .modal-inner-header .title {
  color: white;
}

.modal .modal-header {
  position: relative;
  padding: 24px 75px 20px 24px;
  border-bottom: 1px solid #f2f2f6;
}
.email .modal-footer {
  padding: 7px 20px 24px;
}
.email .modal-header .close {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  background-color: #f5f5f9;
  line-height: 20px;
  padding: 0 2px;
  text-align: center;
  font-weight: normal;
  position: absolute;
  opacity: 1;
  color: #797f99;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}

.email .modal-footer .disabled {
  pointer-events: none;
  opacity: 0.3;
}

.modal .modal-footer .disabled {
  pointer-events: none;
  opacity: 0.3;
}

.email .modal-header {
  padding: 5px 10px;
}

.email .modal-header .close {
  top: 10px;
  right: 10px;
}

.modal-footer .btnw {
  display: inline-block;
  border: none;
  outline: none;
  appearance: none;
  background: transparent;
  position: relative;
  z-index: 3;
  height: 60px;
  border-radius: 12px;
  padding: 0 21px;
  font-size: 21px;
  box-shadow: -1px -1px 1px 0 transparent;
}
.modal-footer .btnw:before {
  content: 'Choose a template';
  z-index: -1;
  border-radius: 10px;
  color: #4f4f4f;
  font-size: 75%;
  padding: 5px 5px;
}

.modal-footer .btnw:after {
  content: '';
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  z-index: -2;
  border-radius: 10px;
  background: white;
}
.modal-footer .btnw:hover {
  cursor: pointer;
}

.modal-footer .btnwFrench {
  display: inline-block;
  border: none;
  outline: none;
  appearance: none;
  background: transparent;
  position: relative;
  z-index: 3;
  height: 60px;
  border-radius: 12px;
  padding: 0 21px;
  font-size: 21px;
  box-shadow: -1px -1px 1px 0 transparent;
}
.modal-footer .btnwFrench:before {
  content: 'Choisir un modèle';
  z-index: -1;
  border-radius: 10px;
  color: #4f4f4f;
  font-size: 75%;
  padding: 5px 5px;
}

.modal-footer .btnwFrench:after {
  content: '';
  position: absolute;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  z-index: -2;
  border-radius: 10px;
  background: white;
}
.modal-footer .btnwFrench:hover {
  cursor: pointer;
}

.modal-footer .activebtn {
  background: linear-gradient(
    89.67deg,
    #4966ff 2.97%,
    #7760ff 28.76%,
    #b149ff 61.83%,
    #e54ff2 87.37%,
    #ff206e 112.8%
  );
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .activebtn:before {
    background: linear-gradient(
      89.67deg,
      #4966ff 2.97%,
      #7760ff 28.76%,
      #b149ff 61.83%,
      #e54ff2 87.37%,
      #ff206e 112.8%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.quill .ql-container {
  border-bottom-left-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  background: #ffffff;
}

/* Snow Theme */
.quill .ql-snow.ql-toolbar {
  display: block;
  background: white;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
}

/* Bubble Theme */
.quill .ql-bubble .ql-editor {
  border: 1px solid white;
  border-radius: 0.3em;
}

.quill .ql-editor {
  min-height: 170px;
  max-height: 170px;
  overflow: scroll;
}

.quill .ql-editor s {
  text-decoration-line: line-through;
  text-decoration-style: solid;
}

.quill .ql-editor u {
  text-decoration-line: underline;
}

.leads-select-tag-red {
  background: rgba(224, 130, 131, 0.2);
  color: #990000;
  border-radius: 20px;
  padding: 3px 10px;
  font-weight: 500;
  height: 30px;
  margin-right: 50px;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: 'Normal';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: 'Large';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: 'Huge';
  font-size: 18px !important;
}

.i_infos {
  width: 23px;
  height: 23px;
  background: #edf0ff;
  display: inline-block;
  font-weight: bold;
  font-style: italic;
  font-size: 17px;
  text-align: center;
  border-radius: 50%;
  color: #5369df;
  border: 1px solid #999;
  margin-left: 5px;
  cursor: default;
  transform: scale(0.7);
}
/*.workflow-messages-column {
  width: 70% !important;
}
.attach-column {
  width: 10% !important;
}*/
#statistics_tab {
  font-size: 1vw !important;
  width: 110%;
}
.language .dropdown-toggle {
  background: #fff;
  color: #000;
  box-shadow: none;
  border-color: #ccc;
  width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 3px 10px;
}
.language .language-label {
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
}
.language .language-label.line {
  padding: 7px;
}
.language .language-label.line:hover {
  background: #3b53d1;
  color: #fff;
}
.language .language-label img {
  width: 15px;
  margin-right: 5px;
}
.branch-text {
  z-index: 10;
}

.cdi-in {
  position: relative;
  padding: 10px 16px;
  border-bottom: 1px solid #e4e8f5;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  width: 120%;
}
.cdi-in:hover {
  background: #faf2fa;
}
.cdi-in:last-child {
  border-bottom: none;
}

.cdi-in p {
  font-size: 14px;
  color: #111833;
  margin-bottom: 4px;
}

.cdi-in p:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f8fc;
  z-index: -1;
  display: none;
}

.cdi-in span {
  font-size: 12px;
  color: #737da5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cdi-in span b {
  color: #566df2;
}

.cdi-in input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.cdi-in input:checked ~ p:after {
  display: block;
}

.wk-badge {
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  background-color: #ecf5fd;
  color: #3585ca;
  padding: 2px 10px;
  margin-left: 9px;
}

.btn-gray {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  padding: 14px 15px;
  background-color: white;
  max-width: 120%;
  color: #737da5;
  -webkit-box-shadow: 0px 2px 6px rgba(86, 109, 242, 0.12);
  box-shadow: 0px 2px 6px rgba(86, 109, 242, 0.12);
  -webkit-transition: all cubic-bezier(0, 0.47, 0.65, 1.12) 250ms;
  transition: all cubic-bezier(0, 0.47, 0.65, 1.12) 250ms;
  letter-spacing: -0.24px;
  line-height: normal;
  text-align: center;
  border: 1px solid #dadde9;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  line-height: 24px;
  will-change: opacity, box-shadow;
  width: auto;
}

.btn-gray:hover {
  outline: none;
  -webkit-box-shadow: 0px 5px 20px rgba(86, 109, 242, 0.22);
  box-shadow: 0px 5px 20px rgba(86, 109, 242, 0.22);
  opacity: 0.81;
}

.btn-gray:focus {
  -webkit-box-shadow: 0px 10px 20px rgba(86, 109, 242, 0.22);
  box-shadow: 0px 10px 20px rgba(86, 109, 242, 0.22);
  outline: none;
  color: #737da5;
}

.btn-gray.sm {
  padding: 12px 15px;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.btn-gray.xs {
  padding: 6px 12px;
  font-size: 11px;
  width: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 18px;
}

.btn-gray.loader:after {
  content: '';
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid white;
  bottom: 0px;
  margin-left: 5px;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-animation: loader 600ms linear infinite;
  animation: loader 600ms linear infinite;
}
.save-money-ui {
  background: -webkit-linear-gradient(318.12deg, #f9e5f2 0%, #e7ebff 100%);
  background: -o-linear-gradient(318.12deg, #f9e5f2 0%, #e7ebff 100%);
  background: linear-gradient(131.88deg, #f9e5f2 0%, #e7ebff 100%);
  width: 100%;
  height: 142px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  padding: 20px 20px;
}
.save-money-ui.lc-icon:after {
  background: url(assets/images/ic-b-credit.png) no-repeat;
  width: 170px;
  height: 170px;
  bottom: -22px;
  right: -27px;
}
.save-money-ui p {
  color: #0d1829;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: -0.12px;
  line-height: 24px;
  width: 100%;
  margin-bottom: 20px;
}
.save-money-ui.font-24 {
  height: auto;
  padding: 24px;
}
.save-money-ui.font-24 p {
  color: #0d1829;
  font-size: 24px;
  letter-spacing: 0.24px;
  line-height: 32px;
  margin-bottom: 42px;
  width: 100%;
}

.save-money-ui.font-24 a {
  background: linear-gradient(
    89.52deg,
    #4966ff 13.67%,
    #7760ff 32.07%,
    #b149ff 56.92%,
    #e54ff2 87.66%,
    #ff206e 128.2%
  ) !important;
  color: #fff !important;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  display: inline-block;
  padding: 0 16px;
  border-radius: 10px;
  vertical-align: middle;
  background: #fff;
  position: relative;
  z-index: 1;
  margin: 0;
  box-shadow:
    0 0 2px 0 rgb(20 24 47 / 12%),
    0 2px 4px 0 rgb(20 24 47 / 10%);
}

.global-fade-in {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
}

.white-button {
  padding: 0 10px;
  margin-right: 20px;
  line-height: 35px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.82);
  box-shadow:
    0 0 2px 0 rgb(20 24 47 / 10%),
    0 2px 4px 0 rgb(13 16 33 / 8%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #555;
}
.white-button img {
  background: #edf0ff;
  padding: 6px;
  width: 25px;
  height: 25px;
  margin-right: 7px;
  border-radius: 50%;
}
/*
input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}*/

.pushes-remaining {
  margin-left: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  /* background-color: #f1edff;
      color: #573ad7;*/
  /*
    border: 1px solid #5369df;
    */
  background: #f1edff;
  color: #5369df;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  padding: 5px 7px;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
}
.pushes-remaining .infinity-icon {
  width: 20px;
  margin-right: 5px;
  margin-top: -1px;
}
.pushes-remaining {
}
.pushes-remaining span {
  /*  background: #5369df;
    color: #fff;*/
  padding: 2px;
  border-radius: 5px;
}
.pushes-remaining strong {
  font-weight: 700;
}
.billing .subcription-plan-ui p {
  font-size: 15px !important;
}
.billing .subcription-plan-ui > a {
  font-size: 16px !important;
}
.billing .subcription-plan-ui .price-text > span {
  font-size: 23px !important;
}
.billing .subcription-plan-ui li {
  font-size: 14px !important;
}
.subscription-tab-wrapper {
  width: 90%;
  margin: 0 auto;
}
.upgrade-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  width: 100%;
  border: 1px solid #e66d6d;
  padding: 15px 30px;
  border-radius: 6px;
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48c4 150.79%);

  display: flex;
  color: #fff;
  justify-content: center;
  margin: 20px 0;
  font-size: 20px;
}

.invalid-emails-error {
  color: red;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 14px;
}
.invalid-emails-error img {
  width: 18px;
  margin-top: -2px;
  margin-right: 3px;
}
.kaspr-gradient {
  background: linear-gradient(
    89.52deg,
    #4966ff 13.67%,
    #7760ff 32.07%,
    #b149ff 56.92%,
    #e54ff2 87.66%,
    #ff206e 128.2%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
}
.blue-color {
  color: #3b53d1;
}
.first-all {
  font-size: 11px !important;
  font-weight: 500 !important;
}
.modal .modal-header {
  padding-right: 24px;
}

.simple-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #5369df;
  font-weight: 500;
  cursor: pointer;
}

.simple-btn:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}
