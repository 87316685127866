/*
.side-nav-container {
    position: fixed;
    top :70px;
    min-width: 70px;
}*/

.small-navbar #sideNav .navbar-nav li a:hover + .tooltip-box {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.small-navbar #sideNav .navbar-nav li a:hover {
  background: white;
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
}

/*
#sideNav {
    top: 63px;
    height: calc(100% - 64px);
    -webkit-transition: all 250ms ease;
    -o-transition: all 250ms ease;
    transition: all 250ms ease;
}
*/

#sideNav {
  background-color: transparent;
  width: 100%;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  height: calc(100vh - 100px);
  /*overflow-y:auto;*/
}

#sideNav .navbar {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0px;
}

#sideNav .navbar .navbar-collapse {
  height: 100%;
}

#sideNav .navbar-nav {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 0px;
  width: 100%;
  height: 100%;
}

#sideNav .navbar-nav li {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  max-height: 40px;
  margin-left: 1px;
  position: relative;
}

#sideNav .navbar-nav li:last-child {
  margin-bottom: 20px;
}

#sideNav .navbar-nav li a {
  color: #626987;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  padding: 9px 9px 9px 9px;
  max-height: 40px;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}

#sideNav .navbar-nav li a .icon {
  width: 22px;
  display: inline-block;
  background: url('assets/images/ic_home.png');
  background-repeat: no-repeat;
  width: 22px;
  height: 20px;
  left: 0px;
  top: -1px;
  position: relative;
  background-size: 100% !important;
  margin-right: 0px;
  opacity: 0.9;
}

#sideNav .navbar-nav li a .icon.dashboard-icon {
  background: url('assets/images/ic_home.png');
}

#sideNav .navbar-nav li a .icon.company-icon {
  background: url('assets/images/ic_work.png');
}

#sideNav .navbar-nav li a .icon.lead-icon {
  background: url('assets/images/ic_leads.png');
}

#sideNav .navbar-nav li a .icon.team-icon {
  background: url('assets/images/ic_team.png');
}

#sideNav .navbar-nav li a .icon.bulk-icon {
  background: url('assets/images/ic_bulk.svg');
  filter: brightness(85%);
  width: 21px;
  background-repeat: no-repeat;
}

#sideNav .navbar-nav li a .icon.workflow-icon {
  background: url('assets/images/ic_workflow.png');
}

#sideNav .navbar-nav li a .icon.billing-icon {
  background: url('assets/images/ic_billing.png');
}

#sideNav .navbar-nav li a .icon.setting-icon {
  background: url('assets/images/ic_settings.png');
}

#sideNav .navbar-nav li a .icon.onboarding-icon {
  background: url('assets/images/ic_onboarding.svg');
  filter: brightness(85%);
  width: 20px;
  background-repeat: no-repeat;
}

#sideNav .navbar-nav li a:hover .nav-txt {
  color: #3e4358;
}

#sideNav .navbar-nav li a:hover .icon,
#sideNav .navbar-nav li a.active .icon,
#sideNav .navbar-nav li a:focus .icon {
  opacity: 1;
}

#sideNav .navbar-nav li a.active {
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
}

#sideNav .navbar-nav li a.active .icon.dashboard-icon {
  background: url('assets/images/ic_home.png');
}

#sideNav .navbar-nav li a.active .icon.company-icon {
  background: url('assets/images/ic_work.png');
}

#sideNav .navbar-nav li a.active .icon.lead-icon {
  background: url('assets/images/ic_leads-active.png');
}

#sideNav .navbar-nav li a.active .icon.team-icon {
  background: url('assets/images/ic_team.png');
}

#sideNav .navbar-nav li a.active .icon.bulk-icon {
  background: url('assets/images/ic_bulk_active.svg');
  filter: contrast(200%);
  transform: rotate(180deg);
  width: 21px;
  background-repeat: no-repeat;
}

#sideNav .navbar-nav li a.active .icon.workflow-icon {
  background: url('assets/images/ic_workflow_active.png');
}

#sideNav .navbar-nav li a.active .icon.billing-icon {
  background: url('assets/images/ic_billing.png');
}

#sideNav .navbar-nav li a.active .icon.setting-icon {
  background: url('assets/images/ic_settings.png');
}

#sideNav .navbar-nav li a.active .icon.onboarding-icon {
  background: url('assets/images/ic_onboarding_active.svg');
  filter: contrast(110%);
  width: 20px;
  background-repeat: no-repeat;
}

#sideNav .navbar-nav li a.active .nav-txt {
  color: #3b53d1;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}

#sideNav .navbar-nav li a .nav-txt {
  margin-left: 12px;
  display: inline-block;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}

#sideNav .navbar-nav li a span {
  vertical-align: middle;
}

#sideNav .navbar-nav li a:hover span:before,
#sideNav .navbar-nav li a.active span:before {
  color: #ccc;
}

#sideNav .navbar-nav li a [class^='icon-'] {
  font-size: 24px;
  top: -2px;
  position: relative;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  display: inline-block;
  width: 28px;
}

#sideNav.editor-left-panel {
  top: 135px;
}

#sideNav.editor-left-panel .navbar-nav {
  padding-top: 10px;
}

#sideNav .navbar-nav .dropdown-list {
  padding-left: 30px;
  padding-top: 10px;
}
#sideNav .navbar-nav .dropdown-list li a:hover {
  color: #3e4358;
}
#sideNav .red-point {
  background: red;
  width: 11px;
  height: 11px;
  display: inline-flex;
  position: relative;
  top: -2px;
  left: 3px;
  border-radius: 50%;
  color: #fff;
  font-size: 6px;
  justify-content: center;
  align-items: center;
}
