
.page-header .cstm-select {
    display: inline-block;
    width: 132px;
    height: 40px;
    border-radius: 4px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12), 0 2px 4px 0 rgba(20, 24, 47, 0.1);
    box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12), 0 2px 4px 0 rgba(20, 24, 47, 0.1);
    position: relative;
}

.page-header .cstm-select .bootstrap-select {
    width: 100% !important;
}

.page-header .cstm-select .bootstrap-select button {
    width: 100%;
    height: 40px;
    padding-left: 37px;
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.18px;
    color: #242946;
}

.page-header .cstm-select .bootstrap-select button:focus, .page-header .cstm-select .bootstrap-select button:hover {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #fff !important;
}

.page-header .cstm-select .bootstrap-select button.dropdown-toggle::after {
    background:url("assets/images/ic_arrow_down.svg") no-repeat;
    width: 10px;
    height: 10px;
    border: none;
    margin: 0;
    position: absolute;
    right: 8px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.page-header .cstm-select .bootstrap-select .dropdown-menu li a {
    padding-left: 15px;
    padding-right: 15px;
}

.page-header .cstm-select:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 20px;
    left: 8px;
    top: 8px;
    z-index: 1;
    background-size: 100%;
}

.page-header .cstm-select.lead_filter {
    margin-right: 14px;
}

.page-header .cstm-select.lead_filter:before {
    background:url("assets/images/ic_filter.png") no-repeat;
    background-size: 24px;
}

.page-header .cstm-select.user_filter:before {
    background:url("assets/images/ic_assign.png") no-repeat;
    width: 26px;
    background-size: 24px;
}

.page-header .cstm-select.user_filter .bootstrap-select button {
    padding-left: 37px;
    min-width: 140px;
}

.page-header .cstm-select .filter-option-inner-inner {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 5px;
}

.page-header .cstm-select .dropdown-item.active, .page-header .cstm-select .dropdown-item:active {
    background: #3B53D1;
    color: #fff;
}

.page-header .search-ui {
    display: inline-block;
    position: relative;
}

.page-header .search-ui:after {
    position: absolute;
    content: "";
    background:url("assets/images/ic_search.png") no-repeat;
    width: 24px;
    height: 24px;
    left: 10px;
    top: 8px;
    background-size: 24px;
}

.page-header .search-ui input {
    border-radius: 12px;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12), 0 2px 4px 0 rgba(20, 24, 47, 0.1);
    box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12), 0 2px 4px 0 rgba(20, 24, 47, 0.1);
    height: 40px;
    width: 380px;
    padding: 0 20px 0 42px;
    font-size: 16px;
    letter-spacing: -0.18px;
    line-height: 20px;
    color: #797F99;
    border: none;
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 1199px) {
    .page-header .search-ui input {
        width: 280px;
    }
}

@media only screen and (max-width: 1199px) and (max-width: 1199px) {
    .page-header .search-ui input {
        width: 230px;
    }
}

.page-header .search-ui input:focus {
    outline: none;
    -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.24), 0 2px 4px 0 rgba(20, 24, 47, 0.18);
    box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.24), 0 2px 4px 0 rgba(20, 24, 47, 0.18);
}

/*
.page-header .search-ui input:focus:after {
    background:url("assets/images/ic_search_b.png") no-repeat;
}
*/

.page-header .search-ui input:hover {
    -webkit-box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
    box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
}

.page-header .datepicker-ui {
    display: inline-block;
    margin-left: 16px;
}

.list-button {
    margin-top: 5px;
    line-height: 0px;
    padding-top: 10px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.16px;
    color: #3b53d1;
    height: 30px;
    margin-left: 20px;
    padding: 0px 10px;
    align-items: center;
    display: inline-block
}
