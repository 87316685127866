#plan-permissions .plan-permissions-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

#plan-permissions h2 {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #111833;
  margin-bottom: 20px;
}

#plan-permissions h3 {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #111833;
}

#plan-permissions ul li {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #495377;
}
