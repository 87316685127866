.hover-tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  /* bottom: -25px;  */
  bottom: -50%;
  left: 65%;
  transform: translateX(-50%);
  background-color: #242946;
  box-shadow: 0 8px 12px 0 rgba(13, 16, 33, 0.12);
  color: #fff;
  padding: 3px 6px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  opacity: 0.9;
  transition: all 250ms ease;
  z-index: 9999;
}
