#custom-toolTip-container {
  position: relative;
  width: max-content;
  display: inline-block;
}
#custom-toolTip-container .custom-toolTip {
  /*
  background: #3b53d1;
  */
  background: #333;
  color: white;
  padding: 10px;
  position: absolute;
  border-radius: 7px;
  left: 50%;
  transform: translateX(-50%) translateY(-120%);
  top: 0px;
  z-index: 999999999;
  display: none;
  width: max-content;
  z-index: 1000;
}

#custom-toolTip-container .custom-toolTip .custom-toolTip-index {
  position: absolute;
  bottom: 0;
  width: 12px;
  height: 12px;
  left: 50%;
  background: #333;
  z-index: 0;
  transform: translate(-50%, 50%) rotate(-45deg);
}
#custom-toolTip-container:hover .custom-toolTip.left {
    left:0;
    transform: translateX(-105%) translateY(-25%);
}
#custom-toolTip-container .custom-toolTip .custom-toolTip-index.left {
    right:-10px;
    left: unset;
    top:50%;
    transform: unset;
    transform:rotate(-45deg) translateY(-50%) ;
}
#custom-toolTip-container:hover .custom-toolTip.right {
    right:0;
    transform: translateX(45%) translateY(-25%);
}
#custom-toolTip-container .custom-toolTip .custom-toolTip-index.right {
    left:0px;
    top:50%;
    transform: unset;
    transform:rotate(-45deg) translateY(-50%) ;
}
#custom-toolTip-container:hover .custom-toolTip {
  display: inline-block;
}
