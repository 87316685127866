.workflow-tutorial-modal {
    margin:0 !important;

}
.workflow-tutorial-modal  .modal-dialog{
    margin:0;
    width: 80% !important;
    max-width: none;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%) !important;
}
.workflow-tutorial-modal  .modal-dialog .modal-content {
  width: 100% !important;
  height:80vh!important;
}
.workflow-tutorial-modal  .modal-dialog .modal-content .wk-video-tuto {
 display: flex;
 justify-content: center;
}
.text-content {
  width: 45%;
}

.workflow-tutorial-modal  .modal-dialog .modal-content .wk-video-tuto video{
 outline: none;
    margin-top: 100px;
    width: 50%;
    height: 100%;
}