.enter-code-field .styles_react-code-input-container__tpiKG{
    width: 100% !important;
}
.enter-code-field .styles_react-code-input-container__tpiKG input:last-child {
    border-right-color:#d5d7e4;
}
.enter-code-field .styles_react-code-input-container__tpiKG input {
    font-family: inherit;
}

.vi__container {
    width: 100%;
    display: block;
}
.vi__character {
    height: 57px;
    width: 57px;
    margin: 0;
    margin-right: 10px;
    border: 1px solid #d5d7e4;
    border-radius: 8px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
    box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
    display: inline-block;
    margin-right: 18px;
    text-align: center;
    outline: none;
    -webkit-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    color: #14182f;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    opacity: .7;
    border-color: #ccc;
    color: #ccc;
    
    
}
.vi__character--selected,.vi__character--filled {
    color: black;
    border-color: #7390ce;
    border-width: 2px;
}

