.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #123abc;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
