.billing .checkout-form-wrapper {
  width: 70%;
  margin: 0 auto;
}
.billing .checkout-form-wrapper .checkout-content-wrap {
  padding-bottom: 2px;
}
.billing .form-fields-wrapper {
  display: block;
}
.billing .fields-wrapper {
  display: flex;
  justify-content: space-between;
}
.billing .fields-wrapper .form-group {
  padding-left: 0;
  padding-right: 20px;
}
.billing .payment-info-wrap {
  margin-bottom: 20px;
}
.billing .section-info {
  width: 32%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(83, 105, 223, 0.12);
  border-radius: 8px;
  font-weight: normal;

}
.billing .value-txt {
  background: linear-gradient(0deg, #f4f3f8, #f4f3f8), #ffffff;
  padding: 5px 25px;
  border-radius: 6px;
  font-weight: 500;
}
.billing .value-txt.isUpgrade {
  background: rgba(59, 83, 209, 0.16);
  color: #4458c9;
  margin-left: 5px;
  padding-left: 7px;
}
.billing .value-txt.isDowngrade {
  background: rgba(241, 112, 39, 0.16);
  color: #f17027;
  margin-left: 5px;
  padding-left: 7px;
}
.billing .value-txt.isUpgrade img,
.billing .value-txt.isDowngrade img {
  margin-top: -2px;
}
.billing .fields-wrapper .tag-info-container {
  display: flex;
}

.billing .unlimited-tag {
  background: #ffe9b3;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  width: max-content;
  color: #495377;
  display: flex;
  align-items: center;
}
.billing .unlimited-tag img {
  margin-right: 7px;
  width: 15px;
}
.billing .value-txt.isUpgrade img {
  margin-right: 7px;
}
.billing .credits-line-info {
  width: 100%;
  justify-content: flex-start;
}
.billing .credits-line-info .plan-credits-rm {
  margin: 0px;
  flex-wrap: nowrap;
  margin-left: 10px;
}
.billing .credits-line-info .plan-credits-rm span {
  margin-left: 5px;
  width: max-content;
  white-space: nowrap;
  display: inline-flex;
    align-items: center;
  padding: 4px 10px;
  letter-spacing: -0.3px;
}
.billing .inline-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.billing .inline-info.discount {
  justify-content: flex-start;
}
.billing .inline-info.discount .section-info {
  width: max-content;
}
.billing .inline-info.discount .form-group label {
  display: none;
}
.billing .inline-info.discount .form-group {
  width: 100%;
  margin: 0;
}
.billing .inline-info.discount .form-group input {
  width: 100%;
}
.billing .inline-info.discount .section-info {
  color: #3b53d2;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.billing .inline-info.discount .section-info span {
  width: max-content;
  margin-right: 20px;
}
.billing .inline-info.discount .section-info i {
  font-size: 12px;
}
.billing .inline-info .inline-info {
  margin-top: 0px !important;
  width: 60%;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;
}
.billing .inline-info .inline-info button {
  width: 130px;
  height: 100%;
  margin-left: 30px;
  font-size: 17px;
}
.billing .inline-info.total-line {
  margin-bottom: 30px;
}
.billing .inline-info.total-line {
  font-size: 18px;
}
.billing .inline-info.total-line .value-txt {
  font-weight: 500;
}
.billing .proceed-bottom-section {
  position: fixed;
  background: #fff;
  color: #a9afc6;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  border-top: 1px solid #f4f4f4;
  padding: 20px 0;
}
.billing .proceed-bottom-section .proceed-btm {
  width: 71%;
  margin: 0 auto;
  align-items: flex-end;
}
.billing .proceed-bottom-section .proceed-btm .proceed-texts {
  width: 50%;
}
.billing .proceed-bottom-section .proceed-btm .proceed-texts label {
  text-align: justify;
  margin-bottom: 20px;
}
.billing .proceed-bottom-section .proceed-btm .proceed-texts label input {
  transform: scale(1.2);
}
.billing .proceed-bottom-section .proceed-btm button {
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 30px;
  padding-right: 30px;
  width: max-content;
}
.billing .proceed-bottom-section .proceed-btm button .ic-lock {
  margin-right: 20px;
}
.billing .btn-block {
  margin-bottom: 30px;
}
.billing .btn-block button {
}
.billing .has-error {
  margin-left: 20px;
  color: darkred;
  width: 100%;
}
.billing .line-title {
  position: relative;
  background: #fff;
  display: inline-block;
  color: #797f99;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.16px;
  margin-bottom: 10px;
}
.billing .extra-credits-table .value-txt {
  min-width: 100px !important;
  display: inline-flex;
  justify-content: center;
}

.billing .main-line strong{
  font-size: 20px;
  font-weight: 900;
}
.success_modal_payment{
  width: 600px;
  padding: 20px;
}
.success_modal_payment  p {
  text-align: center;
  font-weight: bold;
  margin: 25px 0;
  color: #333;
}
.success_modal_payment  p.large {
  font-size: 20px;
}

.success_modal_payment  p.large:first-child {
  font-size: 23px;
}


.success_modal_payment textarea {
  width: 100%;
   height: 150px;
   border-radius: 6px;
   border-color: #f4F4F4;
   resize: none;
   outline: none;
   padding: 10px;
  box-shadow: 0 0 5px rgba(0,0,0,.13);
}

.success_modal_payment  .btns-group{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.success_modal_payment  .btns-group button {
  padding: 0px 30px;
  font-size: 17px;
}

.success_modal_payment textarea::placeholder {
  color: #888;
}