/*gradient */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap');
.gradient-text {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4966ff+0,ff4083+100 */
  background: #4966ff;
  /* Old browsers */
  background: -moz-linear-gradient(left, #4966ff 0%, #ff4083 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #4966ff 0%, #ff4083 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, right top, from(#4966ff), to(#ff4083));
  background: -o-linear-gradient(left, #4966ff 0%, #ff4083 100%);
  background: linear-gradient(to right, #4966ff 0%, #ff4083 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4966ff', endColorstr='#ff4083',GradientType=1 );
  /* IE6-9 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.floating-effect input:not(:placeholder-shown) {
  background: #f5f5f9 !important;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
}

/** Adding icons to the site **/
/** Helpers includes all variabls and config files **/
/** Basic **/
/* Media query Break point */
@-webkit-keyframes translatetop {
  from {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes translatetop {
  from {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

/*@keyframes pulse {
		from {transform: scale(0.7);}
		to {transform: scale(1);}
    }*/
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.notification-icon.notify::before {
  -webkit-animation: ring 1.5s ease;
  animation: ring 1.5s ease;
}

/*@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    12.5% {
        transform: rotate(20deg);
    }
    25% {
        transform: rotate(-20deg);
    }
    37.5% {
        transform: rotate(20deg);
    }
    50% {
        transform: rotate(-20deg);
    }
    62.5% {
        transform: rotate(20deg);
    }
    75% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
*/
@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }
  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

::-webkit-input-placeholder {
  color: #111d2d;
  opacity: 1;
  font-weight: 400;
}

::-moz-placeholder {
  color: #111d2d;
  opacity: 1;
  font-weight: 400;
}

:-ms-input-placeholder {
  color: #111d2d;
  opacity: 1;
  font-weight: 400;
}

:-moz-placeholder {
  color: #111d2d;
  opacity: 1;
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

/* scroll bar css start */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(59, 83, 209, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(59, 83, 209, 0.5);
}

/*scroll bar css close*/
body {
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  text-decoration: none;
  background: url('assets/images/mainbg.jpg');
}
@media only screen and (min-width: 2200px) {
  body {
    background-size: cover;
  }
}

.container-fluid {
  padding: 0 24px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .container {
    width: 100%;
    max-width: 100%;
  }
}

/*margin top css start */
.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}
@media only screen and (max-width: 767px) {
  .mt-24 {
    margin-top: 12px;
  }
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-42 {
  margin-top: 42px;
}

.mt-46 {
  margin-top: 46px;
}

.mt-52 {
  margin-top: 52px;
}

/* margin bottom css start */
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-42 {
  margin-bottom: 42px;
}

.mb-46 {
  margin-bottom: 46px !important;
}
@media only screen and (max-width: 767px) {
  .mb-46 {
    margin-bottom: 25px !important;
  }
}

.mb-52 {
  margin-bottom: 52px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
  color: #212939;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 6px;
}

.subtitle {
  color: #687794;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 0;
}

.tooltip-inner {
  max-width: 200px;
  padding: 4px 15px;
  color: #fff;
  text-align: center;
  background-color: #5d6471;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #5d6471;
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #5d6471;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #5d6471;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #5d6471;
}

/*margin css*/
.mt-20 {
  margin-top: 20px;
}

.sub-title {
  color: #797f99;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 20px;
}

.need-help-ui .sub-title {
  margin-bottom: 0;
}

.need-help-ui .text-btn {
  display: block;
  margin-top: 20px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .container-fluid {
    padding: 0 15px;
  }
}

.w-141 {
  min-width: 141px;
}

.profile-btn:after {
  border: solid #797f99;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border-radius: 2px;
}

.cstm-select .btn-light:not(:disabled):not(.disabled).active,
.cstm-select .btn-light:not(:disabled):not(.disabled):active,
.cstm-select .show > .btn-light.dropdown-toggle {
  background: #fff;
  border-color: #fff;
}

.cstm-select.date-sorting .bootstrap-select {
  width: auto !important;
}
.cstm-select.date-sorting .bootstrap-select button {
  background: transparent;
  border: none;
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 18px;
  padding-left: 5px;
  font-family: 'Roboto', sans-serif;
}
@media only screen and (max-width: 991px) {
  .cstm-select.date-sorting .bootstrap-select button {
    font-size: 14px;
  }
}
.cstm-select.date-sorting .bootstrap-select button:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.cstm-select.date-sorting .bootstrap-select button:after {
  background: url('assets/images/ic_arrow_down.svg') no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0;
  position: absolute;
  right: -3px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cstm-select.date-sorting .dropdown-menu {
  min-width: 120px;
}

.cstm-select .bootstrap-select .dropdown-item.active,
.cstm-select .bootstrap-select .dropdown-item:active {
  background: #3b53d1;
  color: #fff;
}

.cstm-select .bootstrap-select ul li a {
  font-size: 15px;
  padding: 6px 15px 6px 15px;
}

/*checkbox css*/
.checkbox-wrap {
  display: inline-block;
}
.checkbox-wrap label {
  cursor: pointer;
  -webkit-transition:
    background 0.3s,
    border-color 0.3s,
    -webkit-box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    -webkit-box-shadow 0.2s;
  -o-transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s,
    -webkit-box-shadow 0.2s;
  margin-bottom: 0;
}
.checkbox-wrap label input {
  position: absolute;
  left: 0;
  opacity: 0;
  /* width:100%;
        height:100%;
        left:0;*/
}
.checkbox-wrap label .checkbox-icon {
  position: relative;
  width: 24px;
  height: 24px;
}
.checkbox-wrap label .checkbox-icon:after {
  position: absolute;
  content: '';
  height: 21px;
  width: 21px;
  border-radius: 4px;
  border: 1px solid #d5d7e4;
  background: #fff;
  left: 0;
  top: 0;
  -webkit-transition:
    background 0.3s,
    border-color 0.3s,
    -webkit-box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    -webkit-box-shadow 0.2s;
  -o-transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s,
    -webkit-box-shadow 0.2s;
}
.checkbox-wrap label .checkbox-icon:before {
  width: 15px;
  height: 15px;
  position: absolute;
  content: '';
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  left: 3px;
  top: 3px;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0;
  background: url('assets/images/ic_check_shadow.svg') no-repeat;
  /*border-radius:1px;*/
}
.checkbox-wrap label input:checked + .checkbox-icon:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  border-color: #fff;
  z-index: 1;
}
.checkbox-wrap label input:checked + .checkbox-icon:after {
  opacity: 1;
  background: #3b53d1;
  border-color: rgba(83, 105, 223, 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(83, 105, 223, 0.12);
  box-shadow: 0 0 0 2px rgba(83, 105, 223, 0.12);
}

.radio_btn_wrap label {
  padding-left: 30px;
  cursor: pointer;
  color: #595f79;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.radio_btn_wrap input {
  position: absolute;
  opacity: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  top: 0;
}

.radio_btn_wrap .radio-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 21px;
  height: 21px;
  z-index: 1;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.radio_btn_wrap .radio-icon:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 21px;
  width: 21px;
  border: 1px solid #d5d7e4;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.radio_btn_wrap .radio-icon:before {
  position: absolute;
  content: '';
  left: 6px;
  top: 6px;
  height: 9px;
  width: 9px;
  background-color: #fff;
  border-radius: 100%;
  z-index: 2;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.radio_btn_wrap input:checked + .radio-icon:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.radio_btn_wrap input:checked + .radio-icon:after {
  background: #5369df;
  border-color: #5369df;
}

.radio_btn_wrap input:checked ~ .label_text {
  color: #242946;
  font-weight: 500;
}

.backdrop:after {
  background: rgba(13, 16, 33, 0.06);
  width: 100%;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  content: '';
}

/* custom pagination*/
.cstm-pagination.pagination_one {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
}
@media only screen and (max-width: 991px) {
  .cstm-pagination.pagination_one {
    margin-right: 10px;
    display: none;
  }
}
.cstm-pagination.pagination_one p {
  display: inline-block;
  color: #414766;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin-bottom: 0;
}
.cstm-pagination.pagination_one .np-btn {
  margin-left: 5px;
  position: relative;
  top: 0px;
}
@media only screen and (max-width: 991px) {
  .cstm-pagination.pagination_one .np-btn {
    margin-left: 2px;
  }
}
.cstm-pagination.pagination_one .np-btn a {
  display: inline-block;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  line-height: 32px;
  text-align: center;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.cstm-pagination.pagination_one .np-btn a:first-child {
  margin-right: 2px;
}
@media only screen and (max-width: 991px) {
  .cstm-pagination.pagination_one .np-btn a:first-child {
    margin-right: 2px;
  }
}
.cstm-pagination.pagination_one .np-btn a img {
  height: 12px;
  position: relative;
}
.cstm-pagination.pagination_one .np-btn a.disabled {
  opacity: 0.7;
}
.cstm-pagination.pagination_one .np-btn a.disabled:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cstm-pagination.pagination_one .np-btn a.active {
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
}
.cstm-pagination.pagination_one .np-btn a:hover {
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
}

.cstm-pagination.pagination_two {
  text-align: center;
  margin-top: 16px;
}
.cstm-pagination.pagination_two ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cstm-pagination.pagination_two a {
  color: #797f99;
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 32px;
  text-shadow: -1px 1px 0 0 #ffffff;
  padding: 0 6px;
  margin: 0 4px;
  text-decoration: none;
}
.cstm-pagination.pagination_two a.active {
  color: #3b53d1;
  font-weight: 600;
}
.cstm-pagination.pagination_two a.disabled {
  opacity: 0.7;
}
.cstm-pagination.pagination_two a.disabled:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cstm-pagination.pagination_two a.arrow-btn {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  color: #595f79;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cstm-pagination.pagination_two a.arrow-btn.disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #a9afc6;
}
.cstm-pagination.pagination_two a.arrow-btn img {
  height: 12px;
  position: relative;
  top: 1px;
}

/* tooltip css start */
.tooltip {
  opacity: 1;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow:before {
  border-top-color: #242946 !important;
  border-bottom-color: #242946 !important;
}

.tooltip-inner {
  background-color: #242946;
  -webkit-box-shadow: 0 8px 12px 0 rgba(13, 16, 33, 0.12);
  box-shadow: 0 8px 12px 0 rgba(13, 16, 33, 0.12);
  padding: 0;
  margin: 0;
  text-align: left;
}
.tooltip-inner ul {
  position: relative;
  padding: 8px 30px;
  margin: 0;
}
.tooltip-inner ul li {
  color: #f5f5f9;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 4px;
}

.tip-content.hidden {
  display: none;
}

/*content wrapper expand css start */
.content-block {
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  /*&.expand{
    margin-left:-175px;
    @media only screen and(max-width:1300px){
      margin-left:-175px;
    }

  }*/
}

.table-content {
  overflow-x: auto;
}
.table-content table {
  min-width: 950px;
  overflow-x: auto;
}

.content-wrapper-ui .col-md-2.sticky {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 230px;
  flex: 0 0 230px;
  max-width: 230px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}

.content-wrapper-ui .col-md-2.sticky.small-navbar {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  max-width: 40px;
  padding: 0;
  margin-left: 15px;
}
@media only screen and (max-width: 991px) {
  .content-wrapper-ui .col-md-2.sticky.small-navbar {
    display: block;
    position: fixed !important;
    left: 0;
    z-index: 991;
    max-width: 220px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 220px;
    flex: 0 0 220px;
    background: #fff;
    margin-left: 0;
    width: 220px;
    top: 0;
    padding-top: 80px;
    height: 100%;
    padding: 80px 10px 0px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  }
  .content-wrapper-ui .col-md-2.sticky.small-navbar.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  .content-wrapper-ui .col-md-2.sticky.small-navbar .tooltip-box {
    display: none;
  }
  .content-wrapper-ui .col-md-2.sticky.small-navbar .nav-txt {
    opacity: 1;
    visibility: visible;
  }
}

.content-wrapper-ui .col-md-10.content-wrap {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 230px);
  flex: 0 0 calc(100% - 230px);
  max-width: calc(100% - 230px);
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}
.content-wrapper-ui .col-md-10.content-wrap.expand {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 55px);
  flex: 0 0 calc(100% - 55px);
  max-width: calc(100% - 55px);
}
@media only screen and (max-width: 991px) {
  .content-wrapper-ui .col-md-10.content-wrap.expand {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*navbar tooltip*/
.tooltip-box {
  border-radius: 5px;
  background-color: #242946;
  -webkit-box-shadow: 0 8px 12px 0 rgba(13, 16, 33, 0.12);
  box-shadow: 0 8px 12px 0 rgba(13, 16, 33, 0.12);
  padding: 3px 6px;
  display: inline-block;
  min-width: 76px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  z-index: 9999;
  bottom: -24px;
  position: absolute;
  left: 1px;
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
}
.tooltip-box p {
  color: #f5f5f9;
  font-size: 12px;
  letter-spacing: -0.32px;
  line-height: 14px;
  margin: 0;
}

.small-navbar #sideNav .navbar-nav li a:hover + .tooltip-box {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.small-navbar #sideNav .navbar-nav li a:hover {
  background: white;
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
}

.border-plus-icon {
  height: 17.5px;
  width: 17.5px;
  /*border: 1.5px solid #A1ADEE;*/
  border-radius: 3.2px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  line-height: 14px;
  text-align: center;
  font-size: 18px;
  position: relative;
  vertical-align: middle;
  top: -2px;
}
.border-plus-icon img {
  width: 20px;
}

.btn1 .border-plus-icon {
  top: -2px;
}

.tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 6px;
  margin-right: 3px;
}
.tag.linkedin-tag {
  border-radius: 6px;
  background-color: #ecf5fd;
  color: #3585ca;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}
.tag.enrichment-tag {
  border-radius: 6px;
  background-color: #ffecf5;
  color: #e23381;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}
.tag.integration-tag {
  border-radius: 6px;
  background-color: #f1edff;
  color: #573ad7;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

/* no data found css start */
.no-data {
  text-align: center;
  height: 300px;
  line-height: 300px;
  color: #ccc;
  font-size: 30px;
  border: 1px solid #f5f5f9;
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12);
}

/* no data found css close */
.bootstrap-select .dropdown-menu {
  border-radius: 6px;
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  min-width: 80px;
}
.bootstrap-select .dropdown-menu li a {
  font-size: 14px;
  padding: 0 15px;
}

.overlay-block {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  left: 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
}
.overlay-block.active {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu {
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  background: #ffffff;
  border-radius: 12px;
}
.dropdown-menu li a {
  font-size: 15px;
  padding: 5px 10px 0 15px;
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 18px;
}

.more-btn-wrapper .dropdown-menu {
  margin-top: 10px;
}

.more-btn-wrapper .more-btn:after {
  display: none;
}

.more-btn-wrapper .dropdown-menu {
  left: inherit !important;
  right: 0px;
  top: 0;
  width: 180px;
  -webkit-transform: translate3d(0, 40px, 0) !important;
  transform: translate3d(0, 40px, 0) !important;
}
.more-btn-wrapper .dropdown-menu ul {
  margin: 0;
}
.more-btn-wrapper .dropdown-menu li a {
  padding: 10px 20px;
  display: block;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 18px;
}
.more-btn-wrapper .dropdown-menu li a:hover {
  color: #242946;
  background: #f5f5f9;
}
.more-btn-wrapper .dropdown-menu li a.active,
.more-btn-wrapper .dropdown-menu li a:focus {
  color: #242946;
  background: #f5f5f9;
}
.more-btn-wrapper .dropdown-menu li a.active {
  font-weight: 500;
}

.w-45 {
  width: 45px;
}

.phone-tip-ui:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  background: url(assets/images/ic-phone.svg);
  background-size: 16px;
  left: 8px;
  top: 10px;
}

.email-tip-ui:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 18px;
  background: url(assets/images/ic-mail.svg);
  background-size: 18px;
  left: 6px;
  top: 10px;
}

.tab-pane .dropdown-menu ul li {
  margin-bottom: 0;
}

/*cs tab slider css start */
.cs-tabs-slider > ul {
  position: relative;
}
.cs-tabs-slider > ul li a.active {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.cs-tabs-slider .slider {
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  color: #3b53d1;
  width: 25%;
  position: absolute;
  border-radius: 6px;
  left: 4px;
  height: 32px;
  z-index: -1;
  top: -4px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.blue-ui {
  color: #3b53d1;
}

.graditent-text {
  background: #4966ff;
  background: -moz-linear-gradient(left, #4966ff 0%, #ff4083 100%);
  background: -webkit-linear-gradient(left, #4966ff 0%, #ff4083 100%);
  background: -webkit-gradient(linear, left top, right top, from(#4966ff), to(#ff4083));
  background: -o-linear-gradient(left, #4966ff 0%, #ff4083 100%);
  background: linear-gradient(to right, #4966ff 0%, #ff4083 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4966ff', endColorstr='#ff4083',GradientType=1 );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.desc-block .gradient-text {
  -webkit-animation: flow 4s ease-in-out infinite;
  animation: flow 4s ease-in-out infinite;
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*keyframe gradients*/
@-webkit-keyframes flow {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes flow {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.p-20 {
  padding: 20px !important;
}

.cstm-select .dropdown-menu {
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  margin-top: 12px;
  min-width: 150px !important;
}
.cstm-select .dropdown-menu .dropdown-menu.inner li.active,
.cstm-select .dropdown-menu .dropdown-menu.inner li.focus {
  color: #fff;
  outline: none !important;
}
.cstm-select .dropdown-menu .dropdown-menu.inner li a {
  padding: 12px 20px;
  color: #595f79;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 18px;
}
.cstm-select .dropdown-menu .dropdown-menu.inner li a:hover {
  color: #242946;
  background: transparent;
}
.cstm-select .dropdown-menu .dropdown-menu.inner li a:focus {
  outline: none !important;
}
.cstm-select .dropdown-menu .dropdown-menu.inner li a.active,
.cstm-select .dropdown-menu .dropdown-menu.inner li a.focus {
  color: #242946;
  outline: none !important;
  background: #f5f5f9;
}
.cstm-select .dropdown-menu .dropdown-menu.inner li a.active:hover,
.cstm-select .dropdown-menu .dropdown-menu.inner li a.focus:hover {
  color: #242946;
  background: #f5f5f9;
}

.modal-footer > * {
  margin: 0;
}

.p-24 {
  padding: 0 24px 24px;
}

/** Components includes buttons, carousel, dropdown etc.. **/
.btn-blue {
  height: 48px;
  border-radius: 6px;
  /*background: linear-gradient(140.68deg, #5369DF 0%, #4458C9 100%);*/
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#5369df),
    color-stop(#5369df),
    color-stop(#4458c9),
    to(#5369df)
  );
  background-image: -webkit-linear-gradient(right, #5369df, #5369df, #4458c9, #5369df);
  background-image: -o-linear-gradient(right, #5369df, #5369df, #4458c9, #5369df);
  background-image: linear-gradient(to left, #5369df, #5369df, #4458c9, #5369df);
  border: none;
  cursor: pointer;
  overflow: hidden;
  font-size: 18px;
  letter-spacing: 0.16px;
  line-height: 48px;
  padding: 0 36px;
  background-size: 300% 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-weight: 600;
  color: #fff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16),
    0 4px 8px 0 rgba(83, 105, 223, 0.12);
  box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16),
    0 4px 8px 0 rgba(83, 105, 223, 0.12);
}
.btn-blue:hover,
.btn-blue:focus {
  background-position: -50% 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16),
    0 4px 8px 0 rgba(83, 105, 223, 0.12);
  box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16),
    0 4px 8px 0 rgba(83, 105, 223, 0.12);
  color: #fff;
  outline: none;
}
.btn-blue:active {
  -webkit-transform: scale(0.96) translate3d(0, 0px, 0);
  transform: scale(0.96) translate3d(0, 0px, 0);
}

.btn1 {
  height: 40px;
  line-height: 40px;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.24px;
  text-shadow: -1px 1px 0 #3b53d1;
  color: #fff;
  display: inline-block;
  padding: 0 16px;
  border-radius: 10px;
  vertical-align: middle;
  background: transparent;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  overflow: hidden;
  background: -webkit-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: -o-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: linear-gradient(140.68deg, #5369df 0%, #4458c9 100%);
  -webkit-box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
}
.btn1 .icon {
  display: inline-block;
  margin-right: 8px;
  width: 16px;
  position: relative;
  top: -2px;
}
.btn1 .icon img {
  width: 100%;
}
.btn1:hover {
  color: #fff;
  background-position: right center;
  opacity: 0.85;
}

.btn2 {
  height: 40px;
  line-height: 40px;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  color: #242946;
  display: inline-block;
  padding: 0 16px;
  border-radius: 10px;
  vertical-align: middle;
  background: transparent;
  position: relative;
  z-index: 1;
  margin: 0;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
}
.btn2 .icon {
  display: inline-block;
  margin-right: 8px;
  width: 16px;
  position: relative;
  top: -2px;
}
.btn2 .icon img {
  width: 100%;
}
.btn2:hover {
  color: #242946;
  background-position: right center;
  -webkit-box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 3px 6px 0 rgba(20, 24, 47, 0.14);
  box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 3px 6px 0 rgba(20, 24, 47, 0.14);
}
.btn2.blue-text {
  color: #5369df;
  font-weight: 500;
}

.btn3 {
  height: 40px;
  line-height: 40px;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  color: #495ed0;
  display: inline-block;
  padding: 0 16px;
  border-radius: 10px;
  vertical-align: middle;
  background: #fff;
  position: relative;
  z-index: 1;
  margin: 0;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
}
.btn3 .icon {
  display: inline-block;
  margin-right: 8px;
  width: 16px;
  position: relative;
  top: -2px;
}
.btn3 .icon img {
  width: 100%;
}
.btn3:hover {
  color: #495ed0;
  background-position: right center;
  -webkit-box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 3px 6px 0 rgba(20, 24, 47, 0.14);
  box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 3px 6px 0 rgba(20, 24, 47, 0.14);
}
.btn3.blue-text {
  color: #5369df;
  font-weight: 500;
}

.btn4 {
  color: #595f79;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 24px;
  border-radius: 10px;
  vertical-align: middle;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
}
.btn4:hover {
  color: #4c5269;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
}

.btn5 {
  color: #595f79;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.12px;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 48px;
  line-height: 48px;
  margin: 0;
  padding: 0 32px;
}
.btn5:hover {
  color: #595f79;
  -webkit-box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 8px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 8px 0 rgba(20, 24, 47, 0.1);
}

.ml-20 {
  margin-left: 20px;
}

.btn6 {
  color: #3b53d1;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.12px;
  text-align: center;
  border-radius: 10px;
  vertical-align: middle;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  overflow: hidden;
  border: 1px solid #a9afc6;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  margin: 0;
}
.btn6:hover {
  -webkit-box-shadow:
    0 0 6px 0 rgba(20, 24, 47, 0.18),
    0 2px 6px 0 rgba(20, 24, 47, 0.2);
  box-shadow:
    0 0 6px 0 rgba(20, 24, 47, 0.18),
    0 2px 6px 0 rgba(20, 24, 47, 0.2);
  color: #3b53d1;
}

/*  Ripple */
.ripple {
  width: 0;
  height: 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  position: absolute;
  opacity: 1;
}

.rippleEffect {
  -webkit-animation: rippleDrop 0.6s linear;
  animation: rippleDrop 0.6s linear;
}

@-webkit-keyframes rippleDrop {
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes rippleDrop {
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

/*== tag btn css start ==*/
.tag.active-tag {
  background-color: #d7f7e7;
  border-radius: 8px;
  padding: 4px 9px;
  color: #0c6f55;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 20px;
  text-align: center;
}

/*== tag btn css end ==*/
.delete-icon-btn {
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 40px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.82);
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.1),
    0 2px 4px 0 rgba(13, 16, 33, 0.08);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.1),
    0 2px 4px 0 rgba(13, 16, 33, 0.08);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.delete-icon-btn .delete-icon {
  position: relative;
  line-height: normal;
  top: -2px;
  opacity: 0.8;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.delete-icon-btn:hover {
  -webkit-box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 2px 6px 0 rgba(13, 16, 33, 0.12);
  box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 2px 6px 0 rgba(13, 16, 33, 0.12);
}
.delete-icon-btn:hover .delete-icon {
  opacity: 1;
}

/* export btn css start  */
.export-dropdown-ui .bootstrap-select {
  min-width: 106px;
  max-width: 175px;
  width: auto !important;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}
.export-dropdown-ui .bootstrap-select button {
  border-radius: 10px;
  background: -webkit-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: -o-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: linear-gradient(140.68deg, #5369df 0%, #4458c9 100%);
  -webkit-box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  height: 40px;
  border: none;
  color: #fff;
  padding: 0 14px;
  line-height: 40px;
  font-size: 16px;
  letter-spacing: 0.24px;
  text-shadow: -1px 1px 0 0 #3b53d1;
  font-weight: 500;
  padding-right: 30px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}
.export-dropdown-ui .bootstrap-select button:focus,
.export-dropdown-ui .bootstrap-select button.btn-light.dropdown-toggle {
  outline: none !important;
  color: #fff !important;
}
.export-dropdown-ui .bootstrap-select button .filter-option-inner-inner {
  position: relative;
  padding-right: 10px;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.export-dropdown-ui .bootstrap-select button .filter-option-inner-inner:after {
  border-right: 2px solid rgba(13, 16, 33, 0.32);
  position: absolute;
  content: '';
  height: 18px;
  right: 0px;
  top: 12px;
}
.export-dropdown-ui .bootstrap-select button:after {
  border: none;
  background: url('assets/images/ic_arrow_down_w.svg') no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0;
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.export-dropdown-ui .bootstrap-select > .dropdown-menu {
  min-width: 160px;
  /*padding:5px 0;*/
  width: 160px;
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  margin-top: 12px;
}
.export-dropdown-ui .bootstrap-select > .dropdown-menu li a {
  font-size: 16px;
  padding: 12px 20px;
  border-radius: 0px;
}
.export-dropdown-ui .bootstrap-select > .dropdown-menu li a:hover {
  color: #242946;
  background: transparent;
}
.export-dropdown-ui .bootstrap-select > .dropdown-menu li a.active,
.export-dropdown-ui .bootstrap-select > .dropdown-menu li a.dropdown-item:active {
  color: #242946;
  background: #f5f5f9;
  font-weight: 500;
  outline: none !important;
}

/* export btn css close  */
/*bx btn css start */
.bx-btn {
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  padding: 0px 8px 0 12px;
  height: 40px;
  line-height: 40px;
}

.text-btn {
  color: #5369df;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.16px;
  line-height: 24px;
  text-align: right;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.text-btn:hover {
  color: #2b40b1;
  text-decoration: underline;
}

.back-home-btn {
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  text-align: right;
  display: inline-block;
  position: relative;
  padding-left: 20px;
}
.back-home-btn:hover {
  color: #3b53d2;
}
.back-home-btn:hover:before {
  left: -3px;
}
.back-home-btn:before {
  position: absolute;
  content: '';
  background: url('assets/images/arrow-prev.svg') no-repeat;
  width: 13px;
  height: 12px;
  left: 0;
  top: 6px;
  -webkit-transition: 0.25s ease-out;
  -o-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.info-btn {
  color: #414766;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.delete-account-btn {
  color: #e23f74;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  padding: 0 20px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 180px;
  max-width: 180px;
  margin-left: 20px;
}
@media only screen and (max-width: 991px) {
  .delete-account-btn {
    margin-left: 0;
  }
}
.delete-account-btn:hover {
  color: #e23f74;
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
  -webkit-box-shadow:
    0 0 3px 0 rgba(20, 24, 47, 0.16),
    0 2px 6px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 3px 0 rgba(20, 24, 47, 0.16),
    0 2px 6px 0 rgba(20, 24, 47, 0.12);
}

.discover-btn {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 10px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 120px;
  max-width: 180px;
  margin-left: 20px;
}
@media only screen and (max-width: 991px) {
  .discover-btn {
    margin-left: 0;
  }
}
.discover-btn:hover {
  color: #3b53d1;
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
  -webkit-box-shadow:
    0 0 3px 0 rgba(20, 24, 47, 0.16),
    0 2px 6px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 3px 0 rgba(20, 24, 47, 0.16),
    0 2px 6px 0 rgba(20, 24, 47, 0.12);
}

.actions-btn {
  position: relative;
}
.actions-btn .tooltip-box {
  padding: 3px 5px;
  display: none;
}
.actions-btn .icon-btn {
  padding: 0 7px;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  line-height: 32px;
  display: inline-block;
  margin: 0 5px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.actions-btn .icon-btn:hover {
  background: #f5f5f9;
}
.actions-btn .icon-btn:hover + .tooltip-box {
  visibility: visible;
  opacity: 1;
  display: block;
}
.actions-btn .icon-btn:hover + .tooltip-box p {
  color: #f5f5f9;
}

.btn-md {
  height: 48px;
  padding: 0 30px;
  text-align: center;
  line-height: 48px;
  font-size: 18px;
  font-weight: 500;
}
@media only screen and (max-width: 480px) {
  .btn-md {
    padding: 0 20px;
  }
}

.btn-md.btn4 {
  min-width: 130px;
}

.btn-56 {
  height: 58px;
  line-height: 56px;
  min-width: 160px;
  padding: 0 30px;
  border-radius: 12px;
  background: -webkit-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: -o-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: linear-gradient(140.68deg, #5369df 0%, #4458c9 100%);
  -webkit-box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 1400px) {
  .btn-56 {
    height: 52px;
    line-height: 52px;
  }
}

.linkedin-btn {
  height: 48px;
  border-radius: 10px;
  background-color: #0a66c2;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.12px;
  text-align: center;
  text-shadow: -1px 1px 0 0 #4458c9;
  padding: 0 24px 0 13px;
}
.linkedin-btn .ic-linkedin {
  border-right: 1px solid rgba(255, 255, 255, 0.24);
  margin-right: 20px;
  padding-right: 10px;
}
.linkedin-btn .ic-linkedin img {
  position: relative;
  top: -2px;
}
.linkedin-btn:hover {
  color: #fff;
}

.ripple-btn,
.btn1,
.btn2,
.btn3,
.btn5,
.btn6,
.cta-search-btn,
.cp-btn,
.ec-btn,
.contact-support-btn {
  position: relative;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  -webkit-transform: scale(1) translate3d(0px, 0px, 0);
  transform: scale(1) translate3d(0, 0px, 0);
  overflow: hidden;
}
.ripple-btn:active,
.btn1:active,
.btn2:active,
.btn3:active,
.btn5:active,
.btn6:active,
.cta-search-btn:active,
.cp-btn:active,
.ec-btn:active,
.contact-support-btn:active {
  -webkit-transform: scale(0.96) translate3d(0, 0px, 0);
  transform: scale(0.96) translate3d(0, 0px, 0);
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
button:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input::-webkit-input-placeholder {
  /* Edge */
  color: #797f99;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #797f99;
}

input::-ms-input-placeholder {
  color: #797f99;
}

input::placeholder {
  color: #797f99;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.switch-btn {
  position: relative;
  display: inline-block;
}
.switch-btn input {
  height: 20px;
  width: 38px;
  border-radius: 10px;
  background-color: #d5d7e4;
  -webkit-box-shadow: inset 0 1px 2px 0 rgba(13, 16, 33, 0.16);
  box-shadow: inset 0 1px 2px 0 rgba(13, 16, 33, 0.16);
  -webkit-appearance: none;
  position: relative;
  -webkit-transition:
    background 0.3s,
    border-color 0.3s,
    -webkit-box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    -webkit-box-shadow 0.2s;
  -o-transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s;
  transition:
    background 0.3s,
    border-color 0.3s,
    box-shadow 0.2s,
    -webkit-box-shadow 0.2s;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.switch-btn input:focus {
  outline: none;
}
.switch-btn input:after {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(13, 16, 33, 0.24);
  box-shadow: 0 2px 4px 0 rgba(13, 16, 33, 0.24);
  left: 0px;
  top: 2px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-transform: translate(2px, 0);
  -ms-transform: translate(2px, 0);
  transform: translate(2px, 0);
}
.switch-btn input:checked {
  background-color: #5369df;
  -webkit-box-shadow: inset 0 1px 2px 0 rgba(13, 16, 33, 0.24);
  box-shadow: inset 0 1px 2px 0 rgba(13, 16, 33, 0.24);
  outline: none;
}
.switch-btn input:checked:after {
  -webkit-transform: translate(20px, 0);
  -ms-transform: translate(20px, 0);
  transform: translate(20px, 0);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

/*{
	transition: background .3s, border-color .3s, box-shadow .2s;
}*/
/****  floating-Lable style start ****/
.floating-effect {
  position: relative;
  margin-bottom: 20px;
}
.floating-effect label {
  color: #797f99;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 20px;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  margin: 0;
}
@media only screen and (max-width: 599px) {
  .floating-effect label {
    font-size: 15px;
  }
}
.floating-effect input {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.32px;
  font-weight: 500;
  padding: 0;
  display: block;
  width: 100%;
  padding-left: 11px;
  padding-top: 20px;
  color: #14182f;
  height: 59px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  -webkit-transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
@media only screen and (max-width: 599px) {
  .floating-effect input {
    height: 48px;
    font-size: 14px;
    padding-top: 16px;
    padding-left: 12px;
    font-weight: normal;
  }
}
.floating-effect input:focus {
  outline: none;
  border-color: #5369df;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
  box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
}
.floating-effect input.valid ~ .checked-icon {
  /*position: absolute;
					top:14px;
					right:16px;*/
  opacity: 1;
  visibility: visible;
}
.floating-effect input.valid {
  outline: none;
  border-color: #5369df;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
  box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
}
.floating-effect input.valid + label {
  color: #3b53d1 !important;
}
.floating-effect .checked-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 16px;
  opacity: 0;
  visibility: hidden;
}
.floating-effect input:not(:placeholder-shown) {
  background: #fff;
}
.floating-effect input:focus:not(:placeholder-shown) ~ label,
.floating-effect textarea:focus:not(:placeholder-shown) ~ label {
  color: #3b53d1;
}
.floating-effect select:focus {
  outline: none;
  border-color: #057aff;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 4px rgba(52, 128, 255, 0.16);
  box-shadow: 0 0 0 4px rgba(52, 128, 255, 0.16);
}
.floating-effect input:focus ~ label,
.floating-effect input:not(:placeholder-shown) ~ label,
.floating-effect textarea:focus ~ label,
.floating-effect textarea:not(:placeholder-shown) ~ label {
  top: 9px;
  left: 11px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.24px;
  font-weight: 500;
  color: #90a0b9;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
}
@media only screen and (max-width: 599px) {
  .floating-effect input:focus ~ label,
  .floating-effect input:not(:placeholder-shown) ~ label,
  .floating-effect textarea:focus ~ label,
  .floating-effect textarea:not(:placeholder-shown) ~ label {
    top: 7px;
  }
}
.floating-effect select:focus ~ label,
.floating-effect select:not([value='']):valid ~ label {
  top: 5px;
  font-size: 14px;
  color: #90a0b9;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
}
.floating-effect.textarea-field textarea {
  height: 141px;
  width: 100%;
  border: 1px solid #dde1eb;
  border-radius: 10px;
  background-color: #f6f8fc;
  resize: none;
  padding: 30px 15px 10px;
  -webkit-transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}
.floating-effect.textarea-field textarea:focus {
  outline: none;
  border-color: #057aff;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 4px rgba(52, 128, 255, 0.16);
  box-shadow: 0 0 0 4px rgba(52, 128, 255, 0.16);
}
.floating-effect.textarea-field label {
  top: 20px;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  line-height: normal;
}
.floating-effect.textarea-field textarea:not(:placeholder-shown) {
  background: #fff;
}
.floating-effect .forgot-btn {
  position: absolute;
  right: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #5369df;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 24px;
  text-align: right;
}
.floating-effect .forgot-btn:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 599px) {
  .floating-effect .forgot-btn {
    font-size: 14px;
    top: 12px;
    right: 12px;
  }
}
.floating-effect input[type='password'] {
  padding-right: 90px;
}

.h-58 {
  height: 58px;
  line-height: 56px;
}
@media only screen and (max-width: 599px) {
  .h-58 {
    height: 48px;
    line-height: 48px;
  }
}

.phone-filed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.phone-filed .flag-selectpicker {
  margin-right: 15px;
}
.phone-filed .flag-selectpicker .bootstrap-select {
  width: 77px !important;
}
.phone-filed .flag-selectpicker .bootstrap-select .dropdown-toggle {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  height: 59px;
  width: 77px;
  font-size: 0;
}
@media only screen and (max-width: 599px) {
  .phone-filed .flag-selectpicker .bootstrap-select .dropdown-toggle {
    height: 48px;
  }
}
.phone-filed .flag-selectpicker .dropdown-menu {
  max-height: 200px;
}
.phone-filed .flag-selectpicker .dropdown-menu li a .text {
  display: none;
}
.phone-filed .flag-selectpicker .dropdown-menu li a .inline-flag {
  width: 32px;
  height: 24px;
}
.phone-filed .form-group {
  width: calc(100% - 75px);
  width: 100%;
}

input:focus {
  outline: none;
  border-color: #3b53d1;
}

.floating-effect.form-group .pswd-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  background: url('assets/images/eye-off.svg') no-repeat;
  width: 24px;
  height: 24px;
  content: '';
}
.floating-effect.form-group .pswd-icon.active {
  background: url('assets/images/eye.svg') no-repeat;
}

.floating-effect.error {
  position: relative;
}
.floating-effect.error:after {
  background: url('assets/images/ic-error.svg');
  width: 24px;
  height: 24px;
  position: absolute;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 16px;
}
.floating-effect.error input {
  border-color: #e23f74;
  -webkit-box-shadow: 0 0 0 3px rgba(226, 63, 116, 0.16);
  box-shadow: 0 0 0 3px rgba(226, 63, 116, 0.16);
}

.floating-effect.success:after {
  background: url('assets/images/ic-checked.svg');
  width: 24px;
  height: 24px;
  position: absolute;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 16px;
}

.floating-effect.success input {
  border-color: #5369df;
  -webkit-box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
  box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
}

/** Layout folder styles for major sections of the layout like a header, footer and styles for a grid system **/
header {
  position: relative;
  width: 100%;
  z-index: 99;
  background: url(assets/images/mainbg.jpg);
  -webkit-filter: saturate(100%);
  filter: saturate(100%);
}

.top-header {
  background: transparent;
  padding: 16px 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
@media only screen and (max-width: 991px) {
  .top-header {
    min-height: 57px;
    padding: 10px 0;
  }
}
.top-header .site-logo {
  min-width: 230px;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.top-header .site-logo.fixed-logo {
  opacity: 0;
  visibility: hidden;
}
@media only screen and (max-width: 991px) {
  .top-header .site-logo.fixed-logo {
    opacity: 1;
    visibility: visible;
  }
}
.top-header .site-logo.s-logo {
  min-width: 35px;
  max-width: 40px;
  margin-right: 15px;
  overflow: hidden;
}
.top-header .site-logo a {
  width: 110px;
  display: inline-block;
}
.top-header .site-logo a img {
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .top-header .site-logo {
    width: 80px;
  }
  .top-header .site-logo img {
    width: 100%;
  }
}
.top-header .page-title p {
  margin-bottom: 0;
  color: #a9afc6;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.48px;
  line-height: 32px;
}
@media only screen and (max-width: 767px) {
  .top-header .page-title {
    display: none;
  }
}
.top-header .back_btn a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #ffffff;
}
.top-header .back_btn a .icon-ui {
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  display: inline-block;
  line-height: 30px;
  text-align: center;
  color: #3b53d1;
  margin-right: 10px;
}
.top-header .inner-header-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.top-header .inner-header-block .site-logo {
  /*opacity:0;
            visibility: hidden;*/
}
.top-header .inner-header-block .right-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
}
.top-header .inner-header-block .right-block .credit-point {
  margin-right: 20px;
}
@media only screen and (max-width: 599px) {
  .top-header .inner-header-block .right-block .credit-point {
    display: none;
  }
}
.top-header .inner-header-block .right-block .credit-point .cp-btn {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  display: inline-block;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.12px;
  color: #0d1021;
  min-width: 140px;
  padding: 0 8px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  text-decoration: none;
}
.top-header .inner-header-block .right-block .credit-point .cp-btn:hover {
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.16);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.16);
  background-color: white;
}
.top-header .inner-header-block .right-block .credit-point .cp-btn .icon {
  margin-right: 8px;
  display: inline-block;
  position: relative;
  top: -2px;
  width: 22px;
}
.top-header .inner-header-block .right-block .credit-point .cp-btn .icon img {
  width: 100%;
}
.top-header .inner-header-block .right-block .earn-credit {
  margin-right: 20px;
}
.top-header .inner-header-block .right-block .earn-credit .ec-btn {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  padding: 0 15px 0 15px;
  display: inline-block;
  height: 38px;
  line-height: 38px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.12px;
  color: #0d1021;
  min-width: 140px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  text-decoration: none;
  text-align: center;
  alig-tems: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.top-header .inner-header-block .right-block .earn-credit .ec-btn:hover {
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.16);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.16);
  background-color: white;
}
.top-header .inner-header-block .right-block .earn-credit .ec-btn .icon {
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
  top: -3px;
}
.top-header .inner-header-block .right-block .notification-ui {
  padding-right: 18px;
  margin-right: 18px;
  position: relative;
}
.top-header .inner-header-block .right-block .notification-ui:after {
  position: absolute;
  content: '';
  top: 8px;
  right: 0;
  height: 24px;
  width: 1px;
  border: 1px solid #d5d7e4;
}
.top-header .inner-header-block .right-block .notification-ui .ntfc-btn {
  display: inline-block;
  height: 38px;
  width: 38px;
  line-height: 38px;
  text-align: center;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  position: relative;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  /*&.active,
                    &:focus,
                    &:hover{
                            .notification-icon{
                               &:after{
                                 animation: ring 1.5s ease;
                               }
                            }
                        }*/
}
.top-header .inner-header-block .right-block .notification-ui .ntfc-btn:hover {
  background-color: white;
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.3);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.3);
}
.top-header .inner-header-block .right-block .notification-ui .ntfc-btn .notification-icon {
  width: 24px;
  display: inline-block;
}
.top-header .inner-header-block .right-block .notification-ui .ntfc-btn .notification-icon img {
  width: 100%;
  display: none;
}
.top-header .inner-header-block .right-block .notification-ui .ntfc-btn .notification-icon:after {
  position: absolute;
  content: '';
  height: 23px;
  width: 24px;
  background: url('assets/images/ic_notification.png');
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
  left: 0;
  right: 0;
  background-size: 100%;
  top: 8px;
  margin: auto;
  -webkit-animation: tada 2.5s ease;
  animation: tada 2.5s ease;
}
.top-header .inner-header-block .right-block .notification-ui .ntfc-btn .counter-icon {
  background: #e23f74;
  -webkit-box-shadow: 0 1px 2px 0 rgba(226, 63, 116, 0.16);
  box-shadow: 0 1px 2px 0 rgba(226, 63, 116, 0.16);
  min-width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: -6px;
  top: -2px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.12px;
  text-align: center;
  color: #fff;
  line-height: 16px;
  padding: 0 3px;
  /*-webkit-animation: pulse 2s ease-out 1;*/
}
.top-header .inner-header-block .right-block .profile-ui {
  margin-right: 10px;
}
.top-header .inner-header-block .right-block .profile-ui .profile-btn {
  width: 164px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .top-header .inner-header-block .right-block .profile-ui .profile-btn {
    width: 55px;
  }
}
.top-header .inner-header-block .right-block .profile-ui .profile-btn:after {
  background: url('assets/images/ic_arrow_down.svg') no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0;
  position: absolute;
  right: -2px;
  top: 36%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.top-header .inner-header-block .right-block .profile-ui .user-icon {
  height: 38px;
  width: 38px;
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  border-radius: 100%;
  line-height: 38px;
  text-align: center;
}
.top-header .inner-header-block .right-block .profile-ui .user-icon .icon-proifle-bg {
  width: 24px;
  display: inline-block;
}
.top-header .inner-header-block .right-block .profile-ui .user-icon .icon-proifle-bg img {
  width: 100%;
}
.top-header .inner-header-block .right-block .profile-ui .user-name {
  font-size: 16px;
  letter-spacing: -0.12px;
  line-height: 18px;
  margin-left: 8px;
  text-align: left;
  color: #0d1829;
}
@media only screen and (max-width: 767px) {
  .top-header .inner-header-block .right-block .profile-ui .user-name {
    display: none;
  }
}
.top-header .inner-header-block .right-block .profile-ui .user-name span {
  display: block;
  font-size: 12px;
  letter-spacing: 0.72px;
  line-height: 14px;
  color: #797f99;
  text-transform: uppercase;
  margin-top: 3px;
}

.profile-dropdown {
  width: 200px;
  padding: 10px 0;
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  margin-top: 12px;
  -webkit-transform: translate3d(-27px, 0px, 0px) !important;
  transform: translate3d(-27px, 0px, 0px) !important;
  top: inherit !important;
}
@media only screen and (max-width: 767px) {
  .profile-dropdown {
    left: inherit !important;
    right: 0 !important;
    -webkit-transform: translate3d(10px, 0px, 0px) !important;
    transform: translate3d(10px, 0px, 0px) !important;
  }
}
.profile-dropdown .editprofile-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.profile-dropdown .editprofile-ui .user-image {
  background: #ffd2dd;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  text-align: center;
}
.profile-dropdown .editprofile-ui .user-name {
  color: #14182f;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10px;
}
.profile-dropdown .editprofile-ui .user-name .update-btn {
  display: block;
}
.profile-dropdown ul {
  margin-bottom: 0;
  padding-bottom: 0;
}
.profile-dropdown ul li a {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 8px 15px 8px 15px;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.profile-dropdown ul li a:hover {
  background: #f5f5f9;
}
.profile-dropdown ul li a:focus,
.profile-dropdown ul li a.active {
  color: #242946;
  background: #f5f5f9;
}

.navbar .navbar-toggler {
  display: none;
}

.site-logo a {
  width: 110px;
  display: inline-block;
}
.site-logo a img {
  width: 100%;
}

.site-logo.s-logo {
  min-width: 35px;
  max-width: 40px;
  margin-right: 15px;
  overflow: hidden;
}

.notification-dropdown {
  position: absolute;
  padding: 0;
  width: 320px;
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  right: 0px !important;
  left: inherit !important;
  top: 5px !important;
  max-height: 452px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-transform: translate3d(0px, 42px, 0px) !important;
  transform: translate3d(0px, 42px, 0px) !important;
}
.notification-dropdown .notification-header {
  padding: 20px 20px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.notification-dropdown .notification-header h5 {
  margin-bottom: 0;
  color: #0d1021;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.notification-dropdown .notification-header .see-all-btn {
  margin-left: auto;
  color: #3b53d1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
  padding: 5px 10px;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  border-radius: 4px;
}
.notification-dropdown .notification-header .see-all-btn .ic-arrow {
  margin-left: 6px;
}
.notification-dropdown .notification-header .see-all-btn:hover {
  background: rgba(59, 83, 209, 0.1);
}
.notification-dropdown .notification-body {
  padding: 0 0px 0px;
}
.notification-dropdown .notification-body ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.notification-dropdown .notification-body ul li a {
  padding: 10px 20px 10px 64px;
  position: relative;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.notification-dropdown .notification-body ul li a:hover {
  background: #f7f7f7;
}
.notification-dropdown .notification-body ul li a:hover .ic-notification {
  background: #e9edff;
}
.notification-dropdown .notification-body ul li p {
  color: #0d1121;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 8px;
}
.notification-dropdown .notification-body ul li p .highlight {
  color: #3b53d1;
  text-decoration: underline;
  font-weight: 500;
}
.notification-dropdown .notification-body ul li p .bold-ui {
  color: #0d1021;
  font-weight: 600;
  border-bottom: 1px solid #3b53d1;
}
.notification-dropdown .notification-body ul li .date-ui {
  display: block;
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
.notification-dropdown .notification-body ul li .ic-notification {
  background: #edf0ff;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  left: 20px;
  top: 12px;
  text-align: center;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}

.small-navbar #sideNav .navbar-nav li a.dropdown-ui:after {
  display: none;
}

#sideNav {
  top: 63px;
  height: calc(100% - 64px);
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
#sideNav .site-logo.harmburger {
  margin-top: -71px;
  margin-bottom: 40px;
}

.small-navbar {
  width: 40px;
}
.small-navbar .navbar-nav li a {
  padding: 9px 9px 9px 9px;
  min-height: 40px;
}
.small-navbar .navbar-nav li a .nav-txt {
  visibility: hidden;
  opacity: 0;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  height: calc(100% - 100px);
  z-index: 99;
}

#sideNav {
  background-color: transparent;
  width: 100%;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  height: calc(100vh - 100px);
  /*overflow-y:auto;*/
}
#sideNav .navbar-nav li a .icon.dashboard-icon {
  background: url('assets/images/ic_home.png');
}
#sideNav .navbar-nav li a .icon.ic-tasks {
  background: url('assets/images/ic-task.png');
  background-repeat: no-repeat !important;
}
#sideNav .navbar-nav li a .icon.company-icon {
  background: url('assets/images/ic_work.png');
}
#sideNav .navbar-nav li a .icon.search-icon {
  background: url('assets/images/ic-search-gray.png');
}
#sideNav .navbar-nav li a .icon.lead-icon {
  background: url('assets/images/ic_leads.png');
}
#sideNav .navbar-nav li a .icon.team-icon {
  background: url('assets/images/ic_team.png');
}
#sideNav .navbar-nav li a .icon.workflow-icon {
  background: url('assets/images/ic_workflow.png');
}
#sideNav .navbar-nav li a .icon.billing-icon {
  background: url('assets/images/ic_billing.png') no-repeat;
  height: 20px;
  background-position: center 0;
}
#sideNav .navbar-nav li a .icon.setting-icon {
  background: url('assets/images/ic_settings.png') no-repeat;
}
#sideNav .navbar-nav li a:hover .nav-txt {
  color: #242946;
}
#sideNav .navbar-nav li a:hover .icon,
#sideNav .navbar-nav li a.active .icon,
#sideNav .navbar-nav li a:focus .icon {
  opacity: 1;
}
#sideNav .navbar-nav li a.active {
  background-color: rgba(255, 255, 255, 0.72);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
}
#sideNav .navbar-nav li a.active .icon.dashboard-icon {
  background: url('assets/images/ic-home-active.png') !important;
}
#sideNav .navbar-nav li a.active .icon.ic-tasks {
  background: url('assets/images/ic-task-active.png') !important;
  background-repeat: no-repeat !important;
}
#sideNav .navbar-nav li a.active .icon.company-icon {
  background: url('assets/images/ic_work.png');
}
#sideNav .navbar-nav li a.active .icon.search-icon {
  background: url('assets/images/ic-search-active.png');
}
#sideNav .navbar-nav li a.active .icon.lead-icon {
  background: url('assets/images/ic_leads active.png') !important;
  background-size: cover !important;
}
#sideNav .navbar-nav li a.active .icon.team-icon {
  background: url('assets/images/ic_organization-active.png') !important;
  background-size: cover !important;
}
#sideNav .navbar-nav li a.active .icon.workflow-icon {
  background: url('assets/images/ic_workflow-active.png') !important;
  background-size: cover !important;
}
#sideNav .navbar-nav li a.active .icon.billing-icon {
  background: url('assets/images/ic_billing-active.png') !important;
  background-size: cover !important;
}
#sideNav .navbar-nav li a.active .icon.setting-icon {
  background: url('assets/images/ic_settings-active.png') !important;
  background-size: cover !important;
}
#sideNav .navbar-nav li a.active .nav-txt {
  color: #3b53d1;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}
#sideNav .navbar-nav li a .nav-txt {
  margin-left: 12px;
  display: inline-block;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}
#sideNav .navbar-nav li a span {
  vertical-align: middle;
}
#sideNav .navbar-nav li a:hover span:before,
#sideNav .navbar-nav li a.active span:before {
  color: #ccc;
}
#sideNav .navbar-nav li a [class^='icon-'] {
  font-size: 24px;
  top: -2px;
  position: relative;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  display: inline-block;
  width: 28px;
}

#sideNav.editor-left-panel {
  top: 135px;
}
#sideNav.editor-left-panel .navbar-nav {
  padding-top: 10px;
}

.overlay-layout {
  position: fixed;
  z-index: 99;
  background: rgba(255, 255, 255, 0.7);
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.modal-backdrop {
  background: rgba(13, 16, 33, 0.72);
  opacity: 1;
}
.modal-backdrop.show {
  opacity: 1;
}

.modal-backdrop {
  -webkit-transition: background-color 0.5s ease linear;
  -o-transition: background-color 0.5s ease linear;
  transition: background-color 0.5s ease linear;
}

.modal-md {
  width: 100%;
  max-width: 768px;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, 0) scale(0.8);
  -ms-transform: translate(0, 0) scale(0.8);
  transform: translate(0, 0) scale(0.8);
}
@media only screen and (max-width: 575px) {
  .modal.fade .modal-dialog {
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0) scale(1);
  -ms-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}
@media only screen and (max-width: 575px) {
  .modal.show .modal-dialog {
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.modal .modal-dialog {
  max-height: 100%;
}
@media only screen and (max-width: 767px) {
  .modal .modal-dialog {
    height: 100%;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
  .modal .modal-dialog .modal-content {
    height: 100%;
    border-radius: 0;
  }
  .modal .modal-dialog .modal-content .modal-body {
    height: 100%;
    overflow-y: auto;
  }
  .modal .modal-dialog .modal-content .modal-body .template-listing {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .modal .modal-dialog .modal-content .modal-body .template-listing .template-grid {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.modal .modal-header {
  position: relative;
  padding: 24px 75px 20px 24px;
  border-bottom: 1px solid #f2f2f6;
}
.modal .modal-header .close {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  background-color: #f5f5f9;
  line-height: 40px;
  padding: 0 5px;
  text-align: center;
  font-weight: normal;
  font-size: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  margin: 0;
  opacity: 1;
  color: #797f99;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.modal .modal-header .close:hover {
  opacity: 1;
  color: #454545;
}
.modal .modal-header .close img {
  position: relative;
  top: -2px;
}
.modal .modal-header .step-info {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.modal .modal-footer {
  border-top: 1px solid #f2f2f6;
  padding: 20px 20px 24px;
}
.modal .modal-footer .btn2 {
  margin-left: 16px;
}
.modal .modal-footer .btn2 .ic-btn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
  margin-right: 8px;
  line-height: normal;
}

.modal .modal-content {
  border-radius: 16px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  border: none;
}

.modal.profile-modal .modal-dialog {
  width: 100%;
  max-width: 520px;
  -webkit-transition: all 350ms ease;
  -o-transition: all 350ms ease;
  transition: all 350ms ease;
  max-height: 100%;
  margin: 0 auto;
  padding: 30px 0;
}
@media only screen and (max-width: 767px) {
  .modal.profile-modal .modal-dialog {
    height: 100%;
    max-width: 100%;
    padding: 0;
  }
}
.modal.profile-modal .modal-dialog .modal-content {
  height: 100%;
}
.modal.profile-modal .modal-dialog .modal-content .modal-body {
  max-height: calc(100vh - 290px);
  overflow-y: auto;
}
@media only screen and (max-width: 767px) {
  .modal.profile-modal .modal-dialog .modal-content .modal-body {
    height: 100%;
    max-height: 100%;
  }
}

.modal.profile-modal .modal-inner-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal.profile-modal .profile-img-block {
  width: 96px;
  height: 96px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
}
.modal.profile-modal .profile-img-block.red-bg {
  background: #ffd2dd;
}
.modal.profile-modal .profile-img-block.red-bg p {
  color: #ca5372;
}
.modal.profile-modal .profile-img-block.light_yellow {
  background: #ffe9ae;
}
.modal.profile-modal .profile-img-block.light_yellow p {
  color: #977e3b;
}
.modal.profile-modal .profile-img-block.light_green {
  background: #d7f7e7;
}
.modal.profile-modal .profile-img-block.light_green p {
  color: #458364;
}
.modal.profile-modal .profile-img-block.light_blue {
  background: #e7ebfe;
}
.modal.profile-modal .profile-img-block.light_blue p {
  color: #ca5372;
}
@media only screen and (max-width: 599px) {
  .modal.profile-modal .profile-img-block {
    height: 75px;
    width: 75px;
  }
}
.modal.profile-modal .profile-img-block p {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #ca5372;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  text-shadow: -1px 1px 0 0 #ffe1e9;
}
.modal.profile-modal .profile-img-block img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.modal.profile-modal .profile-info {
  margin-left: 16px;
}
.modal.profile-modal .profile-info .username {
  color: #14182f;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 4px;
  font-family: 'Roboto', sans-serif;
}
.modal.profile-modal .profile-info .designation {
  color: #595f79;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 19px;
  display: block;
  margin-bottom: 15px;
}
.modal.profile-modal .profile-info .social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0;
}
.modal.profile-modal .profile-info .social-list li {
  margin-right: 9px;
}
.modal.profile-modal .profile-info .social-list li a {
  height: 27px;
  width: 27px;
  text-align: center;
  border: 1px solid #d5d7e4;
  border-radius: 4px;
  display: block;
  background: #fff;
  outline: none;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  position: relative;
}
.modal.profile-modal .profile-info .social-list li a:hover {
  border-color: #999ba7;
}
.modal.profile-modal .profile-info .social-list li a .icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.modal.profile-modal .profile-info .social-list li a.lnkdn-icon.active {
  background: #006699;
  border-color: #006699;
}
.modal.profile-modal .profile-info .social-list li a.lnkdn-icon.active .icon {
  background: url('assets/images/sprite.png') no-repeat;
  background-position: 1px -16px;
}
.modal.profile-modal .profile-info .social-list li a.lnkdn-icon .icon {
  background: url('assets/images/sprite.png') no-repeat;
  background-position: 1px 3px;
  width: 20px;
  height: 20px;
}
.modal.profile-modal .profile-info .social-list li a.twitter-icon.active {
  background: #50abf1;
  border-color: #50abf1;
}
.modal.profile-modal .profile-info .social-list li a.twitter-icon.active .icon {
  background: url('assets/images/sprite.png') no-repeat;
  background-position: -18px -16px;
}
.modal.profile-modal .profile-info .social-list li a.twitter-icon .icon {
  background: url('assets/images/sprite.png') no-repeat;
  background-position: -18px 4px;
}
.modal.profile-modal .profile-info .social-list li a.github-icon.active {
  background: #323131;
  border-color: #323131;
}
.modal.profile-modal .profile-info .social-list li a.github-icon.active .icon {
  background: url('assets/images/sprite.png') no-repeat;
  background-position: -38px -16px;
}
.modal.profile-modal .profile-info .social-list li a.github-icon .icon {
  background: url('assets/images/sprite.png') no-repeat;
  background-position: -38px 4px;
}

.profile-detail .info-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
.profile-detail .info-container:last-child {
  margin-bottom: 5px;
}
.profile-detail .info-container .left-wrap {
  width: 100px;
  text-align: right;
}
.profile-detail .info-container .left-wrap label {
  color: #797f99;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0;
}
.profile-detail .info-container .right-wrap {
  padding-left: 20px;
}
.profile-detail .info-container .right-wrap p {
  color: #242946;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 24px;
  margin-bottom: 0;
}
.profile-detail .info-container .read-more {
  color: #3b53d1;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 24px;
  position: relative;
  margin-top: 4px;
  display: inline-block;
}
.profile-detail .info-container .read-more:after {
  position: absolute;
  content: '';
  height: 1px;
  width: 100%;
  background: #3b53d1;
  bottom: -2px;
  left: 0;
}
.profile-detail .info-container ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
}
.profile-detail .info-container ul li .left-ui {
  color: #0d1021;
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 24px;
  width: 40%;
}
.profile-detail .info-container ul li .right-ui {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
}
.profile-detail .info-container ul li .right-ui .ic-check {
  margin-right: 8px;
}

/* workflow modal*/
.modal-inner-header .title {
  color: #0d1829;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}

.modal-inner-header .label-text {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.create-new-modal {
  padding: 0 24px;
}
.create-new-modal .inner-content {
  padding: 0 24px;
}
.create-new-modal .form-group label {
  color: #797f99;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 20px;
  margin-bottom: 8px;
  display: block;
}
.create-new-modal .form-group input {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #f5f5f9;
  height: 49px;
  width: 100%;
  padding: 10px;
}
.create-new-modal .modal-footer a {
  padding: 0 30px;
  font-size: 18px;
  height: 48px;
  line-height: 48px;
}

body.overflow {
  overflow: hidden;
}

.modal-inner-header .heading {
  color: #0d1021;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}

.modal-inner-header .descr-ui {
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: 18px;
}

.manage-plan-modal .modal-dialog {
  width: 100%;
  max-width: 570px;
}
@media only screen and (max-width: 767px) {
  .manage-plan-modal .modal-dialog {
    max-width: 100%;
  }
}

.manage-plan-modal .modal-body {
  padding-bottom: 0;
}

.manage-plan-modal .modal-footer {
  position: relative;
}
.manage-plan-modal .modal-footer .footer-manage-list:before {
  background: #d5d7e4;
  width: 90%;
  margin: auto;
  height: 1px;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
}

.footer-manage-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.footer-manage-list li {
  width: 50%;
}
.footer-manage-list li a {
  padding-left: 50px;
  position: relative;
  color: #595f79;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.footer-manage-list li a .icon-ui {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.manage-plan-ui ul li a {
  padding: 16px 16px 16px 60px;
  display: inline-block;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  border-radius: 12px;
  width: 100%;
  position: relative;
}
.manage-plan-ui ul li a:hover,
.manage-plan-ui ul li a.focus,
.manage-plan-ui ul li a.active {
  background: rgba(59, 83, 209, 0.05);
}
.manage-plan-ui ul li a:hover h5 {
  color: #3b53d1;
}
.manage-plan-ui ul li a h5 {
  color: #242946;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 24px;
  margin-bottom: 8px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.manage-plan-ui ul li a p {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
}
.manage-plan-ui ul li a .icon-ui {
  display: inline-block;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.mb-8 {
  margin-bottom: 8px;
}

.btn48 {
  padding: 0 30px;
  font-size: 18px;
  height: 48px;
  line-height: 48px;
}

/*manage user modal */
.your-plan-ui {
  background: #f5f5f9;
  border-radius: 8px;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.your-plan-ui label {
  color: #3b53d1;
  font-size: 12px;
  letter-spacing: 0.14px;
  line-height: 14px;
  margin-bottom: 10px;
}
.your-plan-ui h5 {
  color: #242946;
  font-size: 18px;
  letter-spacing: -0.11px;
  line-height: 24px;
  margin-bottom: 8px;
}
.your-plan-ui p {
  color: #414766;
  font-size: 12px;
  letter-spacing: 0.14px;
  line-height: 14px;
}
.your-plan-ui .right-ui {
  margin-left: auto;
  color: #414766;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 14px;
  text-align: right;
  margin-left: auto;
}
.your-plan-ui .right-ui .active-user {
  border: 1px solid #d5d7e4;
  border-radius: 4px;
  padding: 5px;
  font-weight: 600;
}
.your-plan-ui .right-ui .active-user.white-bg {
  background: #fff;
}
.your-plan-ui .right-ui .active-user .green {
  color: #0c6f55;
  font-weight: bold;
}

.modify-user-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 20px 10px 0px 5px;
}
.modify-user-block .left-ui {
  width: 50%;
  padding-left: 40px;
  position: relative;
}
.modify-user-block .left-ui h6 {
  color: #242946;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-bottom: 6px;
}
.modify-user-block .left-ui p {
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0.14px;
  line-height: 16px;
}
.modify-user-block .left-ui .icon-user {
  position: absolute;
  left: 4px;
  top: 0px;
}
.modify-user-block .left-ui.fullwidth {
  width: 90%;
  padding-right: 20px;
}
.modify-user-block .left-ui.fullwidth p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.28px;
  line-height: 22px;
}
.modify-user-block .left-ui.fullwidth .price-tag {
  display: inline-block;
  margin-top: 22px;
  border-radius: 8px;
  background-color: #eaedfb;
  padding: 6px 14px;
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 24px;
}
.modify-user-block .right-ui {
  margin-left: auto;
}
.modify-user-block .right-ui .quantity-btn-ui {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  padding: 6px;
  min-width: 140px;
  max-width: 140px;
  text-align: center;
}
.modify-user-block .right-ui .quantity-btn-ui input {
  width: 59px;
  margin: 0 5px;
  border: none;
  background: #fff;
  outline: none;
  text-align: center;
  color: #0d1021;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.modify-user-block .right-ui .quantity-btn-ui button {
  height: 28px;
  width: 28px;
  line-height: 28px;
}

.video-modal-wrapper .modal-dialog {
  max-width: 800px;
  width: 100%;
  height: 600px;
}
.video-modal-wrapper .modal-dialog .modal-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.video-modal-wrapper .modal-dialog .modal-content {
  height: 100%;
}
.video-modal-wrapper .modal-dialog .modal-content .video-modal-ui {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.video-modal-wrapper .modal-dialog .modal-content .video-modal-ui video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/* add member modal css start*/
.w600 {
  width: 100%;
  max-width: 600px;
}

.add-member-fields {
  padding: 0 24px 24px;
}
.add-member-fields .form-group {
  margin-bottom: 24px;
}
.add-member-fields .form-group label {
  width: 100%;
  margin-bottom: 7px;
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 20px;
}
.add-member-fields .form-group input {
  width: 100%;
  height: 45px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #f5f5f9;
  padding: 0 10px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.add-member-fields .form-group input:focus {
  border: 1px solid #a9afc6;
  background: #fff;
}
.add-member-fields .form-group input:not(:placeholder-shown) {
  border: 1px solid #a9afc6;
  background: #fff;
}
.add-member-fields .checkbox-wrap label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.add-member-fields .checkbox-wrap label .checkbox-icon {
  width: 21px;
  height: 21px;
}
.add-member-fields .checkbox-wrap label .checkbox-text {
  margin-left: 10px;
  color: #14182f;
  font-size: 16px;
  letter-spacing: 0.32px;
  line-height: 24px;
}
.add-member-fields .checkbox-wrap label:hover .checkbox-icon:after {
  border-color: #14182f;
}
.add-member-fields .checkbox-wrap label input:checked + .checkbox-icon:after {
  border-color: #3b53d1;
}

.modal-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  display: inline-block;
  width: 100%;
}
.modal-heading:after {
  position: absolute;
  content: '';
  height: 1px;
  width: 100%;
  left: 0;
  background: #f5f5f9;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}
.modal-heading span {
  color: #414766;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
  background: #fff;
  padding: 0 5px 0 0;
}

.user-role-listing {
  margin-bottom: 40px;
}
.user-role-listing .user-role-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.user-role-listing .user-role-block .left-block {
  width: 33%;
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 24px;
}
.user-role-listing .user-role-block .right-block {
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.user-role-listing .user-role-block .right-block .checkbox-wrap {
  width: 50%;
  text-align: left;
}
.user-role-listing .user-role-block .right-block .checkbox-wrap label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.user-role-listing .user-role-block .right-block .checkbox-wrap label .checkbox-icon {
  width: 21px;
  height: 21px;
}
.user-role-listing .user-role-block .right-block .checkbox-wrap label .checkbox-text {
  margin-left: 10px;
}

.connect-modal .modal-body {
  padding: 16px 24px;
}

.connect-modal .right-ui {
  margin-left: auto;
}
.connect-modal .right-ui .btn1 {
  min-width: 100px;
  text-align: center;
}

.connect-modal .connect-btn {
  border-radius: 12px;
  background-color: #d7f7e7;
  padding: 4px 10px;
  color: #0c6f55;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 18px;
  padding-left: 28px;
  position: relative;
}
.connect-modal .connect-btn:before {
  position: absolute;
  content: '';
  left: 7px;
  top: 5px;
  background: url(assets/images/ic_green_check.svg);
  width: 16px;
  height: 14px;
}

/*customize plan ui */
.customize-plan-ui {
  padding: 0 24px 0;
}
.customize-plan-ui .select-user-ui {
  margin-bottom: 30px;
}
.customize-plan-ui .select-user-ui .cstm-select .bootstrap-select button {
  border: 1px solid #d5d7e4;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 49px;
  width: 79px;
  line-height: 49px;
  padding: 0 10px;
}
.customize-plan-ui .select-user-ui .cstm-select .dropdown-menu ul li {
  width: 100%;
  margin-bottom: 5px;
}
.customize-plan-ui.credit-pack-list ul li {
  width: 48%;
}
@media only screen and (max-width: 575px) {
  .customize-plan-ui.credit-pack-list ul li {
    width: 100%;
    margin-right: 0;
  }
}
.customize-plan-ui.credit-pack-list ul li label {
  width: 100%;
}
.customize-plan-ui.credit-pack-list ul li .credit-pack-box {
  width: 100%;
  min-height: 246px;
}
.customize-plan-ui.credit-pack-list ul li .credit-pack-box .tag-ui {
  height: 26px;
  border-radius: 4px;
  background-color: #e61e8e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  display: inline-block;
  padding: 5px 12px;
  position: absolute;
  top: -14px;
}
.customize-plan-ui.credit-pack-list ul li .credit-pack-box .content-ui .credit-text {
  margin-bottom: 24px;
  color: #3b53d1;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 24px;
}
.customize-plan-ui.credit-pack-list ul li .credit-pack-box .content-ui p {
  color: #242946;
  font-size: 40px;
  letter-spacing: -0.4px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 18px;
}
.customize-plan-ui.credit-pack-list ul li .credit-pack-box .content-ui p span {
  color: #797f99;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.customize-plan-ui.credit-pack-list ul li .credit-pack-box .content-ui .price-tag {
  border-radius: 4px;
  background-color: #f5f5f9;
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.18px;
  line-height: 20px;
  display: inline-block;
  width: auto;
  padding: 6px 8px;
  margin-bottom: 26px;
}
.customize-plan-ui.credit-pack-list ul li .credit-pack-box .bottom-ui {
  padding: 20px 0;
  border-top: 1px solid #f5f5f9;
}
.customize-plan-ui.credit-pack-list ul li .credit-pack-box .bottom-ui p {
  color: #414766;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.customize-plan-ui.credit-pack-list ul li:last-child {
  margin-right: 0;
}

.info-ui {
  position: relative;
  padding-left: 34px;
  color: #797f99;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.32px;
  line-height: 24px;
  margin-bottom: 26px;
}
.info-ui:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 0;
  top: 0px;
  background: url(assets/images/icon-info.svg) no-repeat;
}

/** Component css links **/
.signin-bg {
  background: url('assets/images/signin_bg.png');
  background-size: cover;
  height: 100vh;
}

.signup-header {
  padding: 16px 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  background: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.signup-header .header-right .text-btn {
  padding-left: 8px;
}
.signup-header .header-right p span {
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .signup-header .header-right p span {
    display: none;
  }
}
.signup-header .row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-color {
  color: #414766;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
}

p {
  margin-bottom: 0;
}

.border-radius-12 {
  border-radius: 12px;
}

.signup-wrapper {
  padding-top: 92px;
}

.main-wraper .white-bg {
  -webkit-box-shadow:
    -4px 4px 8px 0 rgba(60, 66, 87, 0.1),
    -12px 12px 24px 0 rgba(33, 54, 89, 0.06),
    -16px 20px 40px 0 rgba(89, 116, 166, 0.04);
  box-shadow:
    -4px 4px 8px 0 rgba(60, 66, 87, 0.1),
    -12px 12px 24px 0 rgba(33, 54, 89, 0.06),
    -16px 20px 40px 0 rgba(89, 116, 166, 0.04);
  background-color: #ffffff;
  padding: 40px;
}
@media only screen and (max-width: 480px) {
  .main-wraper .white-bg {
    padding: 30px 20px;
  }
}

.form-heading-grp {
  margin-bottom: 30px;
}
.form-heading-grp .h4 {
  color: #0d1829;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;
}
.form-heading-grp p {
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 24px;
}

.other-info {
  margin-top: 32px;
}

.footer-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 64px;
}
@media only screen and (max-height: 750px) {
  .footer-list {
    position: relative;
    bottom: 0;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-height: 750px) and (max-height: 599px) {
  .footer-list {
    margin-top: 30px;
  }
}
.footer-list li {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
  text-align: center;
  margin: 0 5px;
}
.footer-list li a {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
  text-align: center;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.footer-list li a:hover {
  color: #414766;
}

/*signup css start */
.half-white-bg {
  position: relative;
  padding-top: 125px;
  min-height: 100vh;
}
@media only screen and (max-width: 480px) {
  .half-white-bg {
    padding-top: 90px;
  }
}
.half-white-bg:after {
  position: absolute;
  height: 100%;
  width: 50%;
  background: #fff;
  content: '';
  right: 0;
  top: 0;
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .half-white-bg:after {
    display: none;
  }
}
.half-white-bg:before {
  position: absolute;
  height: 100%;
  width: 50%;
  background: url('assets/images/mainbg.jpg');
  background-size: 100%;
  content: '';
  left: 0;
  top: 0;
}
@media only screen and (max-width: 991px) {
  .half-white-bg:before {
    display: none;
  }
}

.side-footer .footer-list {
  position: relative;
  left: 0;
  text-align: left;
  bottom: initial;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.desc-block {
  max-width: 400px;
}
.desc-block .title {
  color: #0d1829;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.24px;
  line-height: 33px;
  margin-bottom: 16px;
}
.desc-block p {
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 24px;
  margin-bottom: 40px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-txt {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 250ms;
  animation-duration: 250ms;
}

.fade-in-txt.one {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fade-in-txt.two {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fade-in-txt.three {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.steps-block {
  margin-bottom: 56px;
}
.steps-block .d-flex {
  position: relative;
  margin-bottom: 20px;
  padding-left: 35px;
}
.steps-block .d-flex:after {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  top: 0;
  left: 0;
  background: url('assets/images/list-icon.svg') no-repeat;
  background-size: 24px;
}
.steps-block .d-flex p {
  color: #0d1829;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 24px;
}

.signup-right .form-inner-content {
  max-width: 380px;
  margin: auto;
}
@media only screen and (max-width: 991px) {
  .signup-right .form-inner-content {
    -webkit-box-shadow:
      -4px 4px 8px 0 rgba(60, 66, 87, 0.1),
      -12px 12px 24px 0 rgba(33, 54, 89, 0.06),
      -16px 20px 40px 0 rgba(89, 116, 166, 0.04);
    box-shadow:
      -4px 4px 8px 0 rgba(60, 66, 87, 0.1),
      -12px 12px 24px 0 rgba(33, 54, 89, 0.06),
      -16px 20px 40px 0 rgba(89, 116, 166, 0.04);
    background-color: #ffffff;
    padding: 40px;
    max-width: 450px;
    border-radius: 12px;
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 991px) and (max-width: 480px) {
  .signup-right .form-inner-content {
    padding: 30px 24px;
  }
}

/* checkbox listing */
.checkbox-listing {
  margin-top: 4px;
  margin-bottom: 32px;
}
.checkbox-listing .checkbox-wrap {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 18px;
}
@media only screen and (max-width: 599px) {
  .checkbox-listing .checkbox-wrap {
    height: auto;
  }
}
.checkbox-listing .checkbox-wrap label {
  padding-left: 40px;
  margin-bottom: 0;
}
@media only screen and (max-width: 599px) {
  .checkbox-listing .checkbox-wrap label {
    line-height: normal;
  }
}
.checkbox-listing .checkbox-wrap .checkbox-icon {
  position: absolute;
  left: 0;
  height: 24px;
}
.checkbox-listing .checkbox-wrap .checkbox-icon:after {
  width: 25px;
  height: 25px;
}
.checkbox-listing .checkbox-wrap .checkbox-icon:before {
  left: 5px;
  top: 5px;
}
.checkbox-listing .checkbox-wrap .checkbox-text {
  color: #414766;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: -0.16px;
  line-height: 20px;
}
@media only screen and (max-width: 599px) {
  .checkbox-listing .checkbox-wrap .checkbox-text {
    line-height: normal;
  }
}
.checkbox-listing .checkbox-wrap .checkbox-text a {
  color: #5369df;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  position: relative;
}
.checkbox-listing .checkbox-wrap .checkbox-text a:after {
  width: 100%;
  height: 1px;
  background: #5369df;
  position: absolute;
  content: '';
  bottom: -2px;
  left: 0;
}

.verticalSlider {
  width: 346px;
  margin-bottom: 130px;
  height: 335px;
}
.verticalSlider .inner-item figure {
  margin-bottom: 6px;
}
.verticalSlider .inner-item figure img {
  width: 14px;
  margin-left: 2px;
}
.verticalSlider .inner-item .title {
  color: #0d1021;
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.verticalSlider .inner-item .desc {
  color: #414766;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 28px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.verticalSlider .profile-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.verticalSlider .profile-ui .img-ui {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 8px;
}
.verticalSlider .profile-ui .img-ui img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.verticalSlider .profile-ui .content-ui p {
  color: #0d1829;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: 24px;
  margin-bottom: 0;
}
.verticalSlider .profile-ui .content-ui span {
  color: #595f79;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.14px;
  line-height: 16px;
  vertical-align: top;
  display: block;
}

@media only screen and (max-width: 991px) {
  .signup-left {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .back-ui {
    max-width: 30px;
    -webkit-box-flex: 30px;
    -ms-flex: 30px;
    flex: 30px;
    display: none;
  }
  .back-ui .back-home-btn {
    font-size: 0;
  }
  .back-ui .back-home-btn:before {
    background-color: #e6e9fa;
    border-radius: 4px;
    text-align: center;
    width: 30px;
    height: 30px;
    top: 0;
    background-position: center;
  }
  .logo {
    text-align: left !important;
  }
  .signup-header .col-4 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 599px) {
  .mt-5 {
    margin-top: 1rem !important;
  }
}

.content-wrapper-ui {
  padding-top: 20px;
}
@media only screen and (max-width: 767px) {
  .content-wrapper-ui {
    padding-top: 20px;
  }
}

.cstm-dropdown {
  display: inline-block;
  min-width: 132px;
}
@media only screen and (max-width: 767px) {
  .cstm-dropdown {
    max-width: 100%;
    width: 50%;
    margin-bottom: 15px;
  }
}
.cstm-dropdown .show .btn-primary.dropdown-toggle {
  outline: none !important;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1) !important;
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1) !important;
  background: #fff !important;
  color: #242946 !important;
}
.cstm-dropdown button:focus,
.cstm-dropdown button:active {
  outline: none !important;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1) !important;
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1) !important;
  background: #fff !important;
  color: #242946 !important;
}
.cstm-dropdown button:before {
  background: url(assets/images/ic_filter.png) no-repeat;
  background-size: 24px;
  position: absolute;
  content: '';
  height: 24px;
  width: 20px;
  left: 8px;
  top: 8px;
  z-index: 1;
}
.cstm-dropdown .dropdown-menu {
  width: 260px;
  padding: 20px;
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  margin-top: 12px;
}
.cstm-dropdown.lead_filter {
  margin-right: 14px;
}
.cstm-dropdown.lead_filter .dropdown-menu .cnl_btn {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 17px;
  border-bottom: 1px solid rgba(213, 215, 228, 0.48);
}
.cstm-dropdown.lead_filter .dropdown-menu .cnl_btn .plus-icon {
  height: 22px;
  width: 22px;
  border: 2px solid #d5d7e4;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  font-weight: normal;
  font-size: 25px;
  line-height: 18px;
  margin-right: 7px;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list {
  margin: 0;
  padding: 10px 0 0 0;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list li {
  position: relative;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list li:last-child {
  margin-bottom: 0;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list li label {
  margin-bottom: 0;
  padding: 5px 10px 5px 30px;
  width: 100%;
  position: relative;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list li .hover-btn-ui {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list li .hover-btn-ui .ic-btn {
  padding: 0px 5px;
  opacity: 0.75;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  width: 32px;
  text-align: center;
  border-radius: 100%;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list li .hover-btn-ui .ic-btn:hover {
  opacity: 1;
  background: #f4f3f9;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list li:hover .hover-btn-ui {
  opacity: 1;
  visibility: visible;
}
.cstm-dropdown.lead_filter .dropdown-menu .dropdown-list li:hover .label_text {
  color: #242946;
}
.cstm-dropdown.user_filter button:before {
  background: url(assets/images/ic_assign.png) no-repeat;
  width: 26px;
  background-size: 24px;
  position: absolute;
  content: '';
  height: 24px;
  left: 8px;
  top: 8px;
  z-index: 1;
}
.cstm-dropdown.user_filter .dropdown-menu {
  width: 240px;
  padding: 15px 0 0px;
}
.cstm-dropdown.user_filter .dropdown-menu ul li {
  margin-bottom: 0px;
}
.cstm-dropdown.user_filter .dropdown-menu ul li a {
  color: #242946;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #ffffff;
  padding: 8px 20px;
  display: block;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.cstm-dropdown.user_filter .dropdown-menu ul li a:hover {
  background: #f5f5f9;
}
.cstm-dropdown.user_filter .dropdown-menu ul li .name_tag {
  height: 38px;
  width: 38px;
  display: inline-block;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 38px;
  text-align: center;
  margin-right: 10px;
}
.cstm-dropdown.user_filter .dropdown-menu ul li .name_tag.light_red {
  background: #ffdede;
  color: #b54848;
}
.cstm-dropdown.user_filter .dropdown-menu ul li .name_tag.light_yellow {
  background: #ffe9ae;
  color: #977e3b;
}
.cstm-dropdown.user_filter .dropdown-menu ul li .name_tag.light_green {
  background: #d7f7e7;
  color: #458364;
}
.cstm-dropdown.user_filter .dropdown-menu ul li .name_tag.light_blue {
  background: #e7ebfe;
  color: #6a77bb;
}
.cstm-dropdown .dropdown-toggle {
  display: inline-block;
  width: 160px;
  height: 40px;
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  position: relative;
  border: none;
  color: #414766;
  text-align: left;
  padding-left: 35px;
  max-width: 160px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  padding-right: 20px;
  letter-spacing: 0.12px;
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .cstm-dropdown .dropdown-toggle {
    max-width: 100%;
    width: 100%;
    border-radius: 8px;
  }
}
.cstm-dropdown .dropdown-toggle.dropdown-toggle::after {
  background: url('assets/images/ic_arrow_down.svg') no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0;
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cstm-dropdown .dropdown-toggle:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
  box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
}

@media only screen and (max-width: 767px) {
  .page-header .row > .col-md-6 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.page-header .cstm-select {
  display: inline-block;
  width: 132px;
  height: 40px;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  position: relative;
}
.page-header .cstm-select .bootstrap-select {
  width: 100% !important;
}
.page-header .cstm-select .bootstrap-select button {
  width: 100%;
  height: 40px;
  padding-left: 37px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.18px;
  color: #242946;
}
.page-header .cstm-select .bootstrap-select button:focus,
.page-header .cstm-select .bootstrap-select button:hover {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff !important;
}
.page-header .cstm-select .bootstrap-select button.dropdown-toggle::after {
  background: url('assets/images/ic_arrow_down.svg') no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0;
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.page-header .cstm-select .bootstrap-select .dropdown-menu li a {
  padding-left: 15px;
  padding-right: 15px;
}
.page-header .cstm-select:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 20px;
  left: 8px;
  top: 8px;
  z-index: 1;
  background-size: 100%;
}
.page-header .cstm-select.lead_filter {
  margin-right: 14px;
}
.page-header .cstm-select.lead_filter:before {
  background: url('assets/images/ic_filter.png') no-repeat;
  background-size: 24px;
}
.page-header .cstm-select.user_filter:before {
  background: url('assets/images/ic_assign.png') no-repeat;
  width: 26px;
  background-size: 24px;
}
.page-header .cstm-select.user_filter .bootstrap-select button {
  padding-left: 37px;
  min-width: 140px;
}
.page-header .cstm-select .filter-option-inner-inner {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 5px;
}
.page-header .cstm-select .dropdown-item.active,
.page-header .cstm-select .dropdown-item:active {
  background: #3b53d1;
  color: #fff;
}

.page-header .search-ui {
  display: inline-block;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .page-header .search-ui {
    width: 100%;
  }
}
.page-header .search-ui:after {
  position: absolute;
  content: '';
  background: url('assets/images/ic_search.png') no-repeat;
  width: 24px;
  height: 24px;
  left: 10px;
  top: 8px;
  background-size: 24px;
}
.page-header .search-ui input {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 40px;
  width: 380px;
  padding: 0 20px 0 42px;
  font-size: 16px;
  letter-spacing: -0.18px;
  line-height: 20px;
  color: #797f99;
  border: none;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
@media only screen and (max-width: 1250px) {
  .page-header .search-ui input {
    width: 280px;
  }
}
@media only screen and (max-width: 1250px) and (max-width: 1199px) {
  .page-header .search-ui input {
    width: 230px;
  }
}
@media only screen and (max-width: 1250px) and (max-width: 767px) {
  .page-header .search-ui input {
    width: 100%;
    border-radius: 8px;
  }
}
.page-header .search-ui input:focus {
  outline: none;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.24),
    0 2px 4px 0 rgba(20, 24, 47, 0.18);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.24),
    0 2px 4px 0 rgba(20, 24, 47, 0.18);
}
/*.page-header .search-ui input:focus:after {
  background:url("assets/images/ic_search_b.png") no-repeat;
}*/
.page-header .search-ui input:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
  box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
}

.page-header .datepicker-ui {
  display: inline-block;
  margin-left: 16px;
}
@media only screen and (max-width: 767px) {
  .page-header .datepicker-ui {
    border-radius: 8px;
  }
}

.table-wrap {
  margin-top: 16px;
  border: 1px solid #f5f5f9;
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12);
}
.table-wrap .head_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  padding: 20px 16px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
  top: 0px;
  background: #fff;
  z-index: 9;
  border-radius: 8px;
}
@media only screen and (max-width: 991px) {
  .table-wrap .head_block {
    padding: 20px 10px;
  }
}
@media only screen and (max-width: 991px) and (max-width: 767px) {
  .table-wrap .head_block {
    position: relative;
    top: inherit;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 10px 10px 20px;
  }
}
.table-wrap .head_block .left_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  margin-right: auto;
}
@media only screen and (max-width: 767px) {
  .table-wrap .head_block .left_block {
    width: 100%;
    margin-bottom: 10px;
  }
  .table-wrap .head_block .left_block .cstm-select.date-sorting .dropdown-menu {
    right: 0;
    left: inherit !important;
    -webkit-transform: translate3d(0px, 0px, 0px) !important;
    transform: translate3d(0px, 0px, 0px) !important;
    top: 28px !important;
  }
}
.table-wrap .head_block .left_block .title-box {
  margin-right: 32px;
}
@media only screen and (max-width: 991px) {
  .table-wrap .head_block .left_block .title-box {
    margin-right: 15px;
  }
}
.table-wrap .head_block .left_block .title-box p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: 32px;
  color: #0d1829;
}
@media only screen and (max-width: 991px) {
  .table-wrap .head_block .left_block .title-box p {
    font-size: 16px;
  }
}
.table-wrap .head_block .sorting-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
}
@media only screen and (max-width: 767px) {
  .table-wrap .head_block .sorting-block {
    margin-left: auto;
  }
}
.table-wrap .head_block .sorting-block label {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 18px;
  color: #595f79;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 991px) {
  .table-wrap .head_block .sorting-block label {
    font-size: 14px;
  }
}
.table-wrap .head_block .sorting-block.table-listing {
  margin-left: 32px;
}
@media only screen and (max-width: 991px) {
  .table-wrap .head_block .sorting-block.table-listing {
    margin-left: 20px;
  }
}
.table-wrap .head_block .right_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
}
@media only screen and (max-width: 767px) {
  .table-wrap .head_block .right_block {
    width: 100%;
  }
}
.table-wrap .head_block .right_block .sorting-block.table-listing {
  margin-left: 0;
  margin-right: 32px;
}
@media only screen and (max-width: 767px) {
  .table-wrap .head_block .right_block .sorting-block.table-listing {
    margin-right: auto;
  }
}
.table-wrap .head_block .right_block .more-btn {
  margin-left: 16px;
  display: inline-block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  vertical-align: middle;
  text-align: center;
  transsition: 0.2s ease-in-out;
}
@media only screen and (max-width: 991px) {
  .table-wrap .head_block .right_block .more-btn {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 991px) and (max-width: 767px) {
  .table-wrap .head_block .right_block .more-btn {
    margin-left: 10px;
  }
}
.table-wrap .head_block .right_block .more-btn span {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #797f99;
  border-radius: 10px;
  margin: 1.5px;
}
.table-wrap .head_block .right_block .more-btn:hover {
  -webkit-box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
  box-shadow: 0 0 0 4px rgba(78, 99, 216, 0.1);
}

.table-content table thead {
  border-bottom: 2px solid #f5f5f9;
}
.table-content table thead th {
  padding: 6px 8px 6px;
  background: #f5f5f9;
  top: 150px;
}
.table-content table thead th:first-child {
  padding-left: 16px;
  width: 50px;
}
.table-content table thead th .checkbox-wrap {
  vertical-align: top;
  height: 19px;
}
.table-content table thead th .checkbox-wrap label {
  width: 19px;
  margin-bottom: 0;
}
.table-content table thead th .checkbox-wrap label .checkbox-icon {
  width: 19px;
  height: 19px;
  display: inline-block;
}
.table-content table thead th .checkbox-wrap label .checkbox-icon:after {
  height: 19px;
  width: 19px;
}
.table-content table thead th .checkbox-wrap label .checkbox-icon:before {
  left: 2px;
  top: 3px;
  background-size: 14px;
}

.table-content table tbody td .checkbox-wrap label {
  display: block;
  margin: 0;
  position: relative;
  height: 21px;
  width: 21px;
}

.table-content table tbody td .user-info-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.table-content table tbody td .profile-ui {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 100%;
  margin: 0;
  margin-right: 10px;
}
.table-content table tbody td .profile-ui img {
  width: 100%;
}

.table-content table tbody tr {
  border-bottom: 1px solid #f5f5f9;
  position: relative;
  cursor: pointer;
  border-radius: 0px;
  -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
  transition: -webkit-box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition:
    box-shadow 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
}
.table-content table tbody tr:last-child {
  border: none;
}
.table-content table tbody tr:hover {
  -webkit-box-shadow: 0 2px 6px 0 rgba(13, 16, 33, 0.2);
  box-shadow: 0 2px 6px 0 rgba(13, 16, 33, 0.2);
  border-bottom-color: transparent;
}
.table-content table tbody tr:hover td {
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.table-content table tbody tr:hover td .checkbox-wrap label .checkbox-icon:after {
  border-color: #a9afc6;
}
.table-content table tbody tr:hover td .checkbox-wrap label input:checked + .checkbox-icon:after {
  border-color: #3b53d1;
}
.table-content table tbody tr:hover td {
  position: relative;
}
.table-content table tbody tr:hover td .username p {
  color: #3b53d1;
}

.table-content table td {
  padding: 16px 5px;
  /*z-index:2;*/
  position: relative;
}
.table-content table td:first-child {
  padding-left: 16px;
}

.table-content table th {
  color: #797f99;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.48px;
  line-height: 16px;
  text-transform: uppercase;
}

.table-content table td {
  color: #414766;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
}
.table-content table td p {
  color: #242946;
  font-weight: 500;
  letter-spacing: 0.16px;
  margin-bottom: 0px;
}
.table-content table td span {
  margin-top: 0px;
  color: #797f99;
  font-size: 12px;
  letter-spacing: -0.32px;
  line-height: 14px;
}
.table-content table td span.attach-counter {
  width: 36px;
  background: #f5f5f9;
  border-radius: 6px;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  text-align: center;
  margin-top: 0;
  margin-right: 6px;
  color: #3b53d1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
}
.table-content table td span.attach-counter i {
  font-style: initial;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  top: -2px;
}
.table-content table td .contact-more {
  height: 24px;
  width: 24px;
  background-color: #edf0ff;
  color: #5369df;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  line-height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.datepicker-ui {
  height: 40px;
  width: 58px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.84);
  -webkit-box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow: 0 1px 4px 0 rgba(20, 24, 47, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: center;
  align-self: center;
  vertical-align: middle;
  line-height: 38px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .datepicker-ui {
    width: 75px;
  }
}
.datepicker-ui .datepicker-icon {
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}
.datepicker-ui .datepicker-icon span {
  width: 100%;
  display: inline-block;
  padding: 0 12px;
  position: relative;
}
.datepicker-ui .datepicker-icon span:after {
  background: url(assets/images/ic_arrow_down.svg) no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  content: '';
  margin: 0;
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.datepicker-ui .datepicker-icon img {
  width: 100%;
  max-width: 25px;
}

.datepicker.dropdown-menu {
  width: 14rem;
  padding: 10px;
  margin-top: 10px;
  -webkit-animation: translatetop 250ms;
  animation: translatetop 250ms;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
}
.datepicker.dropdown-menu table {
  width: 100%;
}
.datepicker.dropdown-menu table tr th,
.datepicker.dropdown-menu table tr td {
  color: #000;
  width: 1.7rem;
  line-height: 1.6rem;
  text-align: center;
  margin: 0.166rem;
  font-weight: normal;
  font-size: 0.8rem;
  border-radius: 4px;
  cursor: pointer;
}
.datepicker.dropdown-menu table tr th {
  font-weight: 500;
}
.datepicker.dropdown-menu table tr th.datepicker-switch {
  font-weight: 500;
  font-size: 0.9rem;
}
.datepicker.dropdown-menu table tr td.active,
.datepicker.dropdown-menu table tr td.today {
  background: #3b53d1;
  color: #fff;
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
}

.loading-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loader-content {
  margin: 20px 0 0 0;
  font-size: 14px;
  color: #9e9e9e;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
}

.loader-inner-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -45px;
  margin-top: -45px;
  width: 90px;
  height: 90px;
  text-align: center;
}

.loader-icon {
  width: 70px;
  position: relative;
  top: 23px;
  -webkit-animation: scaling 0.9s infinite linear;
  -moz-animation: scaling 0.9s infinite linear;
  -o-animation: scaling 0.9s infinite linear;
  animation: scaling 0.9s infinite linear;
}

@-webkit-keyframes scaling {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  65% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaling {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  65% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.customize-table-view-ui .bx-btn {
  margin-right: 16px;
}
@media only screen and (max-width: 767px) {
  .customize-table-view-ui .bx-btn {
    margin-right: 10px;
    display: none;
  }
}
.customize-table-view-ui .bx-btn:after {
  background: url(assets/images/ic_arrow_down.svg) no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0 0 0 3px;
  vertical-align: middle;
}

.customize-table-view-ui .dropdown-menu {
  width: 275px;
  left: inherit !important;
  right: 0 !important;
  border-radius: 12px;
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
}
.customize-table-view-ui .dropdown-menu .checkbox-wrap {
  width: 100%;
  padding-left: 30px;
  position: relative;
}
.customize-table-view-ui .dropdown-menu .checkbox-wrap .checkbox-icon {
  width: 21px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 2px;
}
.customize-table-view-ui .dropdown-menu .dropdown-header {
  color: #0d1829;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  padding: 11px 20px;
  border-bottom: 1px solid rgba(213, 215, 228, 0.48);
}
.customize-table-view-ui .dropdown-menu .dropdown-content {
  padding: 12px;
}
.customize-table-view-ui .dropdown-menu .dropdown-content li {
  padding: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid transparent;
  cursor: -webkit-grab;
  cursor: grab;
  margin-bottom: 0;
}
.customize-table-view-ui .dropdown-menu .dropdown-content li label {
  padding-left: 0;
}
.customize-table-view-ui .dropdown-menu .dropdown-content li .sort-icon {
  display: inline-block;
  width: 20px;
  line-height: 20px;
  height: 20px;
}
.customize-table-view-ui .dropdown-menu .dropdown-content li:hover {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(13, 16, 33, 0.08);
  box-shadow: 0 2px 4px 0 rgba(13, 16, 33, 0.08);
}
.customize-table-view-ui .dropdown-menu .dropdown-content li .checkbox-text {
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #ffffff;
}
.customize-table-view-ui
  .dropdown-menu
  .dropdown-content
  li
  input:checked
  + .checkbox-icon
  + .checkbox-text {
  color: #242946;
}
.customize-table-view-ui .dropdown-menu .dropdown-content li .checkbox-wrap label {
  margin-bottom: 0;
}
.customize-table-view-ui .dropdown-menu .button-blocks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px 8px;
}
.customize-table-view-ui .dropdown-menu .button-blocks .btn {
  height: 36px;
  line-height: 34px;
  font-size: 14px;
}
.customize-table-view-ui .dropdown-menu .button-blocks .btn:first-child {
  width: 40%;
  margin-right: 10px;
}
.customize-table-view-ui .dropdown-menu .button-blocks .btn.btn1 {
  width: 60%;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
  box-shadow:
    0 1px 2px 0 rgba(59, 83, 209, 0.14),
    0 2px 4px 0 rgba(59, 83, 209, 0.16);
}

.workflow-page-wrapper td .username {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.create-workflow .modal-dialog {
  max-width: 768px;
}

.create-workflow .modal-body {
  padding: 23px;
}

.create-workflow .modal-footer {
  padding: 14px 20px;
}

.create-workflow-wrap .sub-title2 {
  color: #797f99;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 16px;
  text-align: right;
}

.create-workflow-wrap .template-listing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.create-workflow-wrap .template-listing .template-grid {
  width: 100%;
  padding: 2px;
  margin-right: 22px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.create-workflow-wrap .template-listing .template-grid:last-child {
  margin-right: 0;
}
.create-workflow-wrap .template-listing .template-grid .grid-inner-ui {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  outline-radius: 8px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.create-workflow-wrap .template-listing .template-grid .grid-inner-ui .icon-ui {
  opacity: 0;
  visibility: hidden;
  height: 28px;
  width: 28px;
  background: -webkit-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: -o-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: linear-gradient(140.68deg, #5369df 0%, #4458c9 100%);
  display: inline-block;
  position: absolute;
  border-radius: 100%;
  right: 15px;
  top: 15px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.create-workflow-wrap .template-listing .template-grid .grid-inner-ui .icon-ui:after {
  height: 34px;
  width: 34px;
  border: 3px solid rgba(83, 105, 223, 0.2);
  -webkit-box-shadow: 0 1px 2px 0 rgba(83, 105, 223, 0.15);
  box-shadow: 0 1px 2px 0 rgba(83, 105, 223, 0.15);
  position: absolute;
  content: '';
  right: -3px;
  border-radius: 100%;
  top: -3px;
}
.create-workflow-wrap .template-listing .template-grid .grid-inner-ui .icon-ui:before {
  height: 34px;
  width: 34px;
  position: absolute;
  content: '';
  background: url('assets/images/ic_check_shadow.svg') no-repeat;
  right: 0;
  border-radius: 100%;
  top: 5px;
  text-align: center;
  left: 5px;
  background-size: 18px;
}
.create-workflow-wrap .template-listing .template-grid input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  z-index: 9;
  cursor: pointer;
}
.create-workflow-wrap .template-listing .template-grid input:checked + .grid-inner-ui {
  border: 1px solid #5369df;
  -webkit-box-shadow: 0px 0px 1px 1px #5369df;
  box-shadow: 0px 0px 1px 1px #5369df;
}
.create-workflow-wrap .template-listing .template-grid input:checked + .grid-inner-ui .icon-ui {
  opacity: 1;
  visibility: visible;
}
.create-workflow-wrap
  .template-listing
  .template-grid
  input:checked
  + .grid-inner-ui
  .content-block
  p {
  color: #3b53d1;
}
.create-workflow-wrap .template-listing .template-grid .img-block {
  min-height: 230px;
  background: #f5f5f9;
  border: 3px solid #fff;
  text-align: center;
  padding-top: 34px;
}
.create-workflow-wrap .template-listing .template-grid .content-block {
  padding: 12px;
}
.create-workflow-wrap .template-listing .template-grid .content-block p {
  margin-bottom: 0;
  color: #595f79;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 20px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.create-workflow-wrap .info-content {
  margin-top: 20px;
}
.create-workflow-wrap .info-content p {
  color: #797f99;
  font-size: 16px;
  font-style: italic;
  letter-spacing: 0.32px;
  line-height: 24px;
  padding-left: 32px;
  position: relative;
}
.create-workflow-wrap .info-content p:before {
  position: absolute;
  content: '';
  background: url('assets/images/icon-info.svg') no-repeat;
  height: 22px;
  width: 22px;
  left: 0;
  top: 2px;
}

.actions-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.actions-btn .merge-icons {
  padding: 0 25px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.actions-btn .merge-icons img {
  opacity: 0.8;
}
.actions-btn .merge-icons:hover img {
  opacity: 1;
}
.actions-btn .delete-btn a {
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  opacity: 0.8;
  display: inline-block;
}
.actions-btn .delete-btn a:hover {
  opacity: 1;
}

.actions-btn a {
  height: 36px;
  width: 36px;
  display: inline-block;
  vertical-align: middle;
  line-height: 36px;
  border-radius: 100%;
  text-align: center;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.actions-btn a:hover {
  background: #f2f2f2;
}

.wd-wrapper .title {
  color: #0d1829;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 32px;
  font-weight: normal;
  margin-bottom: 16px;
}

.wd-wrapper .tab-content {
  display: block;
  width: 100%;
}
.wd-wrapper .tab-content .perfomance-detail {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  min-height: 674px;
}
.wd-wrapper .tab-content .perfomance-detail .top-header {
  padding: 32px 40px;
}
@media only screen and (max-width: 991px) {
  .wd-wrapper .tab-content .perfomance-detail .top-header {
    padding: 16px;
  }
}
.wd-wrapper .tab-content .perfomance-detail .table-content {
  padding-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .wd-wrapper .tab-content .perfomance-detail .table-content table {
    min-width: auto;
  }
}

.workflow-page-wrapper .table-content table tbody tr:hover {
  cursor: initial;
}

.table-content.table-width-equal table {
  table-layout: fixed;
}
.table-content.table-width-equal table thead {
  padding: 0 30px;
}
.table-content.table-width-equal table th:first-child,
.table-content.table-width-equal table td:first-child {
  width: auto;
  text-align: left;
  padding-left: 49px;
}
.table-content.table-width-equal table th:nth-child(2),
.table-content.table-width-equal table td:nth-child(2) {
  width: auto;
  text-align: left;
}
.table-content.table-width-equal table td:first-child {
  color: #242946;
  font-weight: 500;
}
.table-content.table-width-equal table th,
.table-content.table-width-equal table td {
  text-align: center;
}
.table-content.table-width-equal table td .ic_link {
  position: relative;
}
.table-content.table-width-equal table td .ic_link:after {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  background: url('assets/images/link_icon.svg') no-repeat;
  right: -19px;
  top: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.table-content.table-width-equal table td .ic_link:hover:after {
  opacity: 1;
  visibility: visible;
}

.workflow-detail-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}
.workflow-detail-header .cstm-tabs-ui {
  margin-right: auto;
}
.workflow-detail-header .cstm-tabs-ui .nav-tabs {
  border: none;
}
.workflow-detail-header .cstm-tabs-ui .nav-tabs li a {
  padding: 6px 19px;
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 18px;
  text-align: center;
  text-shadow: -1px 1px 0 0 #ffffff;
  border-radius: 8px;
}
.workflow-detail-header .cstm-tabs-ui .nav-tabs li a.active {
  /*background-color: rgba(255,255,255,0.82);
							box-shadow: 0 0 2px 0 rgba(20,24,47,0.1), 0 2px 4px 0 rgba(13,16,33,0.08);*/
  color: #3b53d1;
  font-weight: 500;
}
.workflow-detail-header .cstm-tabs-ui .nav-tabs li a.active:hover {
  color: #3b53d1;
}
.workflow-detail-header .cstm-tabs-ui .nav-tabs li a:hover {
  color: #3e4358;
}
.workflow-detail-header .btn-blocks .switch-btn {
  vertical-align: middle;
  height: 24px;
  margin-left: 20px;
}
.workflow-detail-header .btn-blocks .switch-btn input {
  height: 24px;
  border-radius: 30px;
  cursor: pointer;
}
.workflow-detail-header .btn-blocks .switch-btn input:after {
  height: 18px;
  width: 18px;
  -webkit-transform: translate(3px, 0);
  -ms-transform: translate(3px, 0);
  transform: translate(3px, 0);
  top: 3px;
}
.workflow-detail-header .btn-blocks .switch-btn input:checked:after {
  -webkit-transform: translate(17px, 0);
  -ms-transform: translate(17px, 0);
  transform: translate(17px, 0);
}
.workflow-detail-header .btn-blocks .delete-icon-btn {
  margin-left: 20px;
}

.mini-stats-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mini-stats {
  margin-right: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .mini-stats {
    margin-right: 20px;
  }
}
.mini-stats .mini-stats-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.mini-stats .mini-stats-icon img {
  width: 40px;
}
.mini-stats .mini-stats-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mini-stats .mini-stats-content p {
  color: #242946;
  font-size: 48px;
  letter-spacing: 0;
  line-height: 52px;
  font-weight: 300;
  margin-bottom: 0;
}
.mini-stats .mini-stats-content span {
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 20px;
  font-weight: normal;
  width: 80px;
  margin-left: 12px;
}

/* Profile tab css start */
.profile_detail_block .table-content.table-width-equal tr:hover {
  cursor: initial;
}

.profile_detail_block .table-content.table-width-equal th,
.profile_detail_block .table-content.table-width-equal td {
  text-align: left;
}
.profile_detail_block .table-content.table-width-equal th:first-child,
.profile_detail_block .table-content.table-width-equal td:first-child {
  padding-left: 24px;
}

.profile_detail_block .table-content.table-width-equal td {
  text-align: left;
}
.profile_detail_block .table-content.table-width-equal td a {
  color: #242946;
  font-size: 14px;
  letter-spacing: 0.12px;
  line-height: 16px;
  font-weight: 500;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.profile_detail_block .table-content.table-width-equal td a:hover {
  color: #3b53d1;
}

/* profile tab css close */
.create-workflow-menubar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.create-workflow-menubar .workflow-name-field {
  margin-right: auto;
}
.create-workflow-menubar .workflow-name-field input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #a9afc6;
  max-width: 397px;
  min-width: 397px;
  height: 39px;
  color: #414766;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  -webkit-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}
.create-workflow-menubar .workflow-name-field input::-webkit-input-placeholder {
  /* Edge */
  color: #a9afc6;
}
.create-workflow-menubar .workflow-name-field input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a9afc6;
}
.create-workflow-menubar .workflow-name-field input::-ms-input-placeholder {
  color: #a9afc6;
}
.create-workflow-menubar .workflow-name-field input::placeholder {
  color: #a9afc6;
}
.create-workflow-menubar .workflow-name-field input:focus,
.create-workflow-menubar .workflow-name-field input:hover {
  outline: none;
  border-color: #7e849a;
}

.save-publish-ui .btn1 {
  min-width: 158px;
  text-align: center;
}

.workflow-zoom-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 9;
}
.workflow-zoom-block .btn {
  height: 28px;
  padding: 0;
  display: inline-block;
  width: 28px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  border-radius: 100%;
  line-height: 24px;
  text-align: center;
  color: #5369df;
  font-size: 22px;
}
.workflow-zoom-block .btn.zoom-out {
  position: relative;
}
.workflow-zoom-block .zoom-content {
  padding: 0 8px;
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
}

.expend-btn {
  height: 32px;
  width: 32px;
  border-radius: 6px;
  background-color: #ffffff;
  display: inline-block;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
  z-index: 9;
  text-align: center;
  line-height: 30px;
}

.create-workflow-outer {
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.expend .create-workflow-tree-block {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 999;
  -webkit-transition: all 0.56s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.56s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.56s cubic-bezier(0.77, 0, 0.175, 1);
}

.create-workflow-tree-block {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  padding: 16px;
  margin-bottom: 30px;
  width: 100%;
  -webkit-transition: all 0.56s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.56s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.56s cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.create-workflow-tree-block .create-inner-tree-block {
  border-radius: 8px;
  background-color: #f5f5f9;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(13, 16, 33, 0.12);
  box-shadow: inset 0 1px 4px 0 rgba(13, 16, 33, 0.12);
  height: 100%;
  position: relative;
  overflow-y: auto;
  width: 100%;
}

.create-workflow-outer {
  height: 722px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: static;
}
.create-workflow-outer.active {
  position: fixed;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 99;
}

.workflow-tree-structure {
  text-align: center;
  width: auto;
  position: relative;
  max-width: 1000px;
  margin: auto;
  height: 100%;
}
@media only screen and (max-width: 991px) {
  .workflow-tree-structure {
    min-width: 650px;
    padding: 0 40px;
  }
}

.workflow-container {
  width: auto;
  position: relative;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.workflow-container .workflow-line {
  position: relative;
  z-index: 0;
  width: 1px;
  height: 28px;
  display: inline-block;
  background: #a9afc6;
  margin-bottom: 7px;
}
.workflow-container .workflow-line:after,
.workflow-container .workflow-line:before {
  position: absolute;
  content: '';
  display: inline-block;
  width: 7px;
  height: 1px;
  background: #a9afc6;
  position: absolute;
  bottom: 0px;
}
.workflow-container .workflow-line:after {
  left: -1px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.workflow-container .workflow-line:before {
  right: -1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.workflow-container .workflow-line.hide-arrow:before,
.workflow-container .workflow-line.hide-arrow:after {
  display: none;
}
.workflow-container .workflow-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.08),
    0 3px 7px 0 rgba(20, 24, 47, 0.12),
    0 8px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.08),
    0 3px 7px 0 rgba(20, 24, 47, 0.12),
    0 8px 12px 0 rgba(36, 41, 70, 0.16);
  padding: 8px 10px;
  min-width: 195px;
  position: relative;
  cursor: pointer;
  -moz-outline-radius: 8px;
  --webkit-outline-radius: 8px;
}
.workflow-container .workflow-content:before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  border: 2px solid #5369df;
  border-radius: 8px;
  opacity: 0;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.workflow-container .workflow-content:hover .tooltip-box {
  opacity: 1;
  visibility: visible;
}
.workflow-container .workflow-content:hover:before,
.workflow-container .workflow-content:focus:before,
.workflow-container .workflow-content.active:before {
  opacity: 1;
}
.workflow-container .workflow-content .icon-block {
  height: 26px;
  width: 26px;
  border-radius: 4px;
  background: -webkit-linear-gradient(309.1deg, #797f99 0%, #595f79 100%);
  background: -o-linear-gradient(309.1deg, #797f99 0%, #595f79 100%);
  background: linear-gradient(140.9deg, #797f99 0%, #595f79 100%);
  display: inline-block;
  margin-right: 6px;
  line-height: 24px;
}
.workflow-container .workflow-content .icon-block img {
  width: 17px;
}
.workflow-container .workflow-content .content-block {
  text-align: left;
}
.workflow-container .workflow-content .content-block p {
  color: #0d1021;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  display: block;
  font-weight: 500;
  margin-bottom: 4px;
}
.workflow-container .workflow-content .content-block span {
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: 14px;
  display: block;
}
.workflow-container .workflow-content .content-block a {
  color: #3b53d1;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 14px;
  text-decoration: none;
  display: block;
}
.workflow-container .workflow-content .tooltip-box {
  right: -13px;
  left: inherit;
  bottom: -27px;
}
.workflow-container .workflow-content.active {
  z-index: 9;
}

.attach-more {
  width: 60px;
  min-width: auto;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}
.attach-more:hover:before,
.attach-more.active:before {
  display: none;
}
.attach-more:hover .attach-icon:before,
.attach-more.active .attach-icon:before {
  opacity: 1;
}
.attach-more.active {
  z-index: 9;
}
.attach-more .attach-icon {
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 25px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 3px 8px 0 rgba(36, 41, 70, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 3px 8px 0 rgba(36, 41, 70, 0.12);
  padding: 0;
  display: inline-block;
  position: relative;
  line-height: 38px;
}
.attach-more .attach-icon img {
  width: 24px;
}
.attach-more .attach-icon:before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  border: 2px solid #5369df;
  border-radius: 100%;
  opacity: 0;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.attach-more p {
  display: block;
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: 14px;
  text-align: center;
  margin-top: 8px;
}

.workflow-tree-lines {
  width: 50%;
  margin: 70px auto 0;
  height: 1px;
  background: #a9afc6;
  position: relative;
}
.workflow-tree-lines.no-bg {
  background: none;
}
.workflow-tree-lines:before {
  content: '';
  height: 28px;
  width: 1px;
  background: #a9afc6;
  display: inline-block;
  position: relative;
  top: -60px;
  left: 0px;
}
.workflow-tree-lines .workflow-loader-icon {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -35px;
  z-index: 8;
  background: #f5f5f9;
}
.workflow-tree-lines .workflow-loader-icon .loader-icon-inner {
  width: 50px;
  height: 50px;
  line-height: 48px;
  border-radius: 25px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  position: relative;
}
.workflow-tree-lines .workflow-loader-icon .loader-icon-inner:before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  border: 2px solid #5369df;
  border-radius: 100%;
  opacity: 0;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.workflow-tree-lines .workflow-loader-icon .loader-icon-inner.plus-icon img {
  width: 24px;
}
.workflow-tree-lines .workflow-loader-icon.active {
  z-index: 9;
  background: transparent;
}
.workflow-tree-lines .workflow-loader-icon.active .loader-icon-inner:before {
  opacity: 1;
}
.workflow-tree-lines .workflow-loader-icon.active + .time-duration {
  z-index: 9;
}
.workflow-tree-lines .time-duration {
  position: absolute;
  bottom: -60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 65px;
  color: #3b53d1;
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: 14px;
  text-align: center;
}
.workflow-tree-lines .branch-ui {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: -26px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.workflow-tree-lines .branch-ui .branch-text {
  width: 200px !important;
  display: inline-block;
  white-space: nowrap;
  color: #595f79;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 14px;
  text-align: right;
  vertical-align: middle;
}
.workflow-tree-lines .branch-ui .branch-text:first-child {
  text-align: right;
  margin: 0 35px 0 0;
  position: relative;
  left: -18px;
}
.workflow-tree-lines .branch-ui .branch-text:last-child {
  text-align: left;
  margin: 0 0px 0 35px;
}
.workflow-tree-lines .branch-ui .branch-text .icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  line-height: 15px;
  position: relative;
  top: -1px;
  vertical-align: middle;
}
.workflow-tree-lines .branch-ui .branch-text .icon:after {
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 20px;
  left: -2px;
  top: -2px;
  width: 20px;
  opacity: 0.12;
  background-color: #d12d4b;
}
.workflow-tree-lines .branch-ui .branch-text .icon.cross-icon {
  background-color: #d12d4b;
  margin-left: 10px;
}
.workflow-tree-lines .branch-ui .branch-text .icon.cross-icon:after {
  background-color: #d12d4b;
}
.workflow-tree-lines .branch-ui .branch-text .icon.check-icon {
  background-color: #119672;
  margin-right: 10px;
}
.workflow-tree-lines .branch-ui .branch-text .icon.check-icon:after {
  background-color: #119672;
}
.workflow-tree-lines .enrich-add-more {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -35px;
  z-index: 8;
  background: #f5f5f9;
}
.workflow-tree-lines .enrich-add-more .plus-ui {
  width: 50px;
  height: 50px;
  line-height: 48px;
  border-radius: 25px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  position: relative;
}
.workflow-tree-lines .enrich-add-more .plus-ui img {
  width: 24px;
}

.enrich-add-ui {
  display: block;
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: 14px;
  text-align: center;
  margin-top: 0px;
}

.workflow-tree-columns-outer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.workflow-tree-columns-outer .workflow-column {
  width: 50%;
  margin: 0;
}
.workflow-tree-columns-outer .workflow-column .workflow-tree {
  width: auto;
  position: relative;
  text-align: center;
}
.workflow-tree-columns-outer .workflow-container .workflow-line {
  height: 49px;
}

.multiple-branches .workflow-tree-lines {
  width: 100%;
}

.multiple-branches .workflow-tree-columns-outer .workflow-column {
  display: block;
}
.multiple-branches .workflow-tree-columns-outer .workflow-column:first-child {
  text-align: left;
}
.multiple-branches .workflow-tree-columns-outer .workflow-column:first-child .workflow-container {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.multiple-branches
  .workflow-tree-columns-outer
  .workflow-column:first-child
  .workflow-container
  .workflow-content {
  left: -28px;
  position: relative;
}
.multiple-branches .workflow-tree-columns-outer .workflow-column:last-child {
  text-align: right;
}
.multiple-branches .workflow-tree-columns-outer .workflow-column:last-child .workflow-container {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.multiple-branches
  .workflow-tree-columns-outer
  .workflow-column:last-child
  .workflow-container
  .workflow-content {
  margin-right: -100px;
  position: relative;
}

.expand .workflow-tree-structure {
  /*max-width:100%;*/
}

.workflow-setting {
  width: 0px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.workflow-setting.active {
  width: 360px;
  opacity: 1;
  visibility: visible;
  padding: 8px 0px 4px 20px;
}
.workflow-setting .setting-inner-wrap {
  position: relative;
  height: 100%;
}
.workflow-setting .setting-inner-wrap .sub-heading {
  color: #242946;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 6px;
}
.workflow-setting .setting-inner-wrap .desc {
  color: #595f79;
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-bottom: 11px;
}
.workflow-setting .setting-inner-wrap .info-text {
  color: #797f99;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.12px;
  line-height: 16px;
  text-align: right;
  margin-bottom: 0;
}
.workflow-setting .setting-inner-wrap .button-blocks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.workflow-setting .setting-inner-wrap .button-blocks .btn1,
.workflow-setting .setting-inner-wrap .button-blocks .btn2 {
  width: 100%;
  text-align: center;
}
.workflow-setting .setting-inner-wrap .button-blocks .btn2 {
  margin-right: 10px;
  width: 40%;
}
.workflow-setting .setting-inner-wrap .button-blocks .btn1 {
  width: 60%;
}
.workflow-setting .setting-inner-wrap .step1 {
  padding-bottom: 50px;
  height: 100%;
  display: none;
}
.workflow-setting .setting-inner-wrap .step1.active {
  display: block;
}
.workflow-setting .setting-inner-wrap .attach-workflow-wrapper {
  display: none;
}
.workflow-setting .setting-inner-wrap .attach-workflow-wrapper.active {
  display: block;
}
.workflow-setting .setting-heading {
  margin-bottom: 32px;
  position: relative;
}
.workflow-setting .setting-heading .close-btn {
  height: 32px;
  width: 32px;
  border-radius: 6px;
  background-color: #f5f5f9;
  display: inline-block;
  line-height: 32px;
  position: absolute;
  right: 0;
  top: 0px;
  text-align: center;
  font-size: 26px;
  font-weight: normal;
  color: #797f99;
}
.workflow-setting .setting-heading label {
  color: #797f99;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 16px;
  margin-bottom: 6px;
  padding-right: 35px;
}
.workflow-setting .setting-heading h4 {
  color: #0d1021;
  font-size: 18px;
  letter-spacing: 0.16px;
  line-height: 26px;
  margin-bottom: 12px;
  padding-right: 35px;
}
.workflow-setting .setting-heading p {
  color: #797f99;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-bottom: 0;
}

.daily-invitaion-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}
.daily-invitaion-ui .quantity-input {
  height: 41px;
  width: 139px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  text-align: center;
  margin-right: auto;
}
.daily-invitaion-ui .quantity-input input {
  height: 100%;
  border: none;
  outline: none;
  text-align: center;
  color: #0d1021;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  -webkit-appearance: none;
  vertical-align: bottom;
}
.daily-invitaion-ui .quantity-input .quty-btn {
  height: 28px;
  width: 28px;
  border-radius: 4px;
  background-color: #f7f7fa;
  line-height: 28px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.daily-invitaion-ui .quantity-input .quty-btn span {
  display: block;
  position: relative;
  top: -2px;
}
.daily-invitaion-ui .quantity-input .quty-btn:hover {
  background: rgba(83, 105, 223, 0.12);
}
.daily-invitaion-ui .quantity-input .quty-btn.add {
  float: right;
  margin: 5px 6px;
}
.daily-invitaion-ui .quantity-input .quty-btn.sub {
  margin: 5px 6px;
  float: left;
}

.textarea-wrapper .textarea-box {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  height: 117px;
  padding: 10px;
  width: 100%;
  resize: none;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  color: #a9afc6;
  font-size: 14px;
  letter-spacing: 0.12px;
  line-height: 22px;
}
.textarea-wrapper .textarea-box:focus,
.textarea-wrapper .textarea-box:hover {
  border: 1px solid #b1b3bb;
  outline: none;
}
.textarea-wrapper .textarea-box .tag {
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #ffe7ef;
  display: inline-block;
  color: #e23f74;
}

.textarea-wrapper .select-container {
  position: relative;
}
.textarea-wrapper .select-container .dropdown-toggle {
  background: none;
  border: none;
  color: #3b53d1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding-left: 0;
  margin-top: 5px;
}
.textarea-wrapper .select-container .dropdown-toggle:focus {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background: none;
  color: #3b53d1;
}
.textarea-wrapper .select-container .dropdown-toggle:after {
  display: none;
}

.textarea-wrapper .dropdown-menu {
  bottom: 30px;
  -webkit-transform: inherit !important;
  -ms-transform: inherit !important;
  transform: inherit !important;
  left: 0;
  top: inherit !important;
  width: 250px;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  border: none;
  color: #595f79;
  font-size: 14px;
  max-height: 110px;
  overflow-y: auto;
}

.textarea-footer-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.textarea-footer-ui .info-text {
  margin-left: auto;
}

.radio-btn,
.checkbox-btn {
  display: block;
  margin-bottom: 12px;
  position: relative;
}
.radio-btn input,
.checkbox-btn input {
  -webkit-appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
.radio-btn input:focus,
.checkbox-btn input:focus {
  outline: none;
}
.radio-btn input:checked + .radio-icon,
.checkbox-btn input:checked + .radio-icon {
  border: 1px solid rgba(83, 105, 223, 0.12);
  background: -webkit-linear-gradient(309.68deg, #5369df 0%, #3b53d1 100%);
  background: -o-linear-gradient(309.68deg, #5369df 0%, #3b53d1 100%);
  background: linear-gradient(140.32deg, #5369df 0%, #3b53d1 100%);
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(13, 16, 33, 0.24);
  box-shadow: inset 0 1px 1px 0 rgba(13, 16, 33, 0.24);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.radio-btn input:checked + .radio-icon:before,
.checkbox-btn input:checked + .radio-icon:before {
  opacity: 1;
  -webkit-transform: scale(1) translateY(-50%);
  -ms-transform: scale(1) translateY(-50%);
  transform: scale(1) translateY(-50%);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.radio-btn input:checked + .radio-icon + .radio-text,
.checkbox-btn input:checked + .radio-icon + .radio-text {
  color: #242946;
}
.radio-btn .radio-icon,
.checkbox-btn .radio-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  border-radius: 100%;
  z-index: 1;
  border: 1px solid #d5d7e4;
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  vertical-align: middle;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  position: relative;
}
.radio-btn .radio-icon:before,
.checkbox-btn .radio-icon:before {
  height: 7px;
  width: 7px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  -webkit-box-shadow: -1px 2px 3px 0 rgba(13, 16, 33, 0.32);
  box-shadow: -1px 2px 3px 0 rgba(13, 16, 33, 0.32);
  position: absolute;
  content: '';
  top: 50%;
  left: 7px;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(0) translateY(-50%);
  -ms-transform: scale(0) translateY(-50%);
  transform: scale(0) translateY(-50%);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.radio-btn .radio-text,
.checkbox-btn .radio-text {
  vertical-align: middle;
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #ffffff;
  margin-left: 7px;
  text-transform: capitalize;
}

.checkbox-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.checkbox-btn .checkbox-icon {
  width: 21px;
  height: 21px;
  display: inline-block;
  z-index: 1;
  border: 1px solid #d5d7e4;
  border-radius: 4px;
  background-color: #ffffff;
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  vertical-align: middle;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  position: relative;
}
.checkbox-btn .checkbox-icon:before {
  height: 7px;
  width: 7px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  -webkit-box-shadow: -1px 2px 3px 0 rgba(13, 16, 33, 0.32);
  box-shadow: -1px 2px 3px 0 rgba(13, 16, 33, 0.32);
  position: absolute;
  content: '';
  top: 6px;
  left: 7px;
  border-radius: 50%;
  opacity: 0;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.workflow-tab-list {
  margin-top: 30px;
}
.workflow-tab-list.new_block_tab .nav-tabs {
  border-radius: 8px;
  background-color: #f5f5f9;
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 4px 5px;
  z-index: 1;
  position: relative;
  margin-bottom: 20px;
}
.workflow-tab-list.new_block_tab .nav-tabs li {
  width: 50%;
}
.workflow-tab-list.new_block_tab .nav-tabs li a {
  border-radius: 6px;
  text-align: center;
  display: block;
  padding: 8px;
  color: #797f99;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 16px;
  text-align: center;
  text-shadow: -1px 1px 0 0 #f5f5f9;
  z-index: 2;
}
.workflow-tab-list.new_block_tab .nav-tabs li a.active {
  background: #fff;
  color: #3b53d1;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
}
.workflow-tab-list.new_block_tab .nav-tabs .slider {
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  color: #3b53d1;
  width: 50%;
  position: absolute;
  border-radius: 6px;
  left: 4px;
  height: 32px;
  z-index: -1;
  top: 4px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.workflow-tab-list .sub-title {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.integration_list_block p {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 16px;
}

.workflow-tab-list .tab-pane ul {
  max-height: 295px;
  overflow-y: auto;
}

.workflow-tab-list.existing-workflow-tab ul {
  max-height: 335px;
  overflow-y: auto;
}

.tab-pane .sub-title {
  margin-bottom: 16px;
}

.select-integration ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.select-integration ul li .img-block {
  height: 48px;
  width: 48px;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  margin-right: 10px;
}
.select-integration ul li .img-block img {
  width: 100%;
}
.select-integration ul li .content-block p {
  color: #242946;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
}
.select-integration ul li .content-block .connected-tag {
  color: #119672;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  position: relative;
  padding-left: 13px;
  display: block;
}
.select-integration ul li .content-block .connected-tag:before {
  position: absolute;
  content: '';
  height: 7px;
  width: 7px;
  border: 1px solid #14d273;
  background-color: #1be27f;
  border-radius: 100%;
  top: 5px;
  left: 0;
}
.select-integration ul li .content-block .not-connected-tag {
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  position: relative;
  padding-left: 13px;
  display: block;
}
.select-integration ul li .content-block .not-connected-tag:before {
  position: absolute;
  content: '';
  height: 7px;
  width: 7px;
  border: 1px solid #ced0df;
  background-color: #d5d7e4;
  border-radius: 100%;
  top: 5px;
  left: 0;
}
.select-integration ul li .radio-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 35px;
}
.select-integration ul li .radio-btn input {
  width: 100%;
  z-index: 2;
}
.select-integration ul li .radio-btn .radio-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}
.select-integration ul li .radio-btn .radio-icon:before {
  background: url('assets/images/ic_check_shadow.svg') no-repeat;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 20px;
  height: 20px;
  z-index: 1;
  background-size: 15px;
  background-position: center;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.select-integration ul li .radio-btn .radio-text {
  width: 100%;
  margin-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tab-pane ul li {
  margin-bottom: 13px;
}
.tab-pane ul li .checkbox-wrap {
  position: relative;
  width: 100%;
}
.tab-pane ul li .checkbox-wrap label {
  width: 100%;
  padding-left: 28px;
}
.tab-pane ul li .checkbox-wrap label .checkbox-icon {
  position: absolute;
  left: 0;
  background: #3b53d1;
  width: 21px;
  height: 21px;
  border-radius: 4px;
  top: 1px;
}
.tab-pane ul li .checkbox-wrap label .checkbox-icon:before {
  border: none;
}
.tab-pane ul li .checkbox-wrap label .checkbox-icon:before {
  background: url('assets/images/ic_check_shadow.svg') no-repeat;
  content: '';
  border: none;
  background-size: 16px;
  width: 20px;
  height: 20px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  top: 2px;
  left: 3px;
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  transform: scale(0.4);
}
.tab-pane ul li .checkbox-wrap label input:checked + .checkbox-icon:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.tab-pane ul li .checkbox-wrap .checkbox-text {
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #ffffff;
}

.new_block_tab {
  display: none;
}
.new_block_tab.active {
  display: block;
}

.existing-workflow-tab {
  display: none;
  border-top: 1px solid #d5d7e4;
  padding-top: 20px;
}
.existing-workflow-tab.active {
  display: block;
}
.existing-workflow-tab .sub-title {
  margin-bottom: 15px;
}
.existing-workflow-tab ul li {
  margin-bottom: 20px;
}
.existing-workflow-tab ul li .tag {
  font-size: 12px;
  letter-spacing: 0.16px;
  line-height: 14px;
  display: block;
  padding: 0;
}
.existing-workflow-tab ul li .tag.red-text {
  color: #e23381;
}
.existing-workflow-tab ul li .tag.blue-text {
  color: #573ad7;
}
.existing-workflow-tab ul li .radio-btn {
  cursor: pointer;
  padding-right: 35px;
}
.existing-workflow-tab ul li .radio-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}
.existing-workflow-tab ul li .radio-icon:before {
  background: url('assets/images/ic_check_shadow.svg') no-repeat;
  content: '';
  border: none;
  background-size: 15px;
  width: 20px;
  height: 20px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  top: 4px;
  left: 3px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.existing-workflow-tab ul li .radio-text {
  color: #242946;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.existing-workflow-tab ul li input:checked + .radio-icon:before {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.expend .create-workflow-tree-block {
  width: 100%;
  height: 100%;
  position: fixed;
  -webkit-animation: mymove 0.2s;
  animation: mymove 0.2s;
}

@-webkit-keyframes mymove {
  0% {
    top: 20px;
    left: 50px;
    right: 20px;
    width: 100%;
  }
  100% {
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
  }
}

@keyframes mymove {
  0% {
    top: 20px;
    left: 50px;
    right: 20px;
    width: 100%;
  }
  100% {
    top: 0px;
    left: 0;
    right: 0;
    width: 100%;
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.timer-workflow {
  display: none;
}
.timer-workflow.active {
  display: block;
}

.timer-fields-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.timer-fields-ui .form-group {
  width: 30%;
}
.timer-fields-ui .form-group label {
  text-transform: capitalize;
  font-size: 14px;
  margin-bottom: 3px;
}
.timer-fields-ui .form-group .quantity-btn-ui {
  position: relative;
  height: 88%;
  margin: 0 2px;
}
.timer-fields-ui .form-group .quantity-btn-ui input {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  text-align: center;
}
.timer-fields-ui .form-group .quantity-btn-ui .quty-btn {
  position: absolute;
  z-index: 9;
  height: 18px;
  width: 18px;
  right: 0;
  line-height: 18px;
  text-align: center;
}
.timer-fields-ui .form-group .quantity-btn-ui .quty-btn.add {
  right: 4px;
  top: 4px;
}
.timer-fields-ui .form-group .quantity-btn-ui .quty-btn.add:before {
  position: absolute;
  content: '';
  background: url(assets/images/ic_arrow_down.svg) no-repeat;
  left: 0;
  right: 0;
  margin: auto;
  width: 13px;
  height: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
  text-align: center;
}
.timer-fields-ui .form-group .quantity-btn-ui .quty-btn.sub {
  right: 4px;
  bottom: 4px;
}
.timer-fields-ui .form-group .quantity-btn-ui .quty-btn.sub:before {
  position: absolute;
  content: '';
  background: url(assets/images/ic_arrow_down.svg) no-repeat;
  width: 11px;
  left: 0;
  right: 0px;
  margin: auto;
  height: 10px;
  top: 50%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.workflow-column.full-width {
  width: 100%;
}

.integration-workflow .sub-title2 {
  margin-bottom: 15px;
  color: #595f79;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 19px;
  display: block;
  text-align: left;
  margin-bottom: 15px;
}

.integration-workflow .select-integration ul li {
  margin-bottom: 15px;
}
.integration-workflow .select-integration ul li .img-block {
  padding: 5px;
}

.no-bg {
  background: transparent !important;
}

.hubspot-content .msg {
  font-size: 14px;
}

.hubspot-content .btn3 {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.hubspot-content .btn3.connect-btn {
  margin-top: 10px;
}

.hubspot-content .configure-btn {
  width: 100%;
  text-align: center;
  margin-top: 15px;
}

.hubspot-content .configure-block {
  padding-top: 10px;
}
.hubspot-content .configure-block .sub-heading {
  font-size: 16px;
  padding-bottom: 5px;
}
.hubspot-content .configure-block.active {
  display: block;
}

.spacer-block {
  height: 30px;
}

.edit-profile-ui .floating-effect input:not(:placeholder-shown) ~ label {
  display: none;
}

.enrichment-flow .sub-title {
  margin-bottom: 15px;
}

.enrichment-flow ul li {
  position: relative;
  margin-bottom: 15px;
}
.enrichment-flow ul li .checkbox-icon {
  position: absolute;
  left: 0;
  background: #3b53d1;
  width: 21px;
  height: 21px;
  border-radius: 4px;
  top: 1px;
}
.enrichment-flow ul li label {
  padding-left: 28px;
  position: relative;
}

.list-container {
  /* height: 260px;*/
  margin-left: auto;
  margin-right: auto;
  margin: 0 auto;
  position: relative;
  top: 50px;
}
@media (max-width: 767px) {
  .list-container {
    height: 490px;
  }
}
.list-container .list {
  position: relative;
}

.verticalSlider ul {
  position: relative;
  list-style: none;
}

.verticalSlider ul li {
  padding: 24px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  border-radius: 4px;
  max-width: 346px;
  border-radius: 12px;
  background-color: white;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 6px 12px 0 rgba(20, 24, 47, 0.08);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 6px 12px 0 rgba(20, 24, 47, 0.08);
  text-align: left;
}

.verticalSlider ul li .date {
  margin-left: 10px;
  color: #8898aa;
}

.tab-0 {
  -webkit-transform: translateY(-50px) scale(1.07);
  -ms-transform: translateY(-50px) scale(1.07);
  transform: translateY(-50px) scale(1.07);
  opacity: 0;
  z-index: 90;
  -webkit-filter: blur(10px);
  filter: blur(10px);
  -webkit-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96);
  -o-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96);
  transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96);
}

.tab-1 {
  opcaity: 1;
  background-color: #fff;
  -webkit-box-shadow:
    0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
  box-shadow:
    0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07);
  z-index: 80;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 100ms;
  -o-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 100ms;
  transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 100ms;
}

.tab-2 {
  -webkit-transform: translateY(15px) scale(0.9);
  -ms-transform: translateY(15px) scale(0.9);
  transform: translateY(15px) scale(0.9);
  opacity: 1;
  z-index: 70;
  background: #e2e7ed;
  color: #e2e7ed;
  -webkit-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 200ms;
  -o-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 200ms;
  transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 200ms;
}

.tab-3 {
  -webkit-transform: translateY(25px) scale(0.8);
  -ms-transform: translateY(25px) scale(0.8);
  transform: translateY(25px) scale(0.8);
  opacity: 0.5;
  z-index: 60;
  background: #e2e7ed;
  color: #e2e7ed;
  -webkit-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 300ms;
  -o-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 300ms;
  transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 300ms;
}

.tab-4 {
  -webkit-transform: translateY(30px) scale(0.7);
  -ms-transform: translateY(30px) scale(0.7);
  transform: translateY(30px) scale(0.7);
  opacity: 0;
  z-index: 50;
  background: grey;
  color: grey;
  -webkit-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 400ms;
  -o-transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 400ms;
  transition: all 1s cubic-bezier(0.19, 0.49, 0.46, 0.96) 400ms;
}

.content-box-ui {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  margin-bottom: 32px;
}
.content-box-ui .content-header {
  padding: 24px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .content-box-ui .content-header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .content-box-ui .content-header.p-16 {
    -ms-flex-wrap: initial;
    flex-wrap: initial;
  }
  .content-box-ui .content-header.p-16 .button-ui {
    margin-left: auto;
    margin-top: 0;
  }
}
.content-box-ui .content-header.p-16 {
  padding: 16px 20px;
}
.content-box-ui .content-header .button-ui {
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .content-box-ui .content-header .button-ui {
    margin-left: inherit;
    margin-top: 10px;
  }
}
.content-box-ui .content-header .title-ui label {
  color: #0d1021;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 24px;
  margin: 0;
}
.content-box-ui .content-header .title-ui p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 20px;
  font-weight: normal;
  margin-bottom: 5px;
  margin-top: 8px;
}
.content-box-ui .body-content {
  border-top: 1px solid #f5f5f9;
}

.mr14 {
  margin-right: 14px;
}

.inner-body-content {
  padding: 30px 20px;
}
.inner-body-content > .row {
  margin: 0;
}
.inner-body-content.edit-profile-ui {
  padding: 30px 5px;
}

.edit-profile-ui .form-group label {
  text-transform: uppercase;
}

.input-summry,
.info-block {
  margin-bottom: 40px;
  padding: 0 12px;
  position: relative;
}
.input-summry label,
.info-block label {
  color: #797f99;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 16px;
  margin-bottom: 4px;
  text-transform: uppercase;
}
.input-summry p,
.info-block p {
  color: #0d1021;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
}
.input-summry .text-btn,
.info-block .text-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.input-summry.edit-field p,
.info-block.edit-field p {
  padding-right: 125px;
}

.inner-body-content ul li .checkbox-wrap {
  height: auto;
}
.inner-body-content ul li .checkbox-wrap label {
  padding-left: 36px;
  vertical-align: top;
}
.inner-body-content ul li .checkbox-wrap label .checkbox-icon {
  top: 8px;
}
.inner-body-content ul li .checkbox-wrap label .checkbox-text {
  vertical-align: top;
}
.inner-body-content ul li .checkbox-wrap label .checkbox-text span {
  color: #242946;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 24px;
}
.inner-body-content ul li .checkbox-wrap label .checkbox-text p {
  color: #595f79;
  font-size: 12px;
  letter-spacing: 0.32px;
  line-height: 16px;
}

/*integration css strat */
.inner-title {
  color: #0d1021;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 24px;
}

.integartion-header-ui {
  margin-top: 35px;
  border-bottom: 1px solid #d5d7e4;
  padding-bottom: 15px;
}
.integartion-header-ui .top-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 599px) {
  .integartion-header-ui .top-ui {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.integartion-header-ui .top-ui .right-ui {
  margin-left: auto;
}
@media only screen and (max-width: 599px) {
  .integartion-header-ui .top-ui .right-ui {
    margin-left: inherit;
    width: 100%;
  }
  .integartion-header-ui .top-ui .right-ui.page-header .search-ui {
    width: 100%;
    margin-top: 5px;
  }
  .integartion-header-ui .top-ui .right-ui.page-header .search-ui input {
    width: 100%;
  }
}
.integartion-header-ui .content-ui p {
  color: #797f99;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  max-width: 70%;
}
@media only screen and (max-width: 991px) {
  .integartion-header-ui .content-ui p {
    max-width: 100%;
    margin-top: 15px;
  }
}

.integrations-tool-wrapper .header-block {
  padding: 23px 0 20px;
}
.integrations-tool-wrapper .header-block p {
  color: #414766;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
}

.integrations-card {
  height: 210px;
  /*width: 370px;*/
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 8px 12px 0 rgba(13, 16, 33, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 8px 12px 0 rgba(13, 16, 33, 0.12);
  padding: 20px;
  margin-bottom: 30px;
  cursor: pointer;
}
.integrations-card .top-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}
.integrations-card .top-block .left-ui label {
  margin-bottom: 4px;
  color: #797f99;
  font-size: 10px;
  letter-spacing: 0.64px;
  line-height: 12px;
}
.integrations-card .top-block .left-ui p {
  color: #242946;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
}
.integrations-card .top-block .right-ui {
  margin-left: auto;
}
.integrations-card .top-block .right-ui figure {
  width: 45px;
  margin-bottom: 0;
  border: 0.96px solid #f5f5f9;
  border-radius: 6px;
}
.integrations-card .top-block .right-ui img {
  width: 100%;
}
.integrations-card .middle-content-ui {
  margin-bottom: 25px;
}
.integrations-card .middle-content-ui p {
  color: #797f99;
  font-size: 13px;
  letter-spacing: 0.12px;
  line-height: 18px;
  margin-bottom: 0;
  min-height: 55px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.integrations-card .bottom-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.integrations-card .bottom-ui .connect-btn {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 18px;
}
.integrations-card .bottom-ui .connect-btn.active {
  border-radius: 12px;
  background-color: #d7f7e7;
  padding: 4px 10px;
  color: #0c6f55;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 18px;
  padding-left: 28px;
  position: relative;
}
.integrations-card .bottom-ui .connect-btn.active:before {
  position: absolute;
  content: '';
  left: 7px;
  top: 5px;
  background: url('assets/images/ic_green_check.svg');
  width: 16px;
  height: 14px;
}
.integrations-card .bottom-ui .more-info-ui {
  margin-left: auto;
  color: #797f99;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 18px;
}

.integrations-card.disabled-card {
  height: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.integrations-card.disabled-card .top-block .right-ui {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}
.integrations-card.disabled-card .top-block .left-ui label {
  color: #a9afc6;
}
.integrations-card.disabled-card .top-block .left-ui .title {
  color: #595f79;
}
.integrations-card.disabled-card .middle-content-ui {
  margin-bottom: 5px;
}

.wp-60 {
  width: 60%;
}
@media only screen and (max-width: 991px) {
  .wp-60 {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .mob-full {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.connect-modal .modal-dialog {
  width: 100%;
  max-width: 600px;
}

.connect-modal .modal-inner-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.connect-modal .modal-inner-header .profile-img-block {
  height: 92px;
  width: 92px;
  margin-right: 16px;
}
.connect-modal .modal-inner-header .profile-img-block img {
  width: 100%;
}
.connect-modal .modal-inner-header .profile-info .username {
  color: #0d1829;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
}
.connect-modal .modal-inner-header .profile-info .designation {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 16px;
}

.connect-modal .inner-description label {
  color: #242946;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
  margin-bottom: 16px;
}

.connect-modal .inner-description p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 22px;
  margin-bottom: 20px;
}

.connect-modal .modal-footer {
  padding-bottom: 32px;
}

.connect-modal .text-btn {
  padding: 5px 10px;
}
.connect-modal .text-btn .link-icon {
  display: inline-block;
  margin-left: 8px;
}

.profile-picture-ui {
  margin-bottom: 32px;
}
.profile-picture-ui .title-ui {
  display: block;
  margin-bottom: 16px;
  color: #797f99;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 16px;
}
.profile-picture-ui .profile-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.profile-picture-ui figure {
  width: 96px;
  height: 96px;
  background-color: #f7f7fa;
  -webkit-box-shadow: inset 0 1px 6px 0 rgba(13, 16, 33, 0.04);
  box-shadow: inset 0 1px 6px 0 rgba(13, 16, 33, 0.04);
  border-radius: 100%;
  margin-right: 32px;
  margin-bottom: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.profile-picture-ui .grey-btn {
  background-color: #f0f2ff;
  color: #5369df;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.profile-picture-ui .grey-btn .plus-icon {
  display: inline-block;
  margin-right: 8px;
  font-size: 18px;
  color: #5369df;
}

.request-integration-in {
  padding: 0 24px;
}
.request-integration-in label {
  color: #242946;
  font-size: 18px;
  letter-spacing: 0.16px;
  line-height: 28px;
  margin-bottom: 16px;
}
.request-integration-in textarea {
  height: 103px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  padding: 16px;
  color: #242946;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 20px;
  width: 100%;
  resize: none;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.request-integration-in textarea:focus {
  border: 1px solid #afb0bd;
  outline: none;
}
.request-integration-in textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #797f99;
}

.configration-inner-ui {
  padding: 10px 0px 0px 25px;
}
.configration-inner-ui table {
  width: 100%;
}
.configration-inner-ui table thead {
  border-bottom: 1px solid #f5f5f9;
}
.configration-inner-ui table tbody {
  padding-top: 20px;
}
.configration-inner-ui table tbody tr:first-child td {
  padding-top: 16px;
}
.configration-inner-ui table th {
  color: #3b53d1;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 20px;
  padding-bottom: 10px;
}
.configration-inner-ui table td {
  padding: 8px 0;
  color: #414766;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 24px;
}
.configration-inner-ui table td .form-group {
  margin-bottom: 0;
  position: relative;
  width: 100%;
}
.configration-inner-ui table td .form-group input {
  border-radius: 8px;
  background-color: #ffffff;
  border: none;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 40px;
  width: 100%;
  padding: 0 20px 0 42px;
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
  color: #414766;
}
.configration-inner-ui table td .form-group input:focus {
  -webkit-box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 4px 8px 0 rgba(20, 24, 47, 0.2);
  box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 4px 8px 0 rgba(20, 24, 47, 0.2);
}
.configration-inner-ui table td .form-group .search-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.profile-steps .content-header {
  padding: 24px;
}
.profile-steps .content-header .title-ui {
  position: relative;
  padding-left: 46px;
  padding-right: 20px;
}
.profile-steps .content-header .title-ui:before {
  position: absolute;
  content: '';
  background: url('assets/images/profile-placeholder.png');
  width: 30px;
  height: 38px;
  left: 0;
  top: 4px;
}
.profile-steps .content-header .title-ui h4 {
  color: #0d1021;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 24px;
  margin-bottom: 8px;
}
.profile-steps .content-header .title-ui p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 16px;
  margin-bottom: 0;
}
.profile-steps .content-header .right-block {
  min-width: 330px;
}
.profile-steps .content-header .right-block .progress {
  width: 185px;
}
@media only screen and (max-width: 991px) {
  .profile-steps .content-header .right-block .progress {
    width: 300px;
  }
}
@media only screen and (max-width: 991px) {
  .profile-steps .content-header .right-block {
    margin-left: initial !important;
    margin-top: 30px;
    width: 70%;
  }
}

.profile-steps .profile-steps-listing ul li {
  margin: 0;
  padding: 19px 26px;
  border-top: 1px solid #f5f5f9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.profile-steps .profile-steps-listing ul li:before {
  position: absolute;
  content: '';
  height: 29px;
  width: 29px;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(assets/images/arrow_right-blue.svg) no-repeat;
  background-size: 7px;
  background-position: center;
}
.profile-steps .profile-steps-listing ul li.verified:before {
  position: absolute;
  content: '';
  height: 29px;
  width: 29px;
  left: 25px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(assets/images/ic-status-active.png) no-repeat;
  background-size: 29px;
  background-position: center;
}
.profile-steps .profile-steps-listing ul li.verified .right-ui .text-btn {
  color: #a9afc6;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: auto;
}
.profile-steps .profile-steps-listing ul li.verified .right-ui .text-btn:hover {
  text-decoration: none;
}
.profile-steps .profile-steps-listing ul li .left-ui {
  margin-right: auto;
}
.profile-steps .profile-steps-listing ul li .left-ui p {
  color: #0d1021;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 0;
  padding-left: 40px;
}
.profile-steps .profile-steps-listing ul li .right-ui .text-btn {
  color: #3b53d1;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .profile-steps .profile-steps-listing ul li {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .profile-steps .profile-steps-listing ul li .left-ui {
    width: 100%;
  }
  .profile-steps .profile-steps-listing ul li .right-ui {
    padding-left: 40px;
    padding-top: 10px;
  }
}

.complete-profile-wrapper .new-organization-ui .thumnail-upload-ui figure {
  height: 72px;
  width: 72px;
  background: #f5f5f9;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.complete-profile-wrapper .new-organization-ui .thumnail-upload-ui figure img {
  width: auto;
}

.complete-profile-wrapper .add-member-fields .form-group {
  position: relative;
}
.complete-profile-wrapper .add-member-fields .form-group .checked-icon {
  position: absolute;
  top: 38px;
  right: 16px;
  opacity: 0;
  z-index: 2;
}
.complete-profile-wrapper .add-member-fields .form-group.half {
  width: 48%;
  display: inline-block;
}
.complete-profile-wrapper .add-member-fields .form-group.half.ml-14 {
  margin-left: 15px;
}
.complete-profile-wrapper .add-member-fields .form-group input:focus,
.complete-profile-wrapper .add-member-fields .form-group input:not(:placeholder-shown) {
  border-color: #d5d7e4;
}
.complete-profile-wrapper .add-member-fields .form-group input.active + .checked-icon {
  display: block;
  opacity: 1;
}
.complete-profile-wrapper .add-member-fields .form-group input.active:after {
  position: absolute;
  content: '';
  background: url('assets/images/ic-checked.svg');
  width: 24px;
  height: 24px;
  right: 16px;
  top: 10px;
}
.complete-profile-wrapper .add-member-fields .form-group .phone-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.complete-profile-wrapper .add-member-fields .form-group .phone-field .flag-ui {
  height: 45px;
  width: 85px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  margin-right: 20px;
}
.complete-profile-wrapper .add-member-fields .form-group .phone-field .input-field-ui {
  width: 100%;
  position: relative;
}
.complete-profile-wrapper .add-member-fields .form-group .phone-field .input-field-ui label {
  position: absolute;
  left: 16px;
  top: 10px;
  color: #14182f;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.18px;
  line-height: 24px;
  padding-right: 12px;
  display: inline-block;
  width: auto;
}
.complete-profile-wrapper .add-member-fields .form-group .phone-field .input-field-ui label:after {
  position: absolute;
  content: '';
  height: 22px;
  width: 1px;
  background: #d5d7e4;
  right: 0;
}
.complete-profile-wrapper .add-member-fields .form-group .phone-field .input-field-ui input {
  width: 100%;
  padding-left: 70px;
}
.complete-profile-wrapper
  .add-member-fields
  .form-group
  .phone-field
  .input-field-ui
  input.active
  + .checked-icon {
  top: 9px;
}

.api-integration-wrapper .inner-description p {
  margin-bottom: 20px;
}

.api-integration-wrapper .modal-footer .btn1 {
  padding: 0 30px;
}

.api-integration-wrapper .form-group {
  position: relative;
  border-radius: 8px;
}
.api-integration-wrapper .form-group input {
  width: 100%;
  height: 56px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #fff;
  padding: 0 10px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  padding-left: 100px;
}
.api-integration-wrapper .form-group input:focus {
  outline: none;
  border-color: #5369df;
  background-color: #fff;
  -webkit-box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
  box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
}
.api-integration-wrapper .form-group label {
  background: #f5f5f9;
  position: absolute;
  left: 1px;
  top: 1px;
  height: 54px;
  line-height: 54px;
  padding: 0 20px;
  border-radius: 8px 0 0 8px;
  font-size: 14px;
  margin-bottom: 0;
}

/*member page css start here */
.owner-tag {
  min-height: 18px;
  min-width: auto;
  border-radius: 6px;
  background-color: #e9edff;
  padding: 1px 10px;
  text-align: center;
  color: #3b53d1 !important;
  font-weight: 600;
  line-height: 14px;
  margin-left: 10px;
}

.my-team-block {
  padding: 12px 20px 16px;
}
.my-team-block .title {
  color: #414766;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-bottom: 16px;
}
.my-team-block .content-header {
  padding: 0;
}
.my-team-block .content-header .button-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 991px) {
  .my-team-block .content-header .button-ui {
    margin-left: auto;
  }
}
@media only screen and (max-width: 991px) and (max-width: 767px) {
  .my-team-block .content-header .button-ui {
    margin-left: inherit;
    display: block;
  }
}
.my-team-block .content-header .button-ui .btn2:first-child {
  margin-right: 14px;
}
.my-team-block .content-header .button-ui .btn2 .ic-btn {
  margin-right: 8px;
  position: relative;
  top: -2px;
}
.my-team-block .content-header .title-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .my-team-block .content-header .title-ui {
    width: 100%;
  }
}
.my-team-block .content-header .title-ui p {
  color: #0d1829;
  font-size: 24px;
  letter-spacing: 0.24px;
  line-height: 32px;
  margin: 0;
}
.my-team-block .content-header .title-ui .award-icon {
  margin-right: 14px;
  display: inline-block;
  height: 44px;
}

.addmember-btn .icon {
  width: 18px;
}

/*member page css End here */
/* statistics page css start here  */
.info-card {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  padding: 0 20px;
}
.info-card .info-card-header {
  padding: 16px 0;
  border-bottom: 1px solid #d5d7e4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.info-card .info-card-header .sorting-block {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.info-card .info-card-header .sorting-block label {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 18px;
  color: #595f79;
  margin-bottom: 0;
}
.info-card .info-card-header .sorting-block .bootstrap-select button {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 18px;
  color: #3b53d1;
}
.info-card .info-card-header .sorting-block .bootstrap-select .dropdown-menu {
  left: inherit !important;
  right: 0;
}
.info-card .info-card-header .text-btn {
  margin-left: auto;
  color: #5369df;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
}
.info-card .info-card-header p {
  color: #414766;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
}
.info-card.card-listing-wrapper {
  padding: 0px 0;
  margin-bottom: 30px;
  overflow: hidden;
  overflow-y: auto;
}
.info-card.card-listing-wrapper .info-card-header {
  width: 90%;
  margin: auto;
}
.info-card.card-listing-wrapper .info-card-content {
  margin-bottom: 0;
  padding: 16px 0 0;
}
.info-card.card-listing-wrapper .info-card-content .card-info-listing {
  position: relative;
}
.info-card.card-listing-wrapper .info-card-content .card-info-listing ul {
  padding: 0px 20px 0;
  max-height: 235px;
  overflow: hidden;
  margin-bottom: 0;
}
.info-card.card-listing-wrapper .info-card-content .card-info-listing ul li {
  margin-bottom: 16px;
}
.info-card.card-listing-wrapper .info-card-content .card-info-listing ul.active {
  overflow-y: auto;
}

.info-card-content {
  padding: 16px 0;
  margin-bottom: 30px;
}
.info-card-content.graph-block {
  min-height: 300px;
}

.plan-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.plan-info:last-child {
  margin-bottom: 0;
}
.plan-info .right-ui {
  margin-left: auto;
}
.plan-info .right-ui .regular-text {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
}
.plan-info .credit-point {
  color: #0d1021;
  font-size: 24px;
  letter-spacing: 0.24px;
  line-height: 28px;
  text-align: right;
}
.plan-info .cr-icon {
  width: 22px;
  display: inline-block;
}
.plan-info .cr-icon img {
  width: 100%;
}
.plan-info .link-text {
  color: #3b53d1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
}
.plan-info .info-text {
  color: #797f99;
  font-size: 10px;
  letter-spacing: 0.11px;
  line-height: 11px;
}
.plan-info .left-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.plan-info .left-ui.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.plan-info .price-text {
  color: #0d1021;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0.32px;
  line-height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.plan-info .price-text.link-text {
  color: #3b53d1;
  font-weight: normal;
}
.plan-info .price-info {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-left: 3px;
}
.plan-info label {
  display: block;
  color: #797f99;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
  width: 100%;
}

.more-credit-ui {
  background: -webkit-linear-gradient(318.12deg, #f9e5f2 0%, #e7ebff 100%);
  background: -o-linear-gradient(318.12deg, #f9e5f2 0%, #e7ebff 100%);
  background: linear-gradient(131.88deg, #f9e5f2 0%, #e7ebff 100%);
  width: 100%;
  height: 142px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  padding: 20px 20px;
}
.more-credit-ui:after {
  position: absolute;
  content: '';
  height: 93px;
  width: 102px;
  background: url(assets/images/crediticon-bg.svg) no-repeat;
  right: -4px;
  bottom: 0;
}
.more-credit-ui.lc-icon:after {
  background: url(assets/images/ic-b-credit.png) no-repeat;
  width: 170px;
  height: 170px;
  bottom: -22px;
  right: -27px;
}
.more-credit-ui p {
  color: #0d1829;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: -0.12px;
  line-height: 24px;
  max-width: 145px;
  width: 100%;
  margin-bottom: 20px;
}
.more-credit-ui.font-24 {
  height: auto;
  padding: 24px;
}
.more-credit-ui.font-24 p {
  color: #0d1829;
  font-size: 24px;
  letter-spacing: 0.24px;
  line-height: 32px;
  margin-bottom: 42px;
  max-width: 200px;
}

.card-info-listing li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 38px;
}
.card-info-listing li .user-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.card-info-listing li .user-info .profile-ui {
  height: 38px;
  width: 38px;
  background-color: #ffdede;
  margin-right: 10px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 0;
}
.card-info-listing li .user-info .profile-ui img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.card-info-listing li .user-info .profile-detail p {
  color: #242946;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #ffffff;
  margin-bottom: 4px;
}
.card-info-listing li .user-info .profile-detail span {
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0.14px;
  line-height: 14px;
  display: block;
}
.card-info-listing li .right-ui {
  margin-left: auto;
}

.credit-value {
  color: #414766;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}
.credit-value.grey-bg {
  border-radius: 6px;
  background-color: #f5f5f9;
  padding: 4px 7px;
}
.credit-value .credit-icon {
  color: #ffffff;
  text-shadow: -1px 1px 0 0 #595f79;
  background-color: #797f99;
  width: 17px;
  height: 17px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  font-size: 17px;
  font-weight: normal;
  line-height: 14px;
  margin-left: 4px;
}
.credit-value.green-icon {
  color: #0c6f55;
}
.credit-value.green-icon .credit-icon {
  background-color: #0c6f55;
  color: #fff;
  position: relative;
  line-height: 14px;
}
.credit-value.red-icon {
  color: #981830;
}
.credit-value.red-icon .credit-icon {
  background-color: #981830;
  color: #fff;
  position: relative;
  line-height: 14px;
}

.readmore-listing {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(#ffffff)
  );
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  height: 47px;
}

/* statistics page css End here  */
/*billing tab css start */
.billing-inner-block .info-card-header {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.billing-inner-block .info-card-header p {
  color: #0d1829;
  font-size: 24px;
  letter-spacing: 0.24px;
  line-height: 32px;
  font-weight: normal;
}
.billing-inner-block .info-card-header .card-btn-block {
  margin-left: auto;
  width: auto;
}
.billing-inner-block .info-card-header .card-btn-block .text-btn {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #f5f5f9;
}

.billing-inner-block .info-block {
  margin-bottom: 32px;
  padding: 0;
}
.billing-inner-block .info-block p span {
  color: #414766;
  font-size: 14px;
  line-height: 24px;
}
.billing-inner-block .info-block label {
  color: #797f99;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
  text-transform: initial;
  margin-bottom: 8px;
}

.billing-inner-block .payment-wrap-ui {
  margin-bottom: 40px;
}

.line-heading {
  border-top: 1px solid #d5d7e4;
  margin-bottom: 5px;
}
.line-heading label {
  position: relative;
  background: #fff;
  top: -15px;
  display: inline-block;
  padding: 0 8px 0 0;
  color: #797f99;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 20px;
  margin-bottom: 0;
}

.features-listing ul {
  margin-bottom: 10px;
}
.features-listing ul li {
  display: inline-block;
  width: 49%;
  padding-left: 32px;
  position: relative;
}
.features-listing ul li:after {
  position: absolute;
  content: '';
  background: url('assets/images/ic-check.svg') no-repeat;
  width: 22px;
  height: 22px;
  background-size: 22px;
  top: 0;
  left: 0;
}

.features-listing .text-btn {
  font-size: 14px;
  font-weight: 600;
}

.heading-ui .inner-title {
  display: block;
}

.heading-ui .title2 {
  color: #797f99;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-bottom: 12px;
  display: block;
}

.payment-info-wrapper .head-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #d5d7e4;
  padding: 16px 0;
}
.payment-info-wrapper .head-block .card-info:last-child {
  width: 20%;
}
.payment-info-wrapper .head-block .card-info.card-type {
  color: #0d1021;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.payment-info-wrapper .head-block .card-info.card-type .ic-visa {
  display: inline-block;
  margin-right: 8px;
}
.payment-info-wrapper .head-block .card-info.card-expiry {
  color: #0d1021;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.payment-info-wrapper .info-card-content {
  padding-bottom: 10px;
}

.billing-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 20px;
}
.billing-info div {
  width: 33.33%;
}
.billing-info div:last-child {
  width: 20%;
  text-align: right;
}
.billing-info label {
  color: #797f99;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-bottom: 16px;
  display: block;
}
.billing-info span {
  color: #0d1021;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: block;
  margin-bottom: 4px;
}
.billing-info p {
  color: #0d1021;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.payment-history-wrapper {
  background: #fff;
  border-radius: 12px;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
}
.payment-history-wrapper table {
  width: 100%;
}
.payment-history-wrapper table thead {
  background-color: #f5f5f9;
  border-radius: 0 0 12px 12px;
}
.payment-history-wrapper table thead th {
  color: #797f99;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 16px;
  padding: 12px 20px;
}
.payment-history-wrapper table tbody tr {
  border-bottom: 1px solid #f5f5f9;
}
.payment-history-wrapper table tbody td {
  padding: 13px 20px;
  color: #0d1021;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.pdf-btn {
  color: #3b53d1;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-align: right;
}
.pdf-btn span {
  padding-right: 8px;
}

.nobackground {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.nobackground .head_block {
  background-color: transparent;
  padding: 0px 0 20px;
  position: relative;
  top: 0;
}
@media only screen and (max-width: 599px) {
  .nobackground .head_block {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .nobackground .head_block .righ_block {
    margin-top: 10px;
  }
}
.nobackground .cstm-select.date-sorting .bootstrap-select button {
  background: none !important;
}

.member-info-card {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.4s all ease;
  -o-transition: 0.4s all ease;
  transition: 0.4s all ease;
  margin-bottom: 30px;
}
.member-info-card:hover {
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 8px 12px 0 rgba(13, 16, 33, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 8px 12px 0 rgba(13, 16, 33, 0.12);
}
.member-info-card .profile-ui {
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(13, 16, 33, 0.12);
  box-shadow: inset 0 1px 4px 0 rgba(13, 16, 33, 0.12);
  width: 64px;
  height: 64px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 64px;
  flex: 0 0 64px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 12px;
}
.member-info-card .profile-ui img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.member-info-card .member-info-ui {
  width: 100%;
}
.member-info-card .member-info-ui .username {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 4px;
}
.member-info-card .member-info-ui .username p {
  color: #0d1021;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}
.member-info-card .member-info-ui .username .tag {
  line-height: 20px;
  font-size: 11px;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  padding: 0 10px;
  height: 20px;
}
.member-info-card .member-info-ui .info-text {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-bottom: 8px;
  display: block;
}
.member-info-card .member-info-ui .desc {
  margin-bottom: 24px;
  color: #242946;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.member-info-card .member-info-ui .btn-blocks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.member-info-card .member-info-ui .btn-blocks a {
  color: #595f79;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 18px;
  display: inline-block;
  padding: 0 15px 0 0;
}
.member-info-card .member-info-ui .btn-blocks a.edit-btn {
  color: #3b53d1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 18px;
  position: relative;
}

.member-info-card .member-info-ui .btn-blocks a.remove-btn {
  color: #595f79;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 18px;
  padding-right: 0;
}
.member-info-card .member-info-ui .btn-blocks a.remove-btn:hover {
  text-decoration: underline;
}
.member-info-card .member-info-ui .btn-blocks .right-ui {
  margin-left: auto;
}
.member-info-card .member-info-ui .btn-blocks .date-ui {
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0.12px;
  line-height: 14px;
  text-align: right;
}

.current-plan {
  position: relative;
}
.current-plan .icon {
  position: relative;
  top: 0px;
  margin-right: 12px;
  display: inline-block;
}

.view-member-modal .profile-detail .info-container .left-wrap {
  width: 124px;
}

.view-member-modal .profile-detail .info-container .right-wrap {
  width: calc(100% - 130px);
}

.view-member-modal .date-ui {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 24px;
}

.credit-pack-list .heading-ui {
  color: #797f99;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.72px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 18px;
}

.credit-pack-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.credit-pack-list ul li {
  margin-right: 20px;
  position: relative;
  margin-bottom: 20px;
}
.credit-pack-list ul li:nth-child(3n + 3) {
  margin-right: 0;
}
.credit-pack-list ul li:hover .credit-pack-box {
  border: 1px solid #414766;
}
.credit-pack-list ul li .credit-pack-box {
  height: 137px;
  width: 145px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 16px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.credit-pack-list ul li label,
.credit-pack-list ul li .radio-btn {
  margin-bottom: 0;
}
.credit-pack-list ul li input:checked + .credit-pack-box {
  border-color: #3b53d1;
}
.credit-pack-list ul li input:checked + .credit-pack-box .radio-icon {
  background: -webkit-linear-gradient(309.68deg, #5369df 0%, #3b53d1 100%);
  background: -o-linear-gradient(309.68deg, #5369df 0%, #3b53d1 100%);
  background: linear-gradient(140.32deg, #5369df 0%, #3b53d1 100%);
  border: 1px solid rgba(83, 105, 223, 0.12);
  -webkit-box-shadow: inset 0 1px 1px 0 rgba(13, 16, 33, 0.24);
  box-shadow: inset 0 1px 1px 0 rgba(13, 16, 33, 0.24);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  width: 23px;
  height: 23px;
}
.credit-pack-list ul li input:checked + .credit-pack-box .radio-icon:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.credit-pack-list ul li .credit-pack-box .radio-icon {
  position: absolute;
  right: 16px;
  height: 23px;
  width: 23px;
}
.credit-pack-list ul li .credit-pack-box .radio-icon:before {
  background: url(assets/images/ic_check_shadow.svg) no-repeat;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 23px;
  height: 23px;
  z-index: 1;
  background-size: 15px;
  background-position: center;
  left: -1px;
  top: -1px;
}
.credit-pack-list ul li .credit-pack-box .content-ui .credit-text {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 8px;
  display: block;
}
.credit-pack-list ul li .credit-pack-box .content-ui p {
  color: #0d1021;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: -0.32px;
  line-height: 40px;
  margin-bottom: 18px;
}
.credit-pack-list ul li .credit-pack-box .content-ui .price-ui {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.add-credit-wrapper .modal-dialog {
  max-width: 520px;
}
.add-credit-wrapper .modal-dialog .credit-btn {
  color: #3b53d1;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 18px;
  text-align: center;
  padding: 7px 12px;
  border-radius: 6px;
  background-color: #eaedfb;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  margin-right: 32px;
}
.add-credit-wrapper .modal-dialog .credit-btn:hover {
  background-color: #dbe1fb;
}

.radio-btn.custom-credit-box {
  text-align: center;
  cursor: pointer;
}
.radio-btn.custom-credit-box.hide {
  display: none;
}
.radio-btn.custom-credit-box .credit-pack-box {
  cursor: pointer;
}
.radio-btn.custom-credit-box .credit-pack-box figure {
  margin-bottom: 9px;
}
.radio-btn.custom-credit-box .credit-pack-box .price-ui {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 4px;
}
.radio-btn.custom-credit-box .credit-pack-box .credit-text {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}

.radio-btn.select-credit-point {
  display: none;
}
.radio-btn.select-credit-point.active {
  display: block;
}
.radio-btn.select-credit-point .cstm-select {
  margin-top: 14px;
  margin-bottom: 10px;
}
.radio-btn.select-credit-point .cstm-select .dropdown-menu {
  width: 100%;
}
.radio-btn.select-credit-point .cstm-select .dropdown-menu ul li {
  margin: 0;
}
.radio-btn.select-credit-point .cstm-select .show > .btn-light.dropdown-toggle {
  border: 1px solid #d5d7e4 !important;
}
.radio-btn.select-credit-point .bootstrap-select {
  width: 113px !important;
}
.radio-btn.select-credit-point .bootstrap-select button {
  border: 1px solid #d5d7e4;
  border-radius: 6px;
  background-color: #ffffff;
  height: 41px;
  line-height: 41px;
  width: 100%;
  padding: 0 10px;
}
.radio-btn.select-credit-point .bootstrap-select button:focus {
  border: 1px solid #d5d7e4 !important;
  outline: none !important;
}
.radio-btn.select-credit-point .bootstrap-select button:after {
  background: url('assets/images/ic_arrow_down.svg') no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0;
  position: absolute;
  right: 10px;
  border: none;
}
.radio-btn.select-credit-point input[type='radio'] {
  height: 43px;
}

.best-value-tag {
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  border-radius: 4px;
  display: inline-block;
  position: absolute;
  top: -13px;
  left: 0;
  right: 0;
  width: max-content;
  margin: auto;
  z-index: 1;
}
.best-value-tag:before {
  background: -webkit-linear-gradient(305.46deg, #f6f7ff 0%, #f6f7ff 53.13%, #fff2f3 100%);
  background: -o-linear-gradient(305.46deg, #f6f7ff 0%, #f6f7ff 53.13%, #fff2f3 100%);
  background: linear-gradient(144.54deg, #f6f7ff 0%, #f6f7ff 53.13%, #fff2f3 100%);
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 4px;
}
.best-value-tag .ic-bv-tag {
  margin-right: 2px;
}
.best-value-tag .graditent-text {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.delete-organization-wrapper .modal-dialog {
  width: 100%;
  max-width: 768px;
}

.delete-organization-wrapper .delete-inner-ui {
  padding: 0 24px 0px;
}
@media only screen and (max-width: 480px) {
  .delete-organization-wrapper .delete-inner-ui {
    padding: 0 10px 0;
  }
}
.delete-organization-wrapper .delete-inner-ui p {
  color: #414766;
  font-size: 18px;
  letter-spacing: 0.16px;
  line-height: 28px;
}
.delete-organization-wrapper .delete-inner-ui p span {
  font-weight: 700;
}

.delete-organization-wrapper .alert-wrap {
  border-radius: 8px;
  background-color: #ffe8e8;
  padding: 20px 30px 20px 56px;
  margin-bottom: 24px;
  position: relative;
}
.delete-organization-wrapper .alert-wrap .ic-alert {
  position: absolute;
  left: 20px;
  top: 20px;
}
.delete-organization-wrapper .alert-wrap label {
  color: #981830;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin-bottom: 16px;
}
.delete-organization-wrapper .alert-wrap p {
  color: #242946;
  font-size: 18px;
  letter-spacing: 0.16px;
  line-height: 28px;
}
.delete-organization-wrapper .alert-wrap p span {
  font-weight: 500;
}

.delete-organization-wrapper .delete-tag {
  height: 33px;
  border: 1px dashed #d5d7e4;
  border-radius: 6px;
  color: #414766;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 27px;
  padding: 3px 9px;
  margin-top: 14px;
  margin-bottom: 15px;
  display: inline-block;
}

.delete-organization-wrapper .form-fields .form-group input {
  width: 100%;
  height: 49px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #f5f5f9;
  padding: 0 10px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

@media only screen and (max-width: 599px) {
  .delete-organization-wrapper .modal-footer .btn6 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-top: 10px;
  }
  .delete-organization-wrapper .modal-footer .btn6,
  .delete-organization-wrapper .modal-footer .btn5 {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 480px) {
  .delete-organization-wrapper .modal-footer {
    padding: 20px 10px 15px;
  }
}

.manage-plan-modal .footer-manage-list a {
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.manage-plan-modal .footer-manage-list a:hover {
  color: #3b53d1;
}

.unsubscribe-ui-block p .link-text {
  color: #3b53d1;
  font-weight: 500;
}

.unsubscribe-ui-block p strong {
  font-weight: 500;
}

.unsubscribe-ui-block .alert-wrap {
  border-radius: 10px;
  background-color: #ffe8e8;
  padding: 20px 20px 20px 60px;
  position: relative;
  margin-bottom: 40px;
  margin-top: 30px;
}
.unsubscribe-ui-block .alert-wrap .ic-alert {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  position: absolute;
  left: 20px;
  top: 20px;
}
.unsubscribe-ui-block .alert-wrap .ic-alert img {
  width: 100%;
  vertical-align: top;
}
.unsubscribe-ui-block .alert-wrap ul {
  padding: 0;
  margin: 0;
}
.unsubscribe-ui-block .alert-wrap ul li {
  color: #242946;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.14px;
  line-height: 36px;
  position: relative;
}
/*.unsubscribe-ui-block .alert-wrap ul li:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  background:url("assets/images/ic-cross-ui.svg") no-repeat;
  left: -36px;
  top: 4px;
}*/
.unsubscribe-ui-block .alert-wrap ul li strong {
  font-weight: 500;
}

.unsubscribe-ui-block .button-block-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.unsubscribe-ui-block .button-block-wrap .right-ui {
  margin-left: auto;
}
.unsubscribe-ui-block .button-block-wrap .right-ui .btn3 {
  margin-right: 20px;
}

.unsubscribe-modal-wrapper .modal-footer {
  border-radius: 0 0 12px 12px;
  background-color: #f5f5f9;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 24px;
}
.unsubscribe-modal-wrapper .modal-footer .contact-support {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.unsubscribe-modal-wrapper .modal-footer .contact-support .left-ui {
  margin-right: auto;
  width: 55%;
}
.unsubscribe-modal-wrapper .modal-footer .contact-support .left-ui h6 {
  color: #242946;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin-bottom: 7px;
}
.unsubscribe-modal-wrapper .modal-footer .contact-support .left-ui p {
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
}
.unsubscribe-modal-wrapper .modal-footer .contact-support .right-ui .contact-support-btn {
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 24px;
  color: #414766;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 18px;
  text-align: center;
  height: 48px;
  line-height: 48px;
}
.unsubscribe-modal-wrapper
  .modal-footer
  .contact-support
  .right-ui
  .contact-support-btn
  .ic-call-ui {
  margin-right: 12px;
}

.join-organization-inner.join-organization-page {
  padding: 40px 24px 0;
  max-width: 670px;
  width: 100%;
  margin: auto;
}
.join-organization-inner.join-organization-page h4 {
  color: #0d1021;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 16px;
}
.join-organization-inner.join-organization-page .divider-ui span {
  background: #f6f3fa;
}
.join-organization-inner.join-organization-page .divider-ui:before {
  background: #d5d7e4;
}

.unsubscribe-type-list {
  padding-bottom: 0;
}
.unsubscribe-type-list p {
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
  margin-bottom: 32px;
}
.unsubscribe-type-list ul li {
  margin-bottom: 20px;
}
.unsubscribe-type-list ul li .radio-text {
  color: #242946;
  font-size: 16px;
  letter-spacing: 0.18px;
  line-height: 20px;
}
.unsubscribe-type-list ul li:last-child {
  margin-bottom: 0;
}
.unsubscribe-type-list .other-info-ui textarea {
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #f5f5f9;
  height: 79px;
  width: 100%;
  padding: 12px 16px 16px;
  color: #242946;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 20px;
  resize: none;
}
.unsubscribe-type-list .other-info-ui textarea::-webkit-input-placeholder {
  color: #797f99;
}
.unsubscribe-type-list .other-info-ui textarea:focus {
  outline: none !important;
  border-color: #c7c9d6;
}

.custom-plan-ui {
  padding: 0 24px 0;
}
.custom-plan-ui .form-group textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #f5f5f9;
  padding: 12px 16px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  resize: none;
}
.custom-plan-ui .form-group textarea::-webkit-input-placeholder {
  color: #797f99;
}
.custom-plan-ui .form-group textarea:focus {
  outline: none !important;
  border-color: #c7c9d6;
}

.join-invite-msg {
  width: 100%;
  max-width: 830px;
  margin: auto;
  margin-top: 10px;
}
.join-invite-msg .hide {
  display: none;
}
.join-invite-msg .invite-notification-ui {
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  display: inline-block;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 16px;
  margin-bottom: 12px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.join-invite-msg .invite-notification-ui .left-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.join-invite-msg .invite-notification-ui .right-ui {
  margin-left: auto;
}
.join-invite-msg .invite-notification-ui .right-ui .time-ui {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-right: 30px;
}
.join-invite-msg .invite-notification-ui .right-ui .btn-ui {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.11px;
  line-height: 18px;
  text-align: center;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.join-invite-msg .invite-notification-ui .right-ui .btn-ui:hover {
  text-decoration: underline;
}
.join-invite-msg .invite-notification-ui .right-ui .btn-ui .icon {
  margin-right: 5px;
  position: relative;
  top: -1px;
}
.join-invite-msg .invite-notification-ui figure {
  height: 32px;
  width: 32px;
  border-radius: 100%;
  overflow: hidden;
  margin-bottom: 0;
  margin-right: 12px;
}
.join-invite-msg .invite-notification-ui figure img {
  width: 100%;
}
.join-invite-msg .divider-ui {
  width: 100%;
  max-width: 86%;
  margin: auto;
  display: inherit;
}
.join-invite-msg .divider-ui.hide {
  display: none;
}
.join-invite-msg .divider-ui:before {
  background: #d5d7e4;
}
.join-invite-msg .divider-ui span {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.11px;
  line-height: 18px;
  text-align: center;
  background: #f4f3f9;
}
.join-invite-msg .show-more {
  cursor: pointer;
}

.activity-wrapper {
  margin-bottom: 50px;
}
.activity-wrapper table td {
  min-width: 180px;
}
.activity-wrapper table td.spent-credit .credit-value {
  text-align: left;
}

.intro-title-wrap {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}
.intro-title-wrap h6 {
  color: #0d1021;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 24px;
  margin-bottom: 8px;
}
.intro-title-wrap p {
  color: #797f99;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.form-wrapper {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
}
@media only screen and (max-width: 767px) {
  .form-wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.form-wrapper .form-group {
  padding: 12px 20px;
  margin-bottom: 0;
  position: relative;
  width: 100%;
}
.form-wrapper .form-group:after {
  background-color: #d5d7e4;
  height: 32px;
  width: 1px;
  content: '';
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .form-group:after {
    width: 95%;
    left: 0;
    right: 0;
    height: 1px;
    bottom: 0;
    top: inherit;
    margin: auto;
    background: #ebecef;
  }
}
.form-wrapper .form-group:nth-last-child(2):after,
.form-wrapper .form-group:last-child:after {
  display: none;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .form-group:nth-last-child(2):after,
  .form-wrapper .form-group:last-child:after {
    display: block;
  }
}
.form-wrapper .form-group:last-child {
  width: 75px;
  padding: 12px;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .form-group:last-child {
    width: 100%;
  }
  .form-wrapper .form-group:last-child:after {
    display: none;
  }
}
.form-wrapper .form-group label {
  display: block;
  color: #414766;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 16px;
  margin-bottom: 4px;
}
.form-wrapper .form-group input {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #242946;
  font-size: 16px;
  letter-spacing: 0.24px;
  line-height: 24px;
  width: 100%;
}
.form-wrapper .form-group .cta-search-btn {
  width: 44px;
  height: 44px;
  line-height: 43px;
  border-radius: 8px;
  background: #3b53d1;
  display: block;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .form-group .cta-search-btn {
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 10px;
  }
}
.form-wrapper .form-group .cta-search-btn:hover {
  background: #2b40b1;
  opacity: 0.95;
}
.form-wrapper .form-group .cta-search-btn .search-text {
  display: none;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .form-group .cta-search-btn .search-text {
    display: inline-block;
    padding-left: 5px;
    font-weight: bold;
  }
}

.search-content-wrapper .info-text {
  color: #797f99;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.21px;
  line-height: 16px;
  margin-bottom: 40px;
}

.search-content-wrapper .info-content {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  padding: 40px 40px 0;
  margin: 0px 0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.search-content-wrapper .info-content figure {
  margin-right: 30px;
  width: 76px;
  min-width: 76px;
}
.search-content-wrapper .info-content figure img {
  width: 100%;
}
.search-content-wrapper .info-content .search-content-block .inner-content-ui {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.search-content-wrapper .info-content .search-content-block h5 {
  color: #242946;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 16px;
  font-weight: normal;
}
.search-content-wrapper .info-content .search-content-block p {
  color: #595f79;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.24px;
  line-height: 24px;
  margin-bottom: 24px;
}

.need-help-block {
  text-align: left;
  border-radius: 12px;
  padding: 0px 24px;
  margin-bottom: 30px;
  position: -webkit-sticky;
  position: sticky;
  top: 120px;
}
@media only screen and (max-width: 991px) {
  .need-help-block {
    padding: 0;
  }
}
.need-help-block .title {
  color: #242946;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 20px;
}
.need-help-block .desc-text {
  color: #595f79;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.24px;
  line-height: 24px;
  margin-bottom: 32px;
}
.need-help-block .text-btn {
  display: block;
  text-align: left;
  margin-bottom: 10px;
}
.need-help-block .text-btn:last-child {
  margin-bottom: 0;
}

/*text radio btn */
.text-radio-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.text-radio-btn .radio-ui {
  margin: 0 6px;
  position: relative;
  overflow: hidden;
}
.text-radio-btn .radio-ui input {
  opacity: 0;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.text-radio-btn .radio-ui span {
  color: #a9afc6;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: bold;
  text-transform: uppercase;
}
.text-radio-btn .radio-ui input:checked + span {
  color: #3b53d1;
}

.form-wrapper .form-group .quantity-btn-ui input {
  width: 40px;
  text-align: center;
  color: #0d1021;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
}

.quantity-btn-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.quantity-btn-ui .quty-btn {
  height: 24px;
  width: 24px;
  border-radius: 4px;
  background-color: #f7f7fa;
  line-height: 23px;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.quantity-btn-ui .quty-btn:hover {
  border-radius: 4px;
  background-color: rgba(83, 105, 223, 0.12);
}
.quantity-btn-ui input {
  width: 40px;
}

/* people result list  */
.result-listing .info-content {
  display: block;
  padding: 20px;
  margin-bottom: 20px;
}

.result-top-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .result-top-ui {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.result-top-ui .result-left-ui {
  width: 70%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .result-top-ui .result-left-ui {
    width: 100%;
  }
}
.result-top-ui .result-left-ui figure {
  width: 60px;
  min-width: 60px;
  height: 60px;
  display: block;
  margin-right: 16px;
  border-radius: 100%;
  overflow: hidden;
  -webkit-box-shadow: inset 0 1px 4px 0 rgba(13, 16, 33, 0.12);
  box-shadow: inset 0 1px 4px 0 rgba(13, 16, 33, 0.12);
  margin-bottom: 0;
}
.result-top-ui .result-left-ui .content-ui {
  width: calc(100% - 90px);
}
.result-top-ui .result-left-ui .content-ui label {
  color: #0d1021;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 4px;
}
.result-top-ui .result-left-ui .content-ui p {
  color: #242946;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 8px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: block;
  word-break: break-all;
}
.result-top-ui .result-left-ui .content-ui span {
  color: #595f79;
  display: block;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
}
.result-top-ui .result-right-ui {
  width: 30%;
  border-left: 1px solid #d5d7e4;
  padding-left: 24px;
  padding-bottom: 10px;
}
@media only screen and (max-width: 991px) {
  .result-top-ui .result-right-ui {
    padding-left: 10px;
  }
}
@media only screen and (max-width: 991px) and (max-width: 767px) {
  .result-top-ui .result-right-ui {
    width: 100%;
    padding-left: 75px;
    margin-top: 20px;
    border-left: none;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 991px) and (max-width: 767px) and (max-width: 599px) {
  .result-top-ui .result-right-ui {
    padding-left: 0;
  }
}
.result-top-ui .result-right-ui ul {
  padding-left: 30px;
  margin-bottom: 0;
}
.result-top-ui .result-right-ui ul li {
  color: #414766;
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 18px;
  margin-bottom: 0;
}
.result-top-ui .result-right-ui .more-links {
  padding-left: 30px;
  margin-top: 4px;
  color: #3b53d1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 16px;
}
.result-top-ui .result-right-ui .contact-list {
  margin-bottom: 14px;
  position: relative;
}
.result-top-ui .result-right-ui .contact-list:before {
  position: absolute;
  content: '';
  background: url(assets/images/ic-phone-call.png) no-repeat;
  width: 17px;
  height: 17px;
  left: 0;
  top: 2px;
}
.result-top-ui .result-right-ui .email-listing {
  margin-bottom: 0px;
  position: relative;
}
.result-top-ui .result-right-ui .email-listing:before {
  position: absolute;
  content: '';
  background: url(assets/images/ic-mail-icon.png) no-repeat;
  width: 20px;
  height: 16px;
  left: 0;
  top: 2px;
}

.result-count {
  color: #242946;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 16px;
}

.result-bottom-ui {
  padding-left: 75px;
  margin-top: -35px;
}
@media only screen and (max-width: 767px) {
  .result-bottom-ui {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) and (max-width: 599px) {
  .result-bottom-ui {
    padding-left: 0;
  }
}
.result-bottom-ui .text-btn {
  color: #495dd0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-align: center;
}
.result-bottom-ui .text-btn .link-icon {
  padding-left: 5px;
  display: inline-block;
}
.result-bottom-ui .btn3 {
  margin-right: 20px;
  height: 32px;
  line-height: 32px;
  border-radius: 8px;
  color: #495dd0;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  text-align: center;
}
.result-bottom-ui .btn3 .plus-icon {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  position: relative;
  top: -1px;
}

.show-contact-ui {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 32px;
  height: 32px;
  text-align: center;
  margin-right: 20px;
  border-radius: 8px;
}
.show-contact-ui .credit-point {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 18px;
  text-align: center;
  display: inline-block;
  padding-left: 8px;
  margin-left: 8px;
  position: relative;
}
.show-contact-ui .credit-point:before {
  width: 1px;
  height: 18px;
  background: rgba(255, 255, 255, 0.2);
  left: 0;
  position: absolute;
  content: '';
  top: 0;
}
.show-contact-ui .credit-icon {
  height: 16px;
  width: 16px;
  border-radius: 50px;
  background-color: #ffffff;
  line-height: 14px;
  font-weight: 400;
  color: #5369df;
  font-size: 16px;
  display: inline-block;
  font-style: initial;
}

.workflow-detail-header .cstm-tabs-ui .nav-tabs li a.active {
  background: #fff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  border-radius: 6px;
  color: #3b53d1;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.select-user-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.select-user-ui p {
  color: #242946;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-right: 16px;
}
.select-user-ui .cstm-select .bootstrap-select {
  min-width: 65px !important;
  width: auto !important;
}
.select-user-ui .cstm-select .bootstrap-select button {
  height: 40px;
  width: 65px;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  color: #3b53d1;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 24px;
  text-align: center;
}
.select-user-ui .cstm-select .bootstrap-select button:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.select-user-ui .cstm-select .bootstrap-select button:after {
  background: url(assets/images/ic_arrow_down.svg) no-repeat;
  width: 10px;
  height: 10px;
  border: none;
  margin: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.select-user-ui .cstm-select .dropdown-menu {
  border: none;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12),
    0 16px 32px 0 rgba(20, 24, 47, 0.24);
}
.select-user-ui .cstm-select .dropdown-menu .inner ul {
  max-height: 160px;
  overflow-y: auto;
}

.subscription-tabs {
  display: inline-block;
  margin-top: 24px;
}
.subscription-tabs ul {
  border-radius: 8px;
  background-color: rgba(213, 215, 228, 0.24);
  -webkit-box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  box-shadow: inset 0 1px 3px 0 rgba(13, 16, 33, 0.12);
  padding: 4px;
}
.subscription-tabs ul li {
  margin-bottom: 0;
}
.subscription-tabs ul li a {
  padding: 8px 30px;
  min-width: 230px;
  display: inline-block;
  text-align: center;
  border-radius: 6px;
  color: #797f99;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.24px;
  line-height: 24px;
  text-align: center;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
@media only screen and (max-width: 599px) {
  .subscription-tabs ul li a {
    padding: 8px 20px;
    min-width: 130px;
  }
}
.subscription-tabs ul li a span {
  color: #099e69;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.48px;
  line-height: 14px;
  margin-left: 12px;
  display: inline-block;
}
.subscription-tabs ul li a.active {
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 1px 3px 0 rgba(20, 24, 47, 0.12);
  color: #3b53d1;
}

.subscription-tab-wrapper {
  margin-bottom: 40px;
}
.subscription-tab-wrapper .tab-content {
  margin-top: 40px;
}
.subscription-tab-wrapper .subcription-plan-ui {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  width: 100%;
  margin-right: 30px;
  padding: 24px;
}
@media only screen and (max-width: 767px) {
  .subscription-tab-wrapper .subcription-plan-ui {
    margin-top: 30px;
  }
}
.subscription-tab-wrapper .subcription-plan-ui:last-child {
  margin-right: 0;
}
.subscription-tab-wrapper .subcription-plan-ui .title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 6px;
}
.subscription-tab-wrapper .subcription-plan-ui .title.blue {
  color: #3b53d1;
}
.subscription-tab-wrapper .subcription-plan-ui .title.pink {
  color: #e545b1;
}
.subscription-tab-wrapper .subcription-plan-ui .subtitle {
  color: #797f99;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 20px;
}
.subscription-tab-wrapper .subcription-plan-ui .price-text {
  color: #797f99;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}
.subscription-tab-wrapper .subcription-plan-ui .price-text span {
  color: #242946;
  font-size: 40px;
  letter-spacing: -0.4px;
  line-height: 40px;
  margin-right: 13px;
}
.subscription-tab-wrapper .subcription-plan-ui .credit-text-ui {
  color: #414766;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: 500;
}
.subscription-tab-wrapper .subcription-plan-ui .credit-text-ui span {
  font-weight: normal;
}
.subscription-tab-wrapper .subcription-plan-ui .btn1 {
  width: 100%;
  text-align: center;
  height: 48px;
  line-height: 48px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.24px;
  text-align: center;
}
.subscription-tab-wrapper .subcription-plan-ui .btn2 {
  width: 100%;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #3b53d1;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.24px;
  text-align: center;
}
.subscription-tab-wrapper .subcription-plan-ui .permissions {
  margin-top: 40px;
}
.subscription-tab-wrapper .subcription-plan-ui .permissions li {
  color: #414766;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 25px;
  padding-left: 32px;
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .subscription-tab-wrapper .subcription-plan-ui .permissions li {
    font-size: 16px;
    line-height: 20px;
  }
}
.subscription-tab-wrapper .subcription-plan-ui .permissions li:before {
  position: absolute;
  content: '';
  background: url(assets/images/ic-check.svg) no-repeat;
  width: 22px;
  height: 22px;
  background-size: 22px;
  top: 0;
  left: 0;
}
.subscription-tab-wrapper .subcription-plan-ui .permissions li.highlight-ui {
  font-weight: 600;
}
.subscription-tab-wrapper .subcription-plan-ui .permissions li.highlight-ui:before {
  background: url(assets/images/ic-check-active.svg) no-repeat;
  width: 23px;
  height: 23px;
}
.subscription-tab-wrapper .subcription-plan-ui .permissions li span {
  color: #797f99;
  font-size: 14px;
  line-height: 24px;
}

.onboarding-wrapper {
  background: #fff;
  height: 100vh;
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .onboarding-wrapper .top-header {
    background: #fff;
  }
}
@media only screen and (max-width: 599px) {
  .onboarding-wrapper {
    height: auto;
  }
}
.onboarding-wrapper:before {
  background: url('assets/images/mainbg.jpg');
  content: '';
  position: absolute;
  left: 0;
  width: 28%;
  height: 100%;
  top: 0;
  z-index: -1;
}
@media only screen and (min-width: 2000px) and (max-width: 2500px) {
  .onboarding-wrapper:before {
    width: 32%;
  }
}
@media only screen and (min-width: 2500px) {
  .onboarding-wrapper:before {
    width: 35%;
  }
}
@media only screen and (max-width: 1200px) {
  .onboarding-wrapper:before {
    display: none;
  }
}
.onboarding-wrapper header {
  background: transparent;
}

.onboard-content-ui {
  /*padding-top:148px;*/
  padding-top: 68px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@media only screen and (max-width: 1400px) {
  .onboard-content-ui .offset-xl-1 {
    margin-left: 0;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media only screen and (max-width: 991px) {
  .onboard-content-ui {
    padding-top: 30px;
  }
}
.onboard-content-ui .left-ui {
  margin-top: 52px;
}
.onboard-content-ui .left-ui h4 {
  color: #0d1829;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.21px;
  line-height: 28px;
  margin-bottom: 16px;
}
.onboard-content-ui .left-ui p {
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 24px;
  max-width: 270px;
}
.onboard-content-ui .left-ui .email-verify-listing {
  margin-bottom: 40px;
}
.onboard-content-ui .left-ui .email-verify-listing P {
  font-size: 15px;
}

.video-wrapper {
  margin-top: 40px;
  text-align: left;
}
@media only screen and (max-width: 991px) {
  .video-wrapper {
    display: none;
  }
}
.video-wrapper span {
  display: block;
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  text-align: left;
  margin-bottom: 10px;
}
.video-wrapper .video-ui {
  width: 240px;
  height: 176px;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
}
.video-wrapper .video-ui .play-icon {
  background-color: rgba(255, 255, 255, 0.26);
  -webkit-box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12);
  box-shadow:
    0 1px 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1),
    0 4px 8px 0 rgba(20, 24, 47, 0.1),
    0 12px 16px 0 rgba(20, 24, 47, 0.12);
  width: 62px;
  height: 62px;
  line-height: 62px;
  border-radius: 100%;
  text-align: center;
  position: absolute;
  bottom: 24px;
  left: 24px;
  margin: 0;
  cursor: pointer;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.video-wrapper .video-ui .play-icon img {
  position: relative;
  right: -2px;
}

.onboard-content {
  padding: 0 20px 0 60px;
}
@media only screen and (max-width: 991px) {
  .onboard-content {
    padding: 0 15px;
  }
}
.onboard-content .title {
  color: #0d1829;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 24px;
}
@media only screen and (max-width: 1200px) {
  .onboard-content .title {
    font-size: 26px;
    line-height: 36px;
  }
}
.onboard-content .title span {
  display: inline-block;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#4966ff+0,da47c4+50,ff4083+100 */
  background: #4966ff;
  /* Old browsers */
  background: -moz-linear-gradient(left, #4966ff 0%, #da47c4 50%, #ff4083 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #4966ff 0%, #da47c4 50%, #ff4083 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4966ff),
    color-stop(50%, #da47c4),
    to(#ff4083)
  );
  background: -o-linear-gradient(left, #4966ff 0%, #da47c4 50%, #ff4083 100%);
  background: linear-gradient(to right, #4966ff 0%, #da47c4 50%, #ff4083 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4966ff', endColorstr='#ff4083',GradientType=1 );
  /* IE6-9 */
  -webkit-animation: flow 4s ease-in-out infinite;
  animation: flow 4s ease-in-out infinite;
  background-size: 200%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.onboard-content p {
  color: #595f79;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 30px;
}
.onboard-content p span {
  font-weight: 600;
}
@media only screen and (max-width: 599px) {
  .onboard-content p br {
    display: none;
  }
}

.progress-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .progress-ui {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.progress-ui .progress {
  width: 505px;
  border-radius: 4px;
  background-color: #f5f5f9;
  height: 8px;
}
@media only screen and (max-width: 767px) {
  .progress-ui .progress {
    width: 100%;
  }
}
.progress-ui .progress .progress-bar {
  background: -webkit-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: -o-linear-gradient(309.32deg, #5369df 0%, #4458c9 100%);
  background: linear-gradient(140.68deg, #5369df 0%, #4458c9 100%);
  border-radius: 6px;
}
.progress-ui .progress-title {
  margin-left: 33px;
  color: #242946;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .progress-ui .progress-title {
    margin-left: 0;
    margin-top: 10px;
  }
}

.onboard-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 45px;
}
@media only screen and (max-width: 599px) {
  .onboard-steps {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.onboard-steps .steps {
  width: 100%;
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  box-shadow:
    0 2px 4px 0 rgba(20, 24, 47, 0.12),
    0 4px 12px 0 rgba(36, 41, 70, 0.16);
  max-width: 244px;
  margin-right: 24px;
  padding: 20px 20px 0;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.onboard-steps .steps:hover {
  -webkit-box-shadow:
    0 2px 8px 0 rgba(20, 24, 47, 0.16),
    0 4px 16px 0 rgba(36, 41, 70, 0.2);
  box-shadow:
    0 2px 8px 0 rgba(20, 24, 47, 0.16),
    0 4px 16px 0 rgba(36, 41, 70, 0.2);
}
@media only screen and (max-width: 599px) {
  .onboard-steps .steps {
    margin-bottom: 20px;
    max-width: 100%;
    margin-right: 0;
  }
  .onboard-steps .steps p br {
    display: none;
  }
}
.onboard-steps .steps figure {
  margin-bottom: 35px;
  min-height: 42px;
}
.onboard-steps .steps .earn-tag {
  color: #3b53d1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 20px;
  border-radius: 6px;
  background-color: #eff1ff;
  padding: 3px 8px;
  margin-bottom: 12px;
  display: inline-block;
}
.onboard-steps .steps p {
  color: #0d1021;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  max-width: 170px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 599px) {
  .onboard-steps .steps p {
    max-width: 100%;
  }
}
.onboard-steps .steps.credit-earned {
  background: #f5f5f9;
  -webkit-box-shadow: none;
  box-shadow: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.onboard-steps .steps.credit-earned p {
  text-decoration: line-through;
}
.onboard-steps .steps.credit-earned .earn-tag {
  background: #fff;
}
.onboard-steps .steps.credit-earned .button-ui .btn-ui {
  color: rgba(89, 95, 121, 0.64);
}
.onboard-steps .button-ui {
  border-top: 1px solid rgba(213, 215, 228, 0.64);
  padding: 13px 0;
}
.onboard-steps .button-ui .btn-ui {
  color: #595f79;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
}
.onboard-steps .button-ui .btn-ui .icon-ui {
  margin-left: 8px;
  display: inline-block;
}

.copyright {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
}

.onboard-footer {
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0;
  max-width: 1600px;
  margin: auto;
  right: 0;
}
@media only screen and (max-width: 599px) {
  .onboard-footer {
    position: relative;
    bottom: 0;
    padding-top: 30px;
    margin-top: 10px !important;
  }
}
@media only screen and (max-width: 991px) {
  .onboard-footer .order-2 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    padding: 0;
  }
  .onboard-footer .col-lg-3 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    text-align: center;
  }
  .onboard-footer .col-lg-3 .copyright {
    padding: 30px 0;
  }
}

.bottom-button-ui {
  padding: 0 20px 0 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
@media only screen and (max-width: 400px) {
  .bottom-button-ui {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.bottom-button-ui .text-btn {
  color: #3b53d1;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.27px;
  line-height: 24px;
  text-align: center;
  margin-right: 30px;
  padding: 0 20px;
}
@media only screen and (max-width: 400px) {
  .bottom-button-ui .text-btn {
    width: 100%;
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
}
@media only screen and (max-width: 991px) {
  .bottom-button-ui {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 991px) and (max-width: 991px) {
  .bottom-button-ui {
    padding: 0;
  }
}
.bottom-button-ui .btn1 {
  height: 58px;
  line-height: 58px;
  max-width: 173px;
  min-width: 173px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.27px;
  text-align: center;
}
@media only screen and (max-width: 400px) {
  .bottom-button-ui .btn1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
  }
}

.video-ui figure {
  border-radius: 1rem;
  overflow: hidden;
  padding: 0;
  -webkit-transition: -webkit-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: -webkit-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  -o-transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  transition:
    transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0s,
    -webkit-transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.video-ui figure img {
  width: 100%;
}

.video-ui:hover .play-icon {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  cursor: pointer;
  display: inline-block;
}

@media only screen and (max-height: 800px) {
  .onboard-footer {
    position: relative;
    margin-top: 70px;
    bottom: 0;
    margin-bottom: 30px;
  }
  .onboarding-wrapper {
    height: auto;
  }
}

.phone-verifiy-ui {
  padding: 0 24px 18px;
}
.phone-verifiy-ui .phone-info p {
  color: #242946;
  font-size: 18px;
  letter-spacing: 0.16px;
  line-height: 28px;
}
.phone-verifiy-ui .phone-info .number-ui {
  color: #242946;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 28px;
}
.phone-verifiy-ui .phone-info .text-btn {
  display: inline-block;
  padding-left: 15px;
  font-size: 18px;
  line-height: 24px;
}

.code-ui {
  margin-top: 28px;
}
.code-ui label {
  color: #797f99;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 20px;
  margin-bottom: 12px;
}
.code-ui .enter-code-field {
  margin-bottom: 28px;
}
.code-ui .enter-code-field input {
  opacity: 0;
}
.code-ui .enter-code-field input:focus {
  border-color: #5369df;
  outline: none;
  -webkit-box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
  box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
}
@media only screen and (max-width: 767px) {
  .code-ui .enter-code-field input {
    height: 46px;
    width: 46px;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 767px) and (max-width: 599px) {
  .code-ui .enter-code-field input {
    height: 36px;
    width: 36px;
    margin-right: 4px !important;
  }
}
.code-ui .info-text {
  color: #595f79;
  font-size: 18px;
  letter-spacing: 0.16px;
  line-height: 28px;
}
@media only screen and (max-width: 767px) {
  .code-ui .info-text {
    font-size: 15px;
    line-height: 24px;
  }
}
.code-ui .info-text .text-btn {
  margin-left: 6px;
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  .code-ui .info-text .text-btn {
    font-size: 15px;
    line-height: 24px;
  }
}

.kaspr-plugin-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 32px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.kaspr-plugin-ui figure {
  margin-right: 16px;
  margin-bottom: 0;
}
.kaspr-plugin-ui .plugin-info h4 {
  color: #242946;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 28px;
  margin-bottom: 8px;
}
.kaspr-plugin-ui .plugin-info p {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0;
}

.send-invite-ui {
  padding: 0 24px 18px;
}
.send-invite-ui .form-group label {
  color: #242946;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 28px;
  margin-bottom: 15px;
}
.send-invite-ui .form-group textarea {
  height: 103px;
  border: 1px solid #d5d7e4;
  resize: none;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  box-shadow: 0 1px 2px 0 rgba(20, 24, 47, 0.12);
  width: 100%;
  padding: 16px;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 20px;
}
.send-invite-ui .form-group textarea:focus {
  outline: none;
  border-color: #3b53d1;
  -webkit-box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
  box-shadow: 0 0 0 3px rgba(83, 105, 223, 0.16);
}
.send-invite-ui .form-group textarea::-webkit-input-placeholder {
  color: #797f99;
}
.send-invite-ui .info-ui {
  position: relative;
  padding-left: 34px;
  color: #414766;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.28px;
  line-height: 22px;
}
.send-invite-ui .info-ui:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  background: url('assets/images/icon-info.svg') no-repeat;
}

.invite-friends-ui .modal-dialog {
  max-width: 600px;
}

.earned-credit-ui .modal-footer .btn4:hover {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.credit-msg-wrapper {
  padding: 0 24px 24px;
}
.credit-msg-wrapper .cresit-msg-ui {
  position: relative;
  margin-bottom: 32px;
  padding: 3px 15px;
  display: inline-block;
}
.credit-msg-wrapper .cresit-msg-ui:before {
  border-radius: 8px;
  background: -webkit-linear-gradient(305.46deg, #e0e5ff 0%, #fadbff 53.13%, #ffd1b9 100%);
  background: -o-linear-gradient(305.46deg, #e0e5ff 0%, #fadbff 53.13%, #ffd1b9 100%);
  background: linear-gradient(144.54deg, #e0e5ff 0%, #fadbff 53.13%, #ffd1b9 100%);
  display: inline-block;
  padding: 10px 10px;
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  opacity: 0.32;
  top: 0;
  left: 0;
}
.credit-msg-wrapper .cresit-msg-ui p {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 32px;
  background: #4966ff;
  /* Old browsers */
  background: -moz-linear-gradient(left, #4966ff 0%, #da47c4 50%, #ff4083 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #4966ff 0%, #da47c4 50%, #ff4083 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#4966ff),
    color-stop(50%, #da47c4),
    to(#ff4083)
  );
  background: -o-linear-gradient(left, #4966ff 0%, #da47c4 50%, #ff4083 100%);
  background: linear-gradient(to right, #4966ff 0%, #da47c4 50%, #ff4083 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4966ff', endColorstr='#ff4083',GradientType=1 );
  /* IE6-9 */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.credit-msg-wrapper .cresit-msg-ui p span {
  margin-right: 10px;
  display: inline-block;
}
.credit-msg-wrapper p {
  color: #242946;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 40px;
}

.onboarding-wrapper header {
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.onboarding-wrapper header.fixed {
  background: #fff;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.07);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.07);
}

.email-verify-listing {
  margin-top: 40px;
}

/*on board all steps */
.onboard-white-bg {
  background: #fff;
  min-height: 100vh;
}
.onboard-white-bg header {
  background: #fff;
}

.onboard-steps-wrapper .onboard-content {
  padding: 0;
}
.onboard-steps-wrapper .onboard-content .description-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.onboard-steps-wrapper .onboard-content .description-ui p {
  margin-bottom: 0;
}

.recomended-block {
  padding: 0 50px;
}
@media only screen and (max-width: 1400px) {
  .recomended-block {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .recomended-block {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1200px) and (max-width: 991px) {
  .recomended-block {
    padding: 0;
  }
}
.recomended-block .top-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
}
.recomended-block .top-heading p {
  color: #595f79;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
}
.recomended-block .top-heading .total-earned-ui {
  color: #0d1021;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
}

.recomended-tag-block {
  position: relative;
  z-index: 1;
}
.recomended-tag-block:after {
  background: url('assets/images/border-img.png');
  width: 100%;
  height: 1px;
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}
.recomended-tag-block .tag-ui {
  background: #f832a1;
  padding: 5px 12px;
  display: inline-block;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 14px;
  text-transform: uppercase;
  border-radius: 6px;
}

.onboard-step-list table {
  width: 100%;
}
.onboard-step-list table tr {
  border-top: 1px solid #d5d7e4;
}
.onboard-step-list table tr:first-child {
  border-top: none;
}
.onboard-step-list table tr:last-child {
  border-bottom: 1px solid #d5d7e4;
}
.onboard-step-list table td {
  padding: 20px 20px 20px 9px;
}
.onboard-step-list table td:first-child {
  width: 60px;
  vertical-align: top;
}
.onboard-step-list table td:last-child {
  padding-right: 0;
  width: 170px;
}
.onboard-step-list table td .step-icon-ui {
  width: 36px;
  display: block;
  padding-top: 9px;
}
.onboard-step-list table td .step-icon-ui img {
  /*width: 100%;*/
}
.onboard-step-list table td .arrow-icon {
  position: relative;
  top: 10px;
  width: 29px;
  right: -16px;
}
.onboard-step-list table td h5 {
  color: #0d1021;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 26px;
  margin-bottom: 5px;
}
.onboard-step-list table td p {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding-bottom: 0;
}
.onboard-step-list table td.w360 {
  width: 360px;
}
.onboard-step-list table td .complete-ui {
  color: #797f99;
  font-size: 18px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
}
.onboard-step-list table td .status-icon {
  width: 25px;
  display: inline-block;
  margin-left: 14px;
  background: url('assets/images/ic-status.png');
  height: 25px;
  background-size: 100%;
}
.onboard-step-list table td .status-icon img {
  width: 100%;
}
.onboard-step-list table td .status {
  color: rgba(13, 16, 33, 0.5);
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.onboard-step-list table td .status.active {
  color: #0d1021;
}
.onboard-step-list table td .status.active .status-icon {
  background: url('assets/images/ic-status-active.png');
  background-size: 100%;
}
.onboard-step-list table td .text-btn {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}

.linkedin-connected-ui .kaspr-plugin-ui figure {
  width: 85px;
  height: 85px;
  margin-right: 0;
}
.linkedin-connected-ui .kaspr-plugin-ui figure img {
  width: 100%;
}

.linkedin-connected-ui .kaspr-plugin-ui .convert-icon {
  display: inline-block;
  padding: 0 24px;
}

.check-phone-ui .form-group input {
  width: 100%;
  height: 45px;
  border-radius: 8px;
  background-color: #f5f5f9;
  padding: 0 10px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.modal-body .enter-phone-number {
  padding: 10px 24px 0;
}
.modal-body .enter-phone-number .form-group.floating-effect {
  margin-bottom: 0;
}
.modal-body .enter-phone-number .form-group label {
  color: #797f99;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 20px;
  margin-bottom: 12px;
}
.modal-body .enter-phone-number .phone-filed input {
  height: 56px;
  background-color: #fff;
  margin-bottom: 0;
}
.modal-body .enter-phone-number .phone-filed button {
  position: relative;
}
.modal-body .enter-phone-number .phone-filed button:focus {
  outline: none !important;
}
.modal-body .enter-phone-number .phone-filed button:after {
  position: absolute;
  content: '';
  background: url('assets/images/ic_arrow_down.svg') no-repeat;
  width: 10px;
  height: 10px;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.modal-body .enter-phone-number .phone-filed .filter-option-inner-inner {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: left;
  padding: 0 15px;
}
.modal-body .enter-phone-number .flag-selectpicker .dropdown-menu {
  max-height: 80px;
}
.modal-body .enter-phone-number .flag-selectpicker .dropdown-menu li a {
  padding: 5px 15px;
}
.modal-body .enter-phone-number .flag-selectpicker .dropdown-menu li a .inline-flag {
  width: 30px;
  height: 12px;
}
.modal-body .enter-phone-number .info-ui {
  position: relative;
  padding-left: 34px;
  color: #414766;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.28px;
  line-height: 22px;
  margin-bottom: 10px;
}
.modal-body .enter-phone-number .info-ui:before {
  position: absolute;
  content: '';
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  background: url(assets/images/icon-info.svg) no-repeat;
}

.welcome-title {
  color: #595f79;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-bottom: 16px;
}
.welcome-title span {
  color: #0d1829;
}

.dashboard-warpper .info-card {
  margin-bottom: 40px;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
}
@media only screen and (max-width: 1200px) {
  .dashboard-warpper .info-card {
    margin-bottom: 20px;
  }
}

.info-card.profile-complete h5 {
  color: #0d1021;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 24px;
  margin-bottom: 16px;
}

.info-card.profile-complete p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.24px;
  line-height: 22px;
  margin-bottom: 10px;
}

.info-card.profile-complete .progress-ui {
  margin-bottom: 24px;
}
.info-card.profile-complete .progress-ui .progress {
  width: 350px;
}
@media only screen and (max-width: 1400px) {
  .info-card.profile-complete .progress-ui .progress {
    width: 300px;
  }
}
@media only screen and (max-width: 1400px) and (max-width: 1300px) {
  .info-card.profile-complete .progress-ui .progress {
    width: 250px;
  }
}
@media only screen and (max-width: 1300px) {
  .info-card.profile-complete .progress-ui .progress-title {
    font-size: 14px;
    margin-left: 15px;
  }
}

.info-card.profile-complete .btn-blocks a {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.24px;
  padding: 0 24px;
}

.info-card.profile-complete .btn-blocks .mr-14 {
  margin-right: 14px;
}

.current-plan-card h6 {
  color: #414766;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.18px;
  line-height: 19px;
  margin-bottom: 33px;
}
@media only screen and (max-width: 1200px) {
  .current-plan-card h6 {
    margin-bottom: 20px;
  }
}

.current-plan-card .gradient-text {
  color: #3b53d1;
  font-size: 28px;
  letter-spacing: 0.24px;
  line-height: 32px;
  margin-bottom: 12px;
  display: block;
}
@media only screen and (max-width: 1200px) {
  .current-plan-card .gradient-text {
    line-height: 22px;
    margin-bottom: 10px;
    font-size: 24px;
  }
}

.current-plan-card .credit-text {
  display: block;
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 18px;
  display: block;
  text-shadow: -1px 1px 0 0 #ffffff;
  margin-bottom: 26px;
}
@media only screen and (max-width: 1200px) {
  .current-plan-card .credit-text {
    margin-bottom: 24px;
  }
}

.read-blog-ui {
  border: 1px solid #d5d7e4;
  border-radius: 12px;
  background-color: #f5f5f9;
}
.read-blog-ui p {
  color: #0d1829;
  font-size: 20px;
  letter-spacing: -0.12px;
  line-height: 28px;
  margin-bottom: 26px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.read-blog-ui p .text-btn {
  display: block;
  text-align: left;
  color: #3b53d1;
  font-size: 20px;
  letter-spacing: -0.12px;
  line-height: 28px;
}
.read-blog-ui p .text-btn:hover {
  text-decoration: none;
}
.read-blog-ui .btn3 {
  padding: 0 20px;
  text-align: center;
}

.account-usage-card {
  max-height: 274px;
  min-height: 274px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.account-usage-card:hover {
  -webkit-box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 3px 6px 0 rgba(20, 24, 47, 0.1),
    0 10px 16px 0 rgba(13, 16, 33, 0.16);
  box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 3px 6px 0 rgba(20, 24, 47, 0.1),
    0 10px 16px 0 rgba(13, 16, 33, 0.16);
}
.account-usage-card.workflow-card {
  background: url('assets/images/workflow-bg.png');
  background-size: 100%;
}
.account-usage-card.team-card {
  background: url('assets/images/team-bg.png');
  background-size: 100%;
}
.account-usage-card figure {
  width: 36px;
  margin-bottom: 40px;
}
.account-usage-card figure img {
  width: 100%;
}
.account-usage-card .heading-ui {
  color: #0d1021;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 8px;
}
.account-usage-card .description {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 20px;
  min-height: 66px;
}
.account-usage-card .number-text {
  color: #0d1021;
  font-size: 52px;
  font-weight: 300;
  letter-spacing: -0.48px;
  line-height: 56px;
  padding-top: 5px;
  margin-bottom: 20px;
  min-height: 70px;
}

.view-all-btn {
  color: #3b53d1;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
  padding: 0px 0px;
  margin-top: 10px;
  display: inline-block;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.view-all-btn:hover {
  text-decoration: underline;
  color: #3b53d1;
}
.view-all-btn:hover span {
  right: -3px;
}
.view-all-btn span {
  margin-left: 8px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  position: relative;
  right: 0;
}

.integration-header-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}
.integration-header-ui.mb-16 {
  margin-bottom: 16px;
}
.integration-header-ui.mb-16 h5 {
  margin-bottom: 0;
}
.integration-header-ui h5 {
  color: #414766;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
  margin-bottom: 8px;
}
.integration-header-ui p {
  color: #595f79;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.integration-header-ui .view-all-btn {
  font-size: 16px;
}
.integration-header-ui .right-ui {
  margin-left: auto;
}

.dashboard-service-card .integrations-card {
  height: auto;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
}
.dashboard-service-card .integrations-card .top-block {
  margin-bottom: 0;
}
.dashboard-service-card .integrations-card:hover {
  -webkit-box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 3px 6px 0 rgba(20, 24, 47, 0.1),
    0 10px 16px 0 rgba(13, 16, 33, 0.16);
  box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 3px 6px 0 rgba(20, 24, 47, 0.1),
    0 10px 16px 0 rgba(13, 16, 33, 0.16);
}

.credit-chart-block .infographic-block {
  min-height: 368px;
}
@media only screen and (max-width: 1200px) {
  .credit-chart-block .infographic-block {
    min-height: 290px;
  }
}

.credit-chart-block .chart-info {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
}
.credit-chart-block .chart-info span {
  color: #e23f74;
}

.join-organization-inner {
  padding: 0 24px;
}
.join-organization-inner .desc {
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.14px;
  line-height: 24px;
  margin-bottom: 24px;
}

.create-organization-btn a {
  display: block;
  padding: 20px 45px 20px 80px;
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  position: relative;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.create-organization-btn a:hover {
  -webkit-box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 2px 8px 0 rgba(20, 24, 47, 0.2);
  box-shadow:
    0 0 4px 0 rgba(20, 24, 47, 0.16),
    0 2px 8px 0 rgba(20, 24, 47, 0.2);
}
.create-organization-btn a:after {
  position: absolute;
  content: '';
  background: url('assets/images/ic-grey-arrow.svg') no-repeat;
  width: 7px;
  height: 12px;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.create-organization-btn a .ic-user {
  position: absolute;
  left: 20px;
  top: 30px;
  width: 34px;
}
.create-organization-btn a .ic-user img {
  width: 100%;
}
.create-organization-btn a h5 {
  color: #242946;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 8px;
}
.create-organization-btn a p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.12px;
  line-height: 16px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
}

.join-organization-inner .divider-ui {
  padding: 24px 0;
}

.divider-ui {
  position: relative;
  text-align: center;
  display: inline-block;
  width: 100%;
  z-index: 1;
}
.divider-ui:before {
  background: #f0f0f3;
  height: 1px;
  width: 100%;
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}
.divider-ui span {
  display: inline-block;
  background: #fff;
  padding: 0 10px;
  color: #a9afc6;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.12px;
  line-height: 16px;
}

.existing-organization-wrapper h6 {
  color: #242946;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.existing-organization-wrapper p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.12px;
  line-height: 16px;
}

.existing-organization-wrapper .existing-listing {
  padding: 30px 0;
}
.existing-organization-wrapper .existing-listing ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 480px) {
  .existing-organization-wrapper .existing-listing ul li {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.existing-organization-wrapper .existing-listing ul li .left-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 480px) {
  .existing-organization-wrapper .existing-listing ul li .left-ui {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
.existing-organization-wrapper .existing-listing ul li .left-ui figure {
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 0;
}
@media only screen and (max-width: 480px) {
  .existing-organization-wrapper .existing-listing ul li .left-ui figure {
    width: 95px;
    height: 95px;
    margin-right: 20px;
  }
}
.existing-organization-wrapper .existing-listing ul li .left-ui figure img {
  width: 100%;
}
.existing-organization-wrapper .existing-listing ul li .left-ui .content-ui label {
  color: #242946;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 6px;
}
.existing-organization-wrapper .existing-listing ul li .left-ui .content-ui p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.12px;
  line-height: 16px;
}
.existing-organization-wrapper .existing-listing ul li .right-ui {
  margin-left: auto;
}
@media only screen and (max-width: 480px) {
  .existing-organization-wrapper .existing-listing ul li .right-ui {
    width: 100%;
    margin-left: 114px;
    margin-top: -41px;
  }
}
.existing-organization-wrapper .existing-listing ul li .btn3 {
  padding: 0 24px;
}
.existing-organization-wrapper .existing-listing ul li .content-ui .seprater {
  display: inline-block;
  padding: 0 6px;
}

.new-organization-ui {
  padding: 0 24px 0;
}
.new-organization-ui .thumnail-upload-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
}
.new-organization-ui .thumnail-upload-ui figure {
  width: 72px;
  height: 72px;
  margin-right: 16px;
  margin-bottom: 0;
}
.new-organization-ui .thumnail-upload-ui figure img {
  width: 100%;
}
.new-organization-ui .thumnail-upload-ui .thumbnail-content p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 20px;
  margin-bottom: 8px;
}
.new-organization-ui .thumnail-upload-ui .thumbnail-content .upload-btn {
  position: relative;
  display: inline-block;
  z-index: 1;
  cursor: pointer;
}
.new-organization-ui .thumnail-upload-ui .thumbnail-content .upload-btn input {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}
.new-organization-ui .thumnail-upload-ui .thumbnail-content .upload-btn label {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.11px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
}
.new-organization-ui .account-type-listing {
  padding: 8px 0;
}
.new-organization-ui .account-type-listing .choose-heading-ui label {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 20px;
  margin-bottom: 10px;
}
.new-organization-ui .account-type-listing .checkbox-wrap {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}
.new-organization-ui .account-type-listing .checkbox-wrap.mb-10 {
  margin-bottom: 12px;
}
.new-organization-ui .account-type-listing .checkbox-wrap .checkbox-icon {
  position: relative;
  top: -1px;
  vertical-align: top;
}
.new-organization-ui .account-type-listing .checkbox-wrap .checkbox-content {
  padding-left: 10px;
}
.new-organization-ui .account-type-listing .checkbox-wrap .checkbox-content h6 {
  color: #14182f;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 24px;
  margin-bottom: 0px;
  display: inline-block;
  margin-right: 8px;
}
.new-organization-ui .account-type-listing .checkbox-wrap .checkbox-content p {
  color: #595f79;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 20px;
  display: inline-block;
}
.new-organization-ui .form-group .cstm-select .bootstrap-select {
  width: 100% !important;
}
.new-organization-ui .form-group .cstm-select .bootstrap-select button {
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 45px;
  padding: 0 15px;
  line-height: 45px;
}
.new-organization-ui .form-group .cstm-select .bootstrap-select button:focus {
  outline: none !important;
}
.new-organization-ui .form-group .cstm-select .bootstrap-select button:after {
  border: none;
  background: url(assets/images/ic_arrow_down.svg) no-repeat;
  position: absolute;
  content: '';
  width: 20px;
  height: 12px;
  right: 9px;
  background-size: 14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.new-organization-ui .form-group .cstm-select .dropdown-menu {
  width: 100%;
  max-height: 180px;
}

.enter-domain-ui {
  margin-bottom: 20px;
}
.enter-domain-ui .form-group {
  margin-bottom: 10px;
}
.enter-domain-ui .suggestion-items .suggestion-label {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 16px;
  padding-left: 30px;
  position: relative;
  margin-right: 7px;
}
.enter-domain-ui .suggestion-items .suggestion-label:before {
  position: absolute;
  content: '';
  background: url('assets/images/ic-suggestion.svg') no-repeat;
  width: 15px;
  height: 16px;
  left: 9px;
  top: -1px;
}
.enter-domain-ui .suggestion-items .sugeestion-tag-ui {
  border-radius: 14px;
  background-color: #f5f5f9;
  color: #595f79;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 24px;
  padding: 2px 12px;
  display: inline-block;
}

.checkout-main-wrapper .signup-header {
  position: relative;
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .checkout-main-wrapper .back-ui {
    display: block;
  }
  .checkout-main-wrapper .signup-header .col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .checkout-main-wrapper .logo {
    text-align: center !important;
  }
  .checkout-main-wrapper .back-home-btn {
    height: 30px;
    width: 30px;
  }
}

.white-wrap {
  border-radius: 12px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  padding: 24px;
}

.checkout-wrapper {
  padding-bottom: 40px;
}
.checkout-wrapper .heading-ui {
  color: #0d1021;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 24px;
  margin-bottom: 16px;
}
.checkout-wrapper .line-heading {
  position: relative;
  z-index: 1;
  border: none;
  margin-bottom: 15px;
}
.checkout-wrapper .line-heading:after {
  position: absolute;
  content: '';
  height: 1px;
  width: 100%;
  background: #d5d7e4;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
}
.checkout-wrapper .line-heading label {
  top: 0;
  background: #fff;
}
.checkout-wrapper .checkout-content-wrap {
  padding-bottom: 40px;
}

.payment-info-wrap {
  margin-bottom: 40px;
}
.payment-info-wrap:last-child {
  margin-bottom: 0;
}

.card-detail-ui {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.card-detail-ui .form-group {
  width: 100%;
}
.card-detail-ui .form-group:last-child {
  margin-bottom: 0;
}
.card-detail-ui .form-group.half {
  width: 50%;
}
.card-detail-ui .form-group label {
  width: 100%;
  margin-bottom: 7px;
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 20px;
}
.card-detail-ui .form-group input {
  width: 100%;
  height: 45px;
  border: 1px solid #d5d7e4;
  border-radius: 8px;
  background-color: #f5f5f9;
  padding: 0 10px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}
.card-detail-ui .form-group input:focus {
  border: 1px solid #a9afc6;
  background: #fff;
}
.card-detail-ui .form-group .cstm-select .bootstrap-select {
  width: 100% !important;
}
.card-detail-ui .form-group .cstm-select button {
  border-radius: 8px;
  background-color: #ffffff;
  -webkit-box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  box-shadow:
    0 0 2px 0 rgba(20, 24, 47, 0.12),
    0 2px 4px 0 rgba(20, 24, 47, 0.1);
  height: 45px;
  padding: 0 15px;
  line-height: 45px;
}
.card-detail-ui .form-group .cstm-select button:focus {
  outline: none !important;
}
.card-detail-ui .form-group .cstm-select button:after {
  border: none;
  background: url(assets/images/ic_arrow_down.svg) no-repeat;
  position: absolute;
  content: '';
  width: 20px;
  height: 12px;
  right: 9px;
  background-size: 14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.card-detail-ui .form-group .cstm-select .dropdown-menu {
  width: 100%;
}

.pr-12 {
  padding-right: 12px;
}

.pl-12 {
  padding-left: 12px;
}

.checkout-wrapper .order-summary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 18px;
}
.checkout-wrapper .order-summary .left-ui {
  margin-right: auto;
}
.checkout-wrapper .order-summary .left-ui p {
  color: #0d1021;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.checkout-wrapper .order-summary .right-ui p {
  color: #0d1021;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}
.checkout-wrapper .order-summary.total-ui {
  border-top: 1px solid #d5d7e4;
  padding-top: 24px;
  padding-bottom: 6px;
}
.checkout-wrapper .order-summary.total-ui .left-ui p {
  color: #0d1021;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
}
.checkout-wrapper .order-summary.total-ui .right-ui p {
  color: #0d1021;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.checkout-wrapper .btn-block .btn1 {
  width: 100%;
  height: 52px;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.24px;
  text-align: center;
  text-shadow: -1px 1px 0 0 #3b53d1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.checkout-wrapper .btn-block .btn1 .ic-lock {
  margin-right: 10px;
  position: relative;
  top: 0 px;
}

.checkout-wrapper .coupon-code a {
  color: #3b53d1;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-shadow: -1px 1px 0 0 #f5f5f9;
  padding-bottom: 23px;
  display: block;
}

.checkout-wrapper .secure-text-ui {
  color: #797f99;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-top: 16px;
}

@media only screen and (min-width: 1400px) {
  .checkout-wrapper .container-fluid {
    max-width: 1300px;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .checkout-right-block {
    margin-top: 40px;
  }
}
