.table-content td:not(.table-content td:first-child) {
    min-width: 200px;
}
.table-content tr:hover .action-btns {
    opacity: 1;
    visibility: visible;
}
.table-content .action-btns {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: linear-gradient(89.93deg, rgba(250, 250, 252, 0) 0.04%, #FAFAFC 24.96%, #FAFAFC 74.95%, rgba(250, 250, 252, 0) 99.92%);
    width: 234px;
    position: absolute;
    left: 260px;
    top: 10px;
    padding: 6px 20px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.table-content .action-btns .act-btn {
    background: #FFFFFF;
    border: 1px solid rgba(222, 222, 235, 0.07);
    -webkit-box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    box-shadow: 0px 0px 2px rgba(20, 24, 47, 0.12), 0px 2px 4px rgba(20, 24, 47, 0.1);
    border-radius: 100%;
    width: 34px;
    height: 34px;
    margin-right: 10px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}
.table-content .action-btns .act-btn.view-btn .icon {
    position: relative;
    left: 0;
    background:url("assets/images/ic-seprite.svg");
    background-position: -164px -5px;
    top: 0;
    width: 23px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 18px;
}
.table-content .action-btns .act-btn.list-btn .icon {
    position: relative;
    left: 0;
    background:url("assets/images/ic-seprite.svg");
    background-position: -198px -6px;
    top: 0;
    width: 23px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 18px;
}
.table-content .action-btns .act-btn.email-btn .icon {
    position: relative;
    left: 0;
    background:url("assets/images/ic-seprite.svg");
    background-position: -3px -39px;
    top: 0;
    width: 23px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 18px;
}
.table-content .action-btns .act-btn.more-btn .icon {
    position: relative;
    left: 0;
    background:url("assets/images/ic-seprite.svg");
    background-position: -231px -6px;
    top: 0;
    width: 23px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 19px;
}

.table-content .show-data {
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding:5px;
    padding-right: 10px;
    border: 1px solid #999;
    color: #999;
    font-size: 14px;
    font-weight: 500;
}
.table-content .show-data img {
 margin-bottom: -3px;
    margin-left: -6px;
    width: 30px;
    margin-right: 0px;
}
