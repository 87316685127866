.signup-header {
  padding: 16px 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  background: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.signup-header .header-right .text-btn {
  padding-left: 8px;
}

.signup-header .header-right p span {
  display: none;
}

.signup-header .row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.signup-header .col-4 {
  max-width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}

.back-home-btn {
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 24px;
  text-align: right;
  display: inline-block;
  position: relative;
  padding-left: 20px;
}

.back-home-btn:hover {
  color: #3b53d2;
}

.back-home-btn:hover:before {
  left: -3px;
}

.back-home-btn:before {
  position: absolute;
  content: '';
  background: url('assets/images/arrow-prev.svg') no-repeat;
  width: 13px;
  height: 12px;
  left: 0;
  top: 6px;
  -webkit-transition: 0.25s ease-out;
  -o-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.border-radius-12 {
  border-radius: 12px;
}
.main-wraper .white-bg {
  -webkit-box-shadow:
    -4px 4px 8px 0 rgba(60, 66, 87, 0.1),
    -12px 12px 24px 0 rgba(33, 54, 89, 0.06),
    -16px 20px 40px 0 rgba(89, 116, 166, 0.04);
  box-shadow:
    -4px 4px 8px 0 rgba(60, 66, 87, 0.1),
    -12px 12px 24px 0 rgba(33, 54, 89, 0.06),
    -16px 20px 40px 0 rgba(89, 116, 166, 0.04);
  background-color: #ffffff;
  padding: 40px;
}

@media only screen and (max-width: 480px) {
  .main-wraper .white-bg {
    padding: 30px 20px;
  }
}

.form-heading-grp {
  margin-bottom: 30px;
}
.form-heading-grp .h4 {
  color: #0d1829;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;
}
.form-heading-grp p {
  color: #595f79;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 24px;
}

.other-info {
  margin-top: 32px;
}

.footer-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 64px;
}
@media only screen and (max-height: 750px) {
  .footer-list {
    position: relative;
    bottom: 0;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
.footer-list li {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
  text-align: center;
  margin: 0 5px;
}
.footer-list li a {
  color: #797f99;
  font-size: 14px;
  letter-spacing: 0.16px;
  line-height: 16px;
  text-align: center;
  -webkit-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.footer-list li a:hover {
  color: #414766;
}

/*signup css start */
.half-white-bg {
  position: relative;
  padding-top: 125px;
  min-height: 100vh;
}
@media only screen and (max-width: 480px) {
  .half-white-bg {
    padding-top: 90px;
  }
}
.half-white-bg:after {
  position: absolute;
  height: 100%;
  width: 50%;
  background: #fff;
  content: '';
  right: 0;
  top: 0;
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .half-white-bg:after {
    display: none;
  }
}
.half-white-bg:before {
  position: absolute;
  height: 100%;
  width: 50%;
  background: url('assets/images/mainbg.jpg');
  background-size: 100%;
  content: '';
  left: 0;
  top: 0;
}
@media only screen and (max-width: 991px) {
  .half-white-bg:before {
    display: none;
  }
}

.side-footer .footer-list {
  position: relative;
  left: 0;
  text-align: left;
  bottom: initial;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.desc-block {
  max-width: 400px;
}
.desc-block .title {
  color: #0d1829;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.24px;
  line-height: 33px;
  margin-bottom: 16px;
}
.desc-block p {
  color: #414766;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 24px;
  margin-bottom: 40px;
}

#signin-main-container button[type='submit'] {
  width: 100%;
}
