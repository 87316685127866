#bottom_banner {
  width: 100%;
  background: #fff;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: -20px 0px 150px rgba(0, 0, 0, 0.3);
  border-top: 2px solid #f2f2f2;
  height: max-content;
  bottom: -100%;
  transition: all 0.5s ease;
}

#bottom_banner.show {
  bottom: 0;
  transition: all 0.5s ease;
}
#bottom_banner .head-bottom-banner {
  position: relative;
}
#bottom_banner .head-bottom-banner span {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}
#bottom_banner .head-bottom-banner span img {
  width: 20px;
}
