#credits-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

#credits-container .section-title {
  display: flex;
  flex-direction: column;
  gap: 0;
}

#credits-container .section-title h1 {
  font-size: 16px;
  font-weight: 500;
  color: #0d1021;
  margin-bottom: 10px !important;
}

#credits-container .section-title p {
  font-size: 12px;
  font-weight: 400;
  color: #495377;
  margin-bottom: 5px;
}

#credits-container .credit-section {
  padding: 16px 16px 24px 16px;
  border-radius: 12px;
  background-color: #ececec6b;
}

#credits-container .credit-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

#credits-container .credit-title {
  display: flex;
  align-items: end;
  gap: 5px;
}

#credits-container .credit-title h5 {
  font-size: 16px;
  font-weight: 500;
  color: #0d1021;
}

#credits-container .pricing-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

#credits-container .info-credit-wrapper p {
  font-size: 12px;
  font-weight: 400;
  color: #979797;
}

#credits-container .info-credit-wrapper .credits {
  font-size: 22px;
  font-weight: 700;
  color: #414766;
}

#credits-container .info-credit-wrapper .credits span {
  font-size: 14px;
  font-weight: 500;
  color: #414766;
  margin-left: 5px;
}

#credits-container .info-credit-wrapper strong {
  margin-right: 5px;
}

#credits-container .info-credit-wrapper span {
  font-size: 14px;
  font-weight: 500;
  color: #414766;
}

/* TEST SLIDER  */

#credits-container .add-credits-slider {
  position: relative;
  padding-bottom: 20px;
}

#credits-container .add-credits-slider .slider_2 {
  z-index: 10;
  position: relative;
}

#credits-container .add-credits-slider .slider_2 .rc-slider-rail {
  background: transparent;
}

#credits-container .add-credits-slider .slider_2 .rc-slider-track {
  background: rgba(50, 50, 0, 0.5);
}

#credits-container .add-credits-slider .rc-slider {
  position: absolute;
  width: 100%;
  top: 0;
}

#credits-container .add-credits-slider .rc-slider-rail {
  background: #ccc;
  height: 8px;
}

#credits-container .add-credits-slider .rc-slider-track {
  background: #595f79;
  height: 8px;
}

#credits-container .add-credits-slider .rc-slider-handle {
  background: #fff;
  opacity: 1;
  width: 20px;
  height: 20px;
  margin-top: -6px;
  border: 1px solid #ccc;
}

#credits-container .add-credits-slider .rc-slider-dot .inject-amount {
  color: #999;
  position: absolute;
  top: 6px;
  transform: translateX(-50%);
  font-size: 13px;
}

#credits-container
  .add-credits-slider
  .rc-slider-dot:last-child
  .inject-amount {
  position: absolute;
  top: 6px;
  transform: translateX(-50%);
  font-size: 13px;
  padding-right: 14px;
}

#credits-container .add-credits-slider .rc-slider-dot {
  background: #999;
  height: 5px;
  border-radius: 0;
  width: 0 !important;
  padding: 0;
  display: inline-block;
  margin: 0 !important;
  border: none;
  bottom: -8px;
  cursor: pointer;
}

#credits-container .n-credits-grd {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}

#credits-container .n-credits-grd span {
  transform: translateX(40%);
  color: #999;
}

#credits-container .add-credits-slider .rc-slider-dot:first-child {
  display: none;
}

#credits-container
  .add-credits-slider
  .rc-slider-handle.rc-slider-handle-dragging {
  background: #fff;
}

#credits-container .add-credits-right {
  font-size: 22px;
  font-weight: 700;
  color: #414766;
}

#credits-container .add-credits-right span {
  font-size: 14px;
  font-weight: 500;
}

#credits-container .price-per-credit {
  font-size: 12px;
  font-weight: 400;
  color: #979797;
}

#credits-container .price-per-credit span {
  font-size: 12px;
  font-weight: 700;
}
