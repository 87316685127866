#customize-plan {
  padding: 20px 20px;
  padding-left: 200px;
  padding-right: 5%;
}
#customize-plan strong {
  font-size: 27px;
}
#customize-plan section {
  margin-bottom: 20px;
}
#customize-plan .credits-section {
  opacity: 0;
  height: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  margin: 0;
}
#customize-plan .credits-section.show-credit-section {
  opacity: 1;
  height: 260px;
  padding-top: 20px;
  transition: all 0.3s ease;
  display: block;
}
#customize-plan .recap-plan-section {
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ddd;
}
#customize-plan .recap-plan-section .recap-plan-head img {
  margin-right: 5px;
  width: 20px;
}
#customize-plan .recap-plan-section .recap-plan-head span:first-child {
  margin-right: 10px;
}
#customize-plan .recap-plan-section .plan-type-wp > span:first-child {
  margin-right: 20px;
  color: #72757e;
}
.switch-txt-style {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 80% !important;
  font-weight: 700;
  text-align: center;
  padding-left: 0px;
}
.react-switch-bg > div:first-child {
  width: 100% !important;
}
.switch-txt-style.right {
  padding-left: 0;
  padding-right: 50px;
}
#customize-plan .recap-plan-head div:first-child {
  font-size: 18px;
  color: #4d58f3;
  font-weight: 600;
}
#customize-plan .recap-plan-head div:last-child {
  margin-top: 20px;
  color: #414766;
}
#customize-plan .credit-type-tabs {
  margin-top: 10px;
  margin-bottom: 25px;
}
#customize-plan .credit-type-tabs > span {
  padding: 10px;
  margin-right: 10px;
  border-radius: 10px;
  color: #72757e;
  font-size: 15px;
  cursor: pointer;
  opacity: 0.7;
  border: 1px solid transparent;
  position: relative;
}
#customize-plan .credit-type-tabs > span > small {
  position: absolute;
  bottom: -9px;
  right: 5px;
  background: #f3f3f3;
  padding: 0px 5px;
  border-radius: 3px;
  color: #555;
}
#customize-plan .credit-type-tabs span.selected {
  color: #4d68f8;
  box-shadow: 0px 0 2px rgba(0, 0, 0, 0.3);
  font-weight: 600;
  opacity: 1;
  transition: all 0.3s ease;
  border-color: #ccc;
}
/*#customize-plan .credit-type-tabs span:first-child {
  color: #0eb763;
}
#customize-plan .credit-type-tabs span:nth-child(2) {
  color: #ff206e;
}
#customize-plan .credit-type-tabs span:last-child {
  color: #b149ff;
}
#customize-plan .credit-type-tabs span.selected {
  box-shadow: 0px 0 5px rgba(0, 0, 0, 0.1);
  font-weight: 600;
  opacity: 1;
  transition: all 0.3s ease;
}
#customize-plan .credit-type-tabs span:first-child.selected {
  border-color: #0eb763;
}
#customize-plan .credit-type-tabs span:nth-child(2).selected {
  border-color: #ff206e;
}
#customize-plan .credit-type-tabs span:last-child.selected {
  border-color: #b149ff;
}*/
#customize-plan .custom-credit-type {
  background: rgba(236, 236, 236, 0.42);
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  padding-bottom: 30px;
  height: auto;
}
#customize-plan .info-credit-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
}
#customize-plan .info-credit-wrapper p {
  font-size: 14px;
  color: #979797;
}
#customize-plan .add-credits-slider {
  position: relative;
  padding-bottom: 20px;
}
#customize-plan .add-credits-slider .slider_2 {
  z-index: 10;
  position: relative;
}

#customize-plan .add-credits-slider .slider_2 .rc-slider-rail {
  background: transparent;
}
#customize-plan .add-credits-slider .slider_2 .rc-slider-track {
  background: rgba(50, 50, 0, 0.5);
}
#customize-plan .add-credits-slider .rc-slider {
  position: absolute;
  width: 100%;
  top: 0;
}
#customize-plan .add-credits-slider .rc-slider-rail {
  background: #ccc;
  height: 8px;
}
#customize-plan .add-credits-slider .rc-slider-track {
  background: #595f79;
  height: 8px;
}

#customize-plan .add-credits-slider .rc-slider-handle {
  background: #fff;
  opacity: 1;
  width: 20px;
  height: 20px;
  margin-top: -6px;
  border: 1px solid #ccc;

  /*
    box-shadow: 1px 0 5px rgba(0,0,0,0.3);
    */
}
#customize-plan .add-credits-slider .rc-slider-dot .inject-amount {
  color: #999;
  position: absolute;
  top: 6px;
  transform: translateX(-50%);
  font-size: 13px;
}
#customize-plan .add-credits-slider .rc-slider-dot {
  background: #999;
  height: 5px;
  border-radius: 0;
  width: 1px !important;
  padding: 0;
  display: inline-block;
  margin: 0 !important;
  border: none;
  bottom: -8px;
  cursor: pointer;
  /*
      box-shadow: 1px 0 5px rgba(0,0,0,0.3);
      */
}

/*#customize-plan .add-credits-slider .rc-slider-dot:nth-child(odd) {
  background: #ccc;
  height: 3px;
  border-radius: 0;
  width: 1px !important;
  padding: 0;
  display: inline-block;
  margin: 0 !important;
  border: none;
  bottom: -7px;
}*/
#customize-plan .n-credits-grd {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}
#customize-plan .n-credits-grd span {
  transform: translateX(40%);
  color: #999;
}
#customize-plan .add-credits-slider .rc-slider-dot:first-child,
#customize-plan .add-credits-slider .rc-slider-dot:last-child {
  display: none;
}
#customize-plan
  .add-credits-slider
  .rc-slider-handle.rc-slider-handle-dragging {
  background: #fff;
}
#customize-plan .info-checkout-section-wp {
  background: linear-gradient(
    92.6deg,
    rgba(255, 233, 179, 0.12) 2.73%,
    rgba(241, 112, 39, 0.12) 100.16%
  );
  padding: 20px;
  margin-bottom: 0;
  margin-top: 30px;
}
#customize-plan .info-checkout-section-wp .info-checkout-hd {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
#customize-plan .info-checkout-section-wp .info-checkout-hd button {
  color: #4d68f8;
  font-weight: 500;
  padding-right: 10px;
}

#customize-plan .info-checkout-section-wp .info-checkout-hd button span {
  font-size: 20px;
}
#customize-plan .info-checkout-section-wp .plan-name {
  display: flex;
  align-items: center;
}
#customize-plan .info-checkout-section-wp .plan-name p {
  margin-left: 10px;
  font-size: 20px;
  font-weight: 600;
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48c4 150.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-right: 20px;
}

#customize-plan .info-checkout-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#customize-plan .info-checkout-section p {
  font-size: 13px;
  margin-bottom: 20px;
  margin-left: 10px;
  color: #72757e;
}
#customize-plan .info-checkout-section .credits-global-info {
  display: flex;
  gap: 10px;
  width: auto;
}

#customize-plan .info-checkout-section .credits-global-info span {
  padding: 10px 15px;
  background: #fff;
  border-radius: 20px;
  background: rgba(151, 151, 151, 0.07);
  color: #414766;
  font-size: 14px;
}
#customize-plan .info-checkout-section .plan-info-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}
#customize-plan .info-checkout-section .plan-info-footer p {
  margin: 0;
  color: #e66d6d;
  font-size: 14px;
  margin-top: -7px;
}
#customize-plan .info-checkout-section .plan-info-footer p.switch-to-yearly {
  /*   border: 1px solid  #e66d6d;
    padding: 2px 7px;
    border-radius: 7px;*/
  cursor: pointer;
  padding: 0;
  border-bottom: 1px solid #e66d6d;
}
#customize-plan
  .info-checkout-section
  .plan-info-footer
  p.switch-to-yearly
  span {
  position: relative;
  padding-right: 7px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 10px;
  background: linear-gradient(96.13deg, #e66d6d -17.49%, #db48be 150.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
#customize-plan
  .info-checkout-section
  .plan-info-footer
  p.switch-to-yearly
  small {
  font-weight: bold;
  color: #4d68f8;
}
#customize-plan .info-checkout-section .plan-info-footer > div {
  text-align: right;
}
#customize-plan .info-checkout-section .cstm-btn {
  width: max-content;
  padding: 10px 30px;
  margin-left: 20px;
}
#customize-plan .addon-totalprice-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
#customize-plan .addon-unit-price {
  position: relative;
  top: -8px;
}
#customize-plan .addon-unit-price small {
  color: #555;
}
#customize-plan .addon-unit-price small > span {
  font-weight: bold;
}

#customize-plan .summary {
  color: #999;
  padding: 0 0 10px 10px;
}
