#auth .floating-effect {
  position: relative;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
}
#auth div.verticalSlider {
  margin-bottom: 0;
}
#auth .steps-block {
  margin-bottom: 0;
}

#auth .signup-left {
  padding-top: 0 !important;
}
/*
#auth input:invalid {
    border-color: red;
}*/
#auth .signup-right .checkbox-wrap {
  margin-bottom: 10px !important;
}

#auth button[type='submit'] {
  width: 100%;
}

#signup-left-container .signup-left-top {
  z-index: 1000;
  position: relative;
}
