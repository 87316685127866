.add-to-list {
    display: flex;
    align-items: center;
    width: 100%;
}
.add-to-list .custom-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #8E96B6;
    margin-right: 30px;
}

.add-to-list button.select-button:before {
    content: none !important;
}
.add-to-list .select-button p {
    margin-right: 10px;
}
.add-to-list .select-button {
    padding: 0 15px;
    max-width: 121px
}