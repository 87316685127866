.extra-credits-table table {
    width: 100%;
    margin-top: 30px;
    border-collapse: collapse ;
}
.extra-credits-table table tr td {
    padding: 10px 0;
    font-size: 17px;
}
.extra-credits-table table  td:last-child{
    text-align: right;
}

.extra-credits-table table thead td {
    color:#595F79
}

.extra-credits-table table tbody td {
    color:#333
}
